const AUTH = '@auth';
const TRAP = '@trap';
const GROUP = '@group';
const IMAGE = '@image';
const LOTE_SCAN = '@lote-scan';
const SINGLE_SCAN = '@single-scan';
const REPORT = '@report';
const QUEUE_SCAN = '@queue-scan';

export const NOTIFICATIONS_ERROR = 'NOTIFICATIONS_ERROR';
export const NOTIFICATIONS_SUCCESS = 'NOTIFICATIONS_SUCCESS';
export const NOTIFICATIONS_ALERT = 'NOTIFICATIONS_ALERT';

export const AUTH_LOGIN_LOAD = `${AUTH}/LOGIN_LOAD`;
export const AUTH_LOGIN_SUCCESS = `${AUTH}/LOGIN_SUCCESS`;
export const AUTH_ERROR = `${AUTH}/ERROR`;
export const AUTH_IS_LOADING = `${AUTH}/IS_LOADING`;
export const AUTH_LOGOUT = `${AUTH}/LOGOUT`;

export const AUTH_INDEX_ORGANIZATIONS_LOAD = `${AUTH}/INDEX_ORGANIZATIONS_LOAD`;
export const AUTH_INDEX_ORGANIZATIONS_SUCCESS = `${AUTH}/INDEX_ORGANIZATIONS_SUCCESS`;
export const AUTH_SET_SELECTED_ORGANIZATION = `${AUTH}/SET_SELECTED_ORGANIZATION`;

export const TRAP_INDEX_LOAD = `${TRAP}/TRAP_INDEX_LOAD`;
export const TRAP_INDEX_SUCCESS = `${TRAP}/TRAP_INDEX_SUCCESS`;
export const TRAP_INDEX_ERROR = `${TRAP}/TRAP_INDEX_ERROR`;
export const TRAP_INDEX_IS_LOADING = `${TRAP}/TRAP_INDEX_IS_LOADING`;
export const TRAP_CLEANUP = `${TRAP}/TRAP_CLEANUP`;

export const TRAP_EDIT_SELECT = `${TRAP}/EDIT_SELECT`;
export const TRAP_EDIT_CLEAR_SELECTED = `${TRAP}/EDIT_CLEAR_SELECTED`;

export const TRAP_ADD = `${TRAP}/ADD`;
export const TRAP_ADD_SUCCESS = `${TRAP}/ADD_SUCCESS`;
export const TRAP_ADD_ERROR = `${TRAP}/ADD_ERROR`;

export const TRAPS_SEARCH_FILTER = `${TRAP}/SEARCH_FILTER`;
export const TRAPS_REMOVE_SEARCH_FILTER = `${TRAP}/REMOVE_SEARCH_FILTER `;

export const TRAP_UPDATE = `${TRAP}/UPDATE`;
export const TRAP_UPDATE_ERROR = `${TRAP}/UPDATE_ERROR`;
export const TRAP_UPDATE_SUCCESS = `${TRAP}/UPDATE_SUCCESS`;

export const TRAP_DELETE = `${TRAP}/DELETE`;
export const TRAP_DELETE_ERROR = `${TRAP}/DELETE_ERROR`;
export const TRAP_DELETE_SUCCESS = `${TRAP}/DELETE_SUCCESS`;

export const GROUP_INDEX_LOAD = `${GROUP}/INDEX_LOAD`;
export const GROUP_INDEX_SUCCESS = `${GROUP}/INDEX_SUCCESS`;
export const GROUP_ERROR = `${GROUP}/ERROR`;
export const GROUP_IS_LOADING = `${GROUP}/IS_LOADING`;
export const GROUP_CLEANUP = `${GROUP}/CLEANUP`;

export const GROUP_NEW_INIT = `${GROUP}/NEW_INIT`;
export const GROUP_SELECTED_CLEANUP = `${GROUP}/SELECTED_CLEANUP`;
export const GROUP_SELECTED_UPDATE_NAME = `${GROUP}/SELECTED_UPDATE_NAME`;
export const GROUP_SELECTED_UPDATE_TRAPS = `${GROUP}/SELECTED_UPDATE_TRAPS`;
export const GROUP_SELECTED_ADD_TRAP = `${GROUP}/SELECTED_ADD_TRAP`;
export const GROUP_SELECTED_REMOVE_TRAP = `${GROUP}/SELECTED_REMOVE_TRAP`;
export const GROUP_NEW_SUBMIT = `${GROUP}/NEW_SUBMIT`;
export const GROUP_NEW_SUBMIT_SUCCESS = `${GROUP}/NEW_SUBMIT_SUCCESS`;

export const GROUP_FIND_LOAD = `${GROUP}/FIND_LOAD`;
export const GROUP_FIND_SUCCESS = `${GROUP}/FIND_SUCCESS`;

export const GROUP_EDIT_SUBMIT = `${GROUP}/EDIT_SUBMIT`;

export const IMAGE_ERROR = `${IMAGE}/ERROR`;
export const IMAGE_CLEANUP = `${IMAGE}/CLEANUP`;
export const IMAGE_IS_LOADING = `${IMAGE}/IS_LOADING`;
export const IMAGE_INDEX_LOAD = `${IMAGE}/INDEX_LOAD`;
export const IMAGE_INDEX_SUCCESS = `${IMAGE}/INDEX_SUCCESS`;

export const IMAGE_FIND_LOAD = `${IMAGE}/FIND_LOAD`;
export const IMAGE_FIND_SUCCESS = `${IMAGE}/FIND_SUCCESS`;

export const IMAGE_UPDATE = `${IMAGE}/UPDATE`;
export const IMAGE_UPDATE_SUCCESS = `${IMAGE}/UPDATE_SUCCESS`;
export const IMAGE_SUBMIT_UPDATED = `${IMAGE}/SUBMIT_UPDATED`;
export const IMAGE_SUBMIT_UPDATED_SUCCESS = `${IMAGE}/SUBMIT_UPDATED_SUCCESS`;

export const LOT_SCAN_ERROR = `${LOTE_SCAN}/ERROR`;
export const LOT_SCAN_CLEANUP = `${LOTE_SCAN}/CLEANUP`;
export const LOT_SCAN_IS_LOADING = `${LOTE_SCAN}/IS_LOADING`;
export const LOT_SCAN_INIT = `${LOTE_SCAN}/init`;
export const LOT_SCAN_IS_UPLOADING = `${LOTE_SCAN}/IS_UPLOADING`;
export const LOT_SCAN_DONE_UPLOADING = `${LOTE_SCAN}/DONE_UPLOADING`;

export const LOT_SCAN_LOAD_GROUPS_SUCCESS = `${LOTE_SCAN}/LOAD_GROUPS_SUCCESS `;

export const LOT_SCAN_ADD_IMAGE = `${LOTE_SCAN}/ADD_IMAGE`;
export const LOT_SCAN_ADD_IMAGE_SUCCESS = `${LOTE_SCAN}/ADD_IMAGE_SUCCESS`;

export const LOT_SCAN_LOAD_TRAPS = `${LOTE_SCAN}/LOAD_TRAPS`;
export const LOT_SCAN_LOAD_TRAPS_SUCCESS = `${LOTE_SCAN}/LOAD_TRAPS_SUCCESS`;
export const LOT_SCAN_LOAD_VEGETATIVE_STAGES = `${LOTE_SCAN}/LOAD_VEGETATIVE_STAGES`;
export const LOT_SCAN_LOAD_VEGETATIVE_STAGES_SUCCESS = `${LOTE_SCAN}/LOAD_VEGETATIVE_STAGES_SUCCESS`;

export const LOT_SCAN_UPDATE_IMAGE = `${LOTE_SCAN}/UPDATE_IMAGE`;
export const LOT_SCAN_UPDATE_IMAGE_SUCCESS = `${LOTE_SCAN}/UPDATE_IMAGE_SUCCESS`;
export const LOT_SCAN_CHANGE_PAGE = `${LOTE_SCAN}/CHANGE_PAGE `;

export const LOT_SCAN_SUBMIT_IMAGES = `${LOTE_SCAN}/SUBMIT_IMAGES`;

export const SINGLE_SCAN_ERROR = `${SINGLE_SCAN}/ERROR`;
export const SINGLE_SCAN_CLEANUP = `${SINGLE_SCAN}/CLEANUP`;
export const SINGLE_SCAN_IS_LOADING = `${SINGLE_SCAN}/IS_LOADING`;
export const SINGLE_SCAN_INIT = `${SINGLE_SCAN}/init`;

export const SINGLE_SCAN_LOAD_TRAPS = `${SINGLE_SCAN}/LOAD_TRAPS`;
export const SINGLE_SCAN_LOAD_TRAPS_SUCCESS = `${SINGLE_SCAN}/LOAD_TRAPS_SUCCESS`;
export const SINGLE_SCAN_LOAD_VEGETATIVE_STAGES = `${SINGLE_SCAN}/LOAD_VEGETATIVE_STAGES`;
export const SINGLE_SCAN_LOAD_VEGETATIVE_STAGES_SUCCESS = `${SINGLE_SCAN}/LOAD_VEGETATIVE_STAGES_SUCCESS`;
export const SINGLE_SCAN_UPDATE_IMAGE = `${SINGLE_SCAN}/UPDATE_IMAGE`;
export const SINGLE_SCAN_CHANGE_SIDE = `${SINGLE_SCAN}/CHANGE_SIDE`;
export const SINGLE_SCAN_UPLOAD_IMAGE = `${SINGLE_SCAN}/UPLOAD_IMAGE`;
export const SINGLE_SCAN_UPLOAD_IMAGE_SUCCESS = `${SINGLE_SCAN}/UPLOAD_IMAGE_SUCCESS`;
export const SINGLE_SCAN_DELETE_IMAGE = `${SINGLE_SCAN}/DELETE_IMAGE`;
export const SINGLE_SCAN_SUBMIT_IMAGE = `${SINGLE_SCAN}/SUBMIT_IMAGE`;

export const REPORTS_ERROR = `${REPORT}/INDEX_ERROR`;
export const REPORTS_IS_LOADING = `${REPORT}/INDEX_IS_LOADING`;
export const REPORTS_CLEANUP = `${REPORT}/CLEANUP`;

export const REPORTS_NEW_REPORT_INIT = `${REPORT}/NEW_REPORT_INIT`;
export const REPORTS_NEW_REPORT_SUBMIT = `${REPORT}/NEW_REPORT_SUBMIT`;

export const REPORTS_LOAD_GROUPS = `${REPORT}/LOAD_GROUPS`;
export const REPORTS_LOAD_GROUPS_SUCCESS = `${REPORT}/LOAD_GROUPS_SUCCESS`;

export const REPORTS_UPDATE_SELECTED = `${REPORT}/UPDATE_SELECTED`;
export const REPORTS_SELECTED_ADD_GROUP = `${REPORT}/SELECTED_ADD_GROUP`;
export const REPORTS_SELECTED_REMOVE_GROUP = `${REPORT}/SELECTED_REMOVE_GROUP`;

export const REPORTS_LOAD_REPORT = `${SINGLE_SCAN}/LOAD_REPORT`;
export const REPORTS_LOAD_REPORT_SUCCESS = `${SINGLE_SCAN}/LOAD_REPORT_SUCCESS`;

export const REPORTS_UPDATE_REPORT_SUBMIT = `${REPORT}/UPDATE_REPORT_SUBMIT`;
export const REPORTS_INDEX_OPEN_LOAD = `${REPORT}/INDEX_OPEN_LOAD`;
export const REPORTS_INDEX_OPEN_SUCCESS = `${REPORT}/INDEX_OPEN_SUCCESS`;

export const REPORTS_REPORT_BY_VEGETATIVE_STAGE_LOAD = `${REPORT}/REPORT_BY_VEGETATIVE_STAGE_LOAD`;
export const REPORTS_REPORT_BY_VEGETATIVE_STAGE_SUCCESS = `${REPORT}/REPORT_BY_VEGETATIVE_STAGE_SUCCESS`;

export const REPORTS_REPORT_BY_GROUP_LOAD = `${REPORT}/REPORT_BY_GROUP_LOAD`;
export const REPORTS_REPORT_BY_GROUP_SUCCESS = `${REPORT}/REPORT_BY_GROUP_SUCCESS`;

export const REPORTS_INDEX_CLOSED_LOAD = `${REPORT}/INDEX_CLOSED_LOAD`;
export const REPORTS_INDEX_CLOSED_SUCCESS = `${REPORT}/INDEX_CLOSED_SUCCESS`;

export const REPORTS_CLOSE_REPORT = `${REPORT}/CLOSE_REPORT`;
export const REPORTS_CLOSE_REPORT_SUCCESS = `${REPORT}/CLOSE_REPORT_SUCCESS`;

export const REPORTS_DELETE_REPORT = `${REPORT}/DELETE_REPORT`;

export const QUEUE_SCAN_ERROR = `${QUEUE_SCAN}/ERROR`;
export const QUEUE_SCAN_CLEANUP = `${QUEUE_SCAN}/CLEANUP`;
export const QUEUE_SCAN_IS_LOADING = `${QUEUE_SCAN}/IS_LOADING`;
export const QUEUE_SCAN_INIT = `${QUEUE_SCAN}/init`;

export const QUEUE_SCAN_LOAD_GROUPS_SUCCESS = `${QUEUE_SCAN}/LOAD_GROUPS_SUCCESS `;

export const QUEUE_SCAN_LOAD_VEGETATIVE_STAGES = `${QUEUE_SCAN}/LOAD_VEGETATIVE_STAGES`;
export const QUEUE_SCAN_LOAD_VEGETATIVE_STAGES_SUCCESS = `${QUEUE_SCAN}/LOAD_VEGETATIVE_STAGES_SUCCESS`;
export const QUEUE_SCAN_LOAD_TRAPS = `${QUEUE_SCAN}/LOAD_TRAPS`;
export const QUEUE_SCAN_LOAD_TRAPS_SUCCESS = `${QUEUE_SCAN}/LOAD_TRAPS_SUCCESS`;

export const QUEUE_SCAN_NEXT_TRAP = `${QUEUE_SCAN}/NEXT_TRAP`;
export const QUEUE_SCAN_NEXT_TRAP_SUCCESS = `${QUEUE_SCAN}/NEXT_TRAP_SUCCESS`;
export const QUEUE_SCAN_PREV_TRAP = `${QUEUE_SCAN}/PREV_TRAP`;
export const QUEUE_SCAN_PREV_TRAP_SUCCESS = `${QUEUE_SCAN}/PREV_TRAP_SUCCESS`;
export const QUEUE_SCAN_CHANGE_TRAP = `${QUEUE_SCAN}/CHANGE_TRAP`;
export const QUEUE_SCAN_CHANGE_TRAP_SUCCESS = `${QUEUE_SCAN}/CHANGE_TRAP_SUCCESS`;
export const QUEUE_SCAN_CHANGE_SIDE = `${QUEUE_SCAN}/CHANGE_SIDE`;
export const QUEUE_SCAN_UPLOAD_IMAGE = `${QUEUE_SCAN}/UPLOAD_IMAGE`;
export const QUEUE_SCAN_UPLOAD_IMAGE_SUCCESS = `${QUEUE_SCAN}/UPLOAD_IMAGE_SUCCESS`;
export const QUEUE_SCAN_DELETE_IMAGE = `${QUEUE_SCAN}/DELETE_IMAGE`;
export const QUEUE_SCAN_UPDATE_CURRENT_TRAP = `${QUEUE_SCAN}/UPDATE_CURRENT_TRAP`;
export const QUEUE_SCAN_TRAP_MISSING_DATA_ERROR = `${QUEUE_SCAN}/TRAP_MISSING_DATA_ERROR`;
export const QUEUE_SCAN_CHANGE_TRAP_CLEAR_CURRENT = `${QUEUE_SCAN}/CHANGE_TRAP_CLEAR_CURRENT`;
export const QUEUE_SCAN_TRAP_MISSING_DATA_ERROR_CLEAR = `${QUEUE_SCAN}/TRAP_MISSING_DATA_ERROR_CLEAR`;
export const QUEUE_SCAN_SUBMIT_TRAPS = `${QUEUE_SCAN}/SUBMIT_TRAPS`;
export const QUEUE_SCAN_SUBMIT_TRAPS_ERROR = `${QUEUE_SCAN}/SUBMIT_TRAPS_ERROR`;
export const QUEUE_SCAN_CLEAR_TRAPS_WITH_ERROR = `${QUEUE_SCAN}/CLEAR_TRAPS_WITH_ERROR`;
export const QUEUE_SCAN_SUBMIT_VALID_TRAPS = `${QUEUE_SCAN}/SUBMIT_VALID_TRAPS`;
