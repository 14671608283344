import axios from 'axios';

export const uploadImageService = async ({ file, uploadUrl }) => {
  try {
    const contentType = file.type;
    const options = {
      params: {
        Key: file.name,
        ContentType: contentType,
      },
      headers: {
        'Content-Type': contentType,
      },
    };
    const { data } = await axios.put(uploadUrl, file, options);
    return data;
  } catch (e) {
    if (e.response) {
      throw e.response.data;
    }
    const error = {
      message: 'Ocorreu um erro por favor tente novamente!',
    };
    throw error;
  }
};
