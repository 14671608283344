import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { groupsIndexAction } from 'store/modules/groups/actions';
import { groupsListSelector } from 'store/modules/groups/selectors';

import { useStyles } from '../../styles';
import GroupCard from './groupCard';

const GroupsList = () => {
  const classes = useStyles();
  const groups = useSelector(groupsListSelector) || [];
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(groupsIndexAction());
  }, [dispatch]);

  return (
    <div className={classes.listContainer}>
      {groups.map((group) => (
        <GroupCard key={group.id} group={group} />
      ))}
    </div>
  );
};

export default GroupsList;
