import { takeLatest, put } from 'redux-saga/effects';
import {
  indexImages,
  getImageDetails,
  getUploadUrl,
  putImage,
} from 'services/images';
import { uploadImageService } from 'services/aws-s3';
import {
  IMAGE_INDEX_LOAD,
  IMAGE_FIND_LOAD,
  IMAGE_UPDATE,
  IMAGE_SUBMIT_UPDATED,
} from 'store/types';
import {
  errorNotificationAction,
  successNotificationAction,
} from 'store/modules/notifications/actions';

import {
  imageIsLoadingAction,
  imageIndexSuccessAction,
  imageErrorAction,
  imageFindSuccessAction,
  updateImageSuccessAction,
  imageSubmitupdateSuccessAction,
} from './actions';

function* loadImages({ data }) {
  yield put(imageIsLoadingAction());

  try {
    const images = yield indexImages(data);
    yield put(imageIndexSuccessAction(images));
  } catch (error) {
    yield put(errorNotificationAction('Não foi possivel carregar as imagens!'));
    yield put(imageErrorAction(error));
  }
}

function* findImage({ data }) {
  yield put(imageIsLoadingAction());

  try {
    const image = yield getImageDetails(data);

    yield put(imageFindSuccessAction(image));
  } catch (error) {
    yield put(errorNotificationAction('Não foi possivel encontrar a imagem!'));
    yield put(imageErrorAction(error));
  }
}

function* updateImage({ data }) {
  yield put(imageIsLoadingAction());

  try {
    const { file, side } = data;

    const { uploadUrl } = yield getUploadUrl({
      fileName: file.name,
      fileType: file.type,
    });

    yield uploadImageService({ file, uploadUrl });
    const url = uploadUrl.split('?')[0];

    yield put(updateImageSuccessAction({ side, url }));
  } catch (error) {
    yield put(errorNotificationAction('Não foi possivel enviar a imagem!'));
    yield put(imageErrorAction(error));
  }
}

function* submitUpdatedImage({ data }) {
  yield put(imageIsLoadingAction());

  try {
    const updated = yield putImage(data);

    yield put(imageSubmitupdateSuccessAction(updated));
    yield put(successNotificationAction('Imagem atualizada com successo'));
  } catch (error) {
    yield put(errorNotificationAction('Não foi possivel encontrar a imagem!'));
    yield put(imageErrorAction(error));
  }
}

const imagesSagas = [
  takeLatest(IMAGE_INDEX_LOAD, loadImages),
  takeLatest(IMAGE_FIND_LOAD, findImage),
  takeLatest(IMAGE_UPDATE, updateImage),
  takeLatest(IMAGE_SUBMIT_UPDATED, submitUpdatedImage),
];

export default imagesSagas;
