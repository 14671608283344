import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import ROUTER_PATH from 'constants/router';

import Topbar from 'components/layout/topbar';
import ScanGroupsSelect from 'components/ScanGroupsSelect';

import Button from '@material-ui/core/Button';

import { queueScanInitAction } from 'store/modules/queue-scan/actions';
import { groupsSelector } from 'store/modules/queue-scan/selectors';

import { useStyles } from './styles';

const QueueScanInit = () => {
  const { defaultDate } = useParams();
  const classes = useStyles();
  const [selectedGroup, setSelectedGroup] = useState('');
  const groupsList = useSelector(groupsSelector) || [];
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(queueScanInitAction(defaultDate));
  }, [dispatch, defaultDate]);

  const insertImagesClickHandler = () => {
    history.push(`${ROUTER_PATH.QUEUE_SCAN_IMAGES}/${selectedGroup}`);
  };

  return (
    <div>
      <Topbar title=" Leitura Sequencial" subtitle="Selecionando grupo" />
      <div className={classes.container}>
        <div>
          <ScanGroupsSelect
            selected={selectedGroup}
            setSelected={setSelectedGroup}
            groupsList={groupsList}
          />
          <Button
            fullWidth
            color="primary"
            variant="contained"
            className={classes.whiteBtn}
            size="large"
            onClick={insertImagesClickHandler}
          >
            INSERIR IMAGENS
          </Button>
        </div>
      </div>
    </div>
  );
};

export default QueueScanInit;
