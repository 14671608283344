import axios from '../utils/axios';

export const indexImages = async (trapId) => {
  try {
    const { data } = await axios.get(`/images/${trapId}`);
    return data;
  } catch (e) {
    if (e.response) {
      throw e.response.data;
    }
    const error = {
      message: 'Ocorreu um erro por favor tente novamente!',
    };
    throw error;
  }
};

export const getImageDetails = async (id) => {
  try {
    const { data } = await axios.get(`/images/details/${id}`);
    return data;
  } catch (e) {
    if (e.response) {
      throw e.response.data;
    }
    const error = {
      message: 'Ocorreu um erro por favor tente novamente!',
    };
    throw error;
  }
};

export const getUploadUrl = async ({ fileName, fileType }) => {
  try {
    const { data } = await axios.post('/images/upload', { fileName, fileType });

    return data;
  } catch (e) {
    if (e.response) {
      throw e.response.data;
    }
    const error = {
      message: 'Ocorreu um erro por favor tente novamente!',
    };
    throw error;
  }
};

export const storeImages = async (images) => {
  try {
    const { data } = await axios.post('/images', { images });

    return data;
  } catch (e) {
    if (e.response) {
      throw e.response.data;
    }
    const error = {
      message: 'Ocorreu um erro por favor tente novamente!',
    };
    throw error;
  }
};

export const putImage = async (image) => {
  try {
    const { data } = await axios.put(`/images/${image.id}`, {
      frontImageUrl: image.frontImageUrl,
      backImageUrl: image.backImageUrl,
    });

    return data;
  } catch (e) {
    if (e.response) {
      throw e.response.data;
    }
    const error = {
      message: 'Ocorreu um erro por favor tente novamente!',
    };
    throw error;
  }
};

export const indexVegetativeStages = async () => {
  try {
    const { data } = await axios.get(`/images/vegetative_stages`);
    return data;
  } catch (e) {
    if (e.response) {
      throw e.response.data;
    }
    const error = {
      message: 'Ocorreu um erro por favor tente novamente!',
    };
    throw error;
  }
};
