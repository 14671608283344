import React from 'react';
import { useSelector } from 'react-redux';

import { selectedReportDetailsSelector } from 'store/modules/reports/selectors';

import Card from './card';
import { useStyles } from '../../styles';

const ReportDetails = () => {
  const classes = useStyles();

  const reportDetailsData = useSelector(selectedReportDetailsSelector);

  return (
    <div className={classes.detailsContainer}>
      {reportDetailsData.map(({ title, data, totalAmount }) => (
        <Card key={title} title={title} data={data} totalAmount={totalAmount} />
      ))}
    </div>
  );
};

export default ReportDetails;
