import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';
import ROUTER_PATH from 'constants/router';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ptBrLocal from 'date-fns/locale/pt-BR';

import Button from './NavButton';

import { useStyles } from './styles';

const ScanTypeModal = ({ open, close }) => {
  const classes = useStyles();
  const [selectedDate, handleDateChange] = useState(new Date());
  const history = useHistory();

  const queueClickHandler = () => {
    const date = selectedDate.toJSON();
    history.push(`${ROUTER_PATH.QUEUE_SCAN_INIT}/${date}`);
  };

  const loteClickHandler = () => {
    const date = selectedDate.toJSON();
    history.push(`${ROUTER_PATH.LOT_SCAN_INIT}/${date}`);
  };

  const singleClickHandler = () => {
    const date = selectedDate.toJSON();
    history.push(`${ROUTER_PATH.SINGE_SCAN}/${date}`);
  };

  return (
    <Dialog open={open} onClose={close} maxWidth="xs">
      <DialogTitle className={classes.title}>
        Selecione o modo de leitura
      </DialogTitle>
      <DialogContent className={classes.card}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBrLocal}>
          <KeyboardDatePicker
            label="Data das imagens"
            value={selectedDate}
            onChange={handleDateChange}
            animateYearScrolling
            format="dd/MM/yyyy"
            fullWidth
          />
        </MuiPickersUtilsProvider>
        <Button
          title="sequencial"
          subTitle="Tire a foto de várias armadilhas"
          onClick={queueClickHandler}
        />

        <Button
          title="Lote"
          subTitle="Enviar arquivos de foto"
          onClick={loteClickHandler}
        />
        <Button
          title="individual"
          subTitle="Tire a foto de uma armadilha"
          onClick={singleClickHandler}
        />
      </DialogContent>
    </Dialog>
  );
};

ScanTypeModal.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};

export default ScanTypeModal;
