import {
  IMAGE_CLEANUP,
  IMAGE_ERROR,
  IMAGE_IS_LOADING,
  IMAGE_INDEX_LOAD,
  IMAGE_INDEX_SUCCESS,
  IMAGE_FIND_LOAD,
  IMAGE_FIND_SUCCESS,
  IMAGE_UPDATE,
  IMAGE_UPDATE_SUCCESS,
  IMAGE_SUBMIT_UPDATED,
  IMAGE_SUBMIT_UPDATED_SUCCESS,
} from 'store/types';

export const imageCleanUpAction = () => ({
  type: IMAGE_CLEANUP,
});

export const imageErrorAction = (error) => ({
  type: IMAGE_ERROR,
  data: error,
});

export const imageIsLoadingAction = () => ({
  type: IMAGE_IS_LOADING,
});

export const imageIndexLoadAction = (trapId) => ({
  type: IMAGE_INDEX_LOAD,
  data: trapId,
});

export const imageIndexSuccessAction = (trapWithImages) => ({
  type: IMAGE_INDEX_SUCCESS,
  data: trapWithImages,
});

export const imageFindLoadAction = (id) => ({
  type: IMAGE_FIND_LOAD,
  data: id,
});

export const imageFindSuccessAction = (image) => ({
  type: IMAGE_FIND_SUCCESS,
  data: image,
});

export const updateImageAction = ({ side, file }) => ({
  type: IMAGE_UPDATE,
  data: { side, file },
});

export const updateImageSuccessAction = ({ side, url }) => ({
  type: IMAGE_UPDATE_SUCCESS,
  data: { side, url },
});

export const imageSubmitupdateAction = (image) => ({
  type: IMAGE_SUBMIT_UPDATED,
  data: image,
});

export const imageSubmitupdateSuccessAction = (image) => ({
  type: IMAGE_SUBMIT_UPDATED_SUCCESS,
  data: image,
});
