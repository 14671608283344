import { createSelector } from 'reselect';
import { isEmpty } from 'lodash';

export const lotScanSelector = (state) => state.lotScan;

export const lotScanDataSelector = createSelector(
  lotScanSelector,
  (lotScan) => lotScan.data
);

export const lotScanIsLoadingSelector = createSelector(
  lotScanSelector,
  (lotScan) => lotScan.isLoading
);

export const lotScanHasLoadedSelector = createSelector(
  lotScanSelector,
  (lotScan) => lotScan.hasLoaded
);

export const lotScanErrorSelector = createSelector(
  lotScanSelector,
  (lotScan) => lotScan.error
);

export const lotScanHasErrorSelector = createSelector(
  lotScanErrorSelector,
  (error) => !isEmpty(error)
);

export const groupsSelector = createSelector(
  lotScanDataSelector,
  (data) => data.groups
);

export const imagesSelector = createSelector(
  lotScanDataSelector,
  (data) => data.images
);

export const hasImagesSelector = createSelector(
  imagesSelector,
  (images) => !isEmpty(images)
);

export const trapsSelector = createSelector(
  lotScanDataSelector,
  (data) => data.traps
);

export const imagesCountSelector = createSelector(
  imagesSelector,
  (images) => images.length || 0
);

export const currentPageSelector = createSelector(
  lotScanDataSelector,
  (data) => data.currentPage || 1
);

export const vegetativeStagesSelector = createSelector(
  lotScanDataSelector,
  (data) =>
    data.vegetativeStages &&
    data.vegetativeStages.map((trap) => ({
      value: trap.id,
      label: trap.name,
    }))
);

export const isUploadingSelector = createSelector(
  lotScanSelector,
  (lotScan) => lotScan.isUploading
);
