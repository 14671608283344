import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import ROUTER_PATHS from 'constants/router';

import { useStyles } from '../../styles';

const ReportCard = ({ report }) => {
  const classes = useStyles();
  const history = useHistory();
  const startDate = moment(report.startDate).format('DD/MM/YYYY');
  const endDate = report.endDate && moment(report.endDate).format('DD/MM/YYYY');

  const reportClickHandler = () => {
    history.push(`${ROUTER_PATHS.REPORT}/${report.id}`);
  };

  const renderEndDate = () =>
    endDate ? (
      <Typography
        variant="subtitle1"
        align="center"
        className={classes.cardDate}
      >
        Final- {endDate}
      </Typography>
    ) : null;

  return (
    <Paper className={classes.listItem} onClick={reportClickHandler}>
      <Typography variant="h4" align="center" className={classes.cardTitle}>
        {report.name}
      </Typography>
      <Typography
        variant="subtitle1"
        align="center"
        className={classes.cardDate}
      >
        Início- {startDate}
      </Typography>
      {renderEndDate()}
      <Typography variant="h6" className={classes.cardTextBold}>
        Armadilhas: {report.totalTrapsCount}
      </Typography>
      <Typography variant="subtitle1" className={classes.cardText}>
        Imagens inseridas: {report.totalimagesCount}
      </Typography>
      <Typography variant="h6" className={classes.cardTextBold}>
        Total de psilídeos: ##{' '}
      </Typography>
    </Paper>
  );
};

ReportCard.propTypes = {
  report: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    startDate: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]).isRequired,
    endDate: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
    totalTrapsCount: PropTypes.number.isRequired,
    totalimagesCount: PropTypes.number.isRequired,
  }).isRequired,
};

export default ReportCard;
