import React from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';

const Page = ({ number, variant, className, onChange }) => {
  return (
    <Button className={className} fullWidth onClick={() => onChange(number)}>
      {variant === 'last' ? ' ... ' : ''}
      {number}
      {variant === 'first' ? ' ... ' : ''}
    </Button>
  );
};

Page.defaultProps = {
  variant: '',
};

Page.propTypes = {
  number: PropTypes.number.isRequired,
  className: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['first', 'last', '']),
  onChange: PropTypes.func.isRequired,
};

export default Page;
