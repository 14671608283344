import produce from 'immer';
import {
  QUEUE_SCAN_INIT,
  QUEUE_SCAN_CLEANUP,
  QUEUE_SCAN_IS_LOADING,
  QUEUE_SCAN_ERROR,
  QUEUE_SCAN_LOAD_GROUPS_SUCCESS,
  QUEUE_SCAN_LOAD_VEGETATIVE_STAGES_SUCCESS,
  QUEUE_SCAN_LOAD_TRAPS_SUCCESS,
  QUEUE_SCAN_NEXT_TRAP_SUCCESS,
  QUEUE_SCAN_PREV_TRAP_SUCCESS,
  QUEUE_SCAN_CHANGE_TRAP_SUCCESS,
  QUEUE_SCAN_CHANGE_SIDE,
  QUEUE_SCAN_UPLOAD_IMAGE_SUCCESS,
  QUEUE_SCAN_DELETE_IMAGE,
  QUEUE_SCAN_UPDATE_CURRENT_TRAP,
  QUEUE_SCAN_TRAP_MISSING_DATA_ERROR,
  QUEUE_SCAN_CHANGE_TRAP_CLEAR_CURRENT,
  QUEUE_SCAN_TRAP_MISSING_DATA_ERROR_CLEAR,
  QUEUE_SCAN_SUBMIT_TRAPS_ERROR,
  QUEUE_SCAN_CLEAR_TRAPS_WITH_ERROR,
} from 'store/types';

export const initialState = {
  isLoading: false,
  hasLoaded: false,
  error: {},
  data: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case QUEUE_SCAN_CLEAR_TRAPS_WITH_ERROR:
      return produce(state, (draft) => {
        draft.data.trapsWithError = null;
      });

    case QUEUE_SCAN_SUBMIT_TRAPS_ERROR:
      return produce(state, (draft) => {
        draft.data.trapsWithError = action.data;
      });

    case QUEUE_SCAN_CHANGE_TRAP_CLEAR_CURRENT:
      return produce(state, (draft) => {
        const { currentTrap, changeTrapError, queue, defaultDate } = draft.data;
        draft.data.queue[currentTrap] = {
          trap: queue[currentTrap].trap,
          vegetativeStage: '',
          frontImageUrl: '',
          backImageUrl: '',
          captureDate: defaultDate,
        };
        draft.data.currentTrap = changeTrapError.targetTrap;

        draft.data.changeTrapError = null;
      });

    case QUEUE_SCAN_TRAP_MISSING_DATA_ERROR:
      return produce(state, (draft) => {
        draft.data.changeTrapError = { targetTrap: action.data };
      });

    case QUEUE_SCAN_TRAP_MISSING_DATA_ERROR_CLEAR:
      return produce(state, (draft) => {
        draft.data.changeTrapError = null;
      });

    case QUEUE_SCAN_UPDATE_CURRENT_TRAP:
      return produce(state, (draft) => {
        const { currentTrap } = draft.data;
        draft.data.queue[currentTrap] = {
          ...draft.data.queue[currentTrap],
          ...action.data,
        };
      });

    case QUEUE_SCAN_DELETE_IMAGE:
      return produce(state, (draft) => {
        const { frontSide, currentTrap } = draft.data;

        if (frontSide) {
          draft.data.queue[currentTrap].frontImageUrl = '';
        } else {
          draft.data.queue[currentTrap].backImageUrl = '';
        }
      });

    case QUEUE_SCAN_UPLOAD_IMAGE_SUCCESS:
      return produce(state, (draft) => {
        draft.isLoading = false;
        draft.hasLoaded = true;
        const { frontSide, currentTrap } = draft.data;

        if (frontSide) {
          draft.data.queue[currentTrap].frontImageUrl = action.data;
        } else {
          draft.data.queue[currentTrap].backImageUrl = action.data;
        }
      });

    case QUEUE_SCAN_CHANGE_SIDE:
      return produce(state, (draft) => {
        draft.data.frontSide = !draft.data.frontSide;
      });

    case QUEUE_SCAN_CHANGE_TRAP_SUCCESS:
      return produce(state, (draft) => {
        draft.isLoading = false;
        draft.hasLoaded = true;
        draft.data.currentTrap = action.data;
        draft.data.frontSide = true;
      });

    case QUEUE_SCAN_PREV_TRAP_SUCCESS:
      return produce(state, (draft) => {
        draft.isLoading = false;
        draft.hasLoaded = true;
        draft.data.currentTrap -= 1;
        draft.data.frontSide = true;
      });

    case QUEUE_SCAN_NEXT_TRAP_SUCCESS:
      return produce(state, (draft) => {
        draft.isLoading = false;
        draft.hasLoaded = true;
        draft.data.currentTrap += 1;
        draft.data.frontSide = true;
      });

    case QUEUE_SCAN_LOAD_TRAPS_SUCCESS:
      return produce(state, (draft) => {
        draft.isLoading = false;
        draft.hasLoaded = true;
        draft.data.queue = action.data;
        draft.data.currentTrap = 0;
        draft.data.frontSide = true;
      });

    case QUEUE_SCAN_LOAD_VEGETATIVE_STAGES_SUCCESS:
      return produce(state, (draft) => {
        draft.isLoading = false;
        draft.hasLoaded = true;
        draft.data.vegetativeStages = action.data;
      });

    case QUEUE_SCAN_LOAD_GROUPS_SUCCESS:
      return produce(state, (draft) => {
        draft.data.groups = action.data;
        draft.isLoading = false;
        draft.hasLoaded = true;
      });

    case QUEUE_SCAN_INIT:
      return produce(state, (draft) => {
        draft.data.defaultDate = action.data;
      });

    case QUEUE_SCAN_IS_LOADING:
      return produce(state, (draft) => {
        draft.isLoading = true;
        draft.hasLoaded = false;
      });

    case QUEUE_SCAN_ERROR:
      return produce(state, (draft) => {
        draft.isLoading = false;
        draft.hasLoaded = true;
        draft.error = action.data;
      });
    case QUEUE_SCAN_CLEANUP:
      return initialState;

    default:
      return state;
  }
};
