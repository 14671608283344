import axios from '../utils/axios';

export const storeReport = async (report) => {
  try {
    const { data } = await axios.post('/reports/', report);
    return data;
  } catch (e) {
    if (e.response) {
      throw e.response.data;
    }
    const error = {
      message: 'Ocorreu um erro por favor tente novamente!',
    };
    throw error;
  }
};

export const getReport = async (id) => {
  try {
    const { data } = await axios.get(`/reports/${id}`);
    return data;
  } catch (e) {
    if (e.response) {
      throw e.response.data;
    }
    const error = {
      message: 'Ocorreu um erro por favor tente novamente!',
    };
    throw error;
  }
};

export const putReport = async ({ id, name, startDate, groups }) => {
  try {
    const { data } = await axios.put(`/reports/${id}`, {
      name,
      startDate,
      groups,
    });
    return data;
  } catch (e) {
    if (e.response) {
      throw e.response.data;
    }
    const error = {
      message: 'Ocorreu um erro por favor tente novamente!',
    };
    throw error;
  }
};

export const indexOpenReports = async () => {
  try {
    const { data } = await axios.get('/reports/open');
    return data;
  } catch (e) {
    if (e.response) {
      throw e.response.data;
    }
    const error = {
      message: 'Ocorreu um erro por favor tente novamente!',
    };
    throw error;
  }
};

export const indexClosedReports = async () => {
  try {
    const { data } = await axios.get('/reports/closed');
    return data;
  } catch (e) {
    if (e.response) {
      throw e.response.data;
    }
    const error = {
      message: 'Ocorreu um erro por favor tente novamente!',
    };
    throw error;
  }
};

export const putCloseReport = async ({ id, endDate }) => {
  try {
    const { data } = await axios.put(`/reports/close/${id}`, {
      endDate,
    });
    return data;
  } catch (e) {
    if (e.response) {
      throw e.response.data;
    }
    const error = {
      message: 'Ocorreu um erro por favor tente novamente!',
    };
    throw error;
  }
};

export const getReportByVegetativeStage = async ({ id }) => {
  try {
    const { data } = await axios.get(`/reports/by_vegetative_stage/${id}`);
    return data;
  } catch (e) {
    if (e.response) {
      throw e.response.data;
    }
    const error = {
      message: 'Ocorreu um erro por favor tente novamente!',
    };
    throw error;
  }
};

export const getReportByGroup = async ({ id }) => {
  try {
    const { data } = await axios.get(`/reports/by_group/${id}`);
    return data;
  } catch (e) {
    if (e.response) {
      throw e.response.data;
    }
    const error = {
      message: 'Ocorreu um erro por favor tente novamente!',
    };
    throw error;
  }
};

export const deleteReportService = async ({ id }) => {
  try {
    const { data } = await axios.delete(`/reports/${id}`);
    return data;
  } catch (e) {
    if (e.response) {
      throw e.response.data;
    }
    const error = {
      message: 'Ocorreu um erro por favor tente novamente!',
    };
    throw error;
  }
};
