import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';

import { groupIsCheckedSelector } from 'store/modules/reports/selectors';
import {
  addGroupToSelectedReportAction,
  removeGroupFromSelectedReportAction,
} from 'store/modules/reports/actions';

import { useStyles } from '../../styles';

const GroupItem = ({ group }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const groupIsChecked = useSelector(groupIsCheckedSelector(group.id));

  const toggleGroupCheckbox = () => {
    if (groupIsChecked) {
      return dispatch(removeGroupFromSelectedReportAction(group));
    }
    return dispatch(addGroupToSelectedReportAction(group));
  };

  return (
    <Container className={classes.groupContainer}>
      <Typography variant="subtitle2" className={classes.groupText}>
        {group.name}
      </Typography>
      <Checkbox
        color="primary"
        checked={groupIsChecked}
        onClick={toggleGroupCheckbox}
      />
    </Container>
  );
};

GroupItem.propTypes = {
  group: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
};

export default GroupItem;
