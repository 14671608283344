import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  navButton: {
    marginRight: theme.spacing(1),
    minWidth: '180px',
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(2),
      width: '45%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
  },
  selected: {
    cursor: 'default',
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  },
}));
