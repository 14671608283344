import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  navContainer: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      width: '100%',
    },
    justifyContent: 'space-between',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  addButton: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.primary.main,
    minWidth: '180px',
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(2),
      width: '45%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
  },
  listContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  listItem: {
    margin: theme.spacing(3),
    minWidth: '234px',
    maxWidth: '518px',
    minHeight: '200px',
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  cardTitle: {
    marginBottom: theme.spacing(1),
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '1.5rem',
  },
  cardContent: {
    marginBottom: theme.spacing(1),
  },
  cardButtons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  cardButton: {
    [theme.breakpoints.up('md')]: {
      minWidth: '180px',
    },
  },
  addTrapForm: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  inputField: {
    width: '100%',
    paddingBottom: theme.spacing(2),
  },
  halfWidth: {
    width: '45%',
  },
  buttonIcon: {
    marginRight: theme.spacing(1),
  },
  searchInput: {
    marginTop: -theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(2),
      width: '45%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
  },
}));
