import React from 'react';
import PropTypes from 'prop-types';

import { useDispatch } from 'react-redux';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import {
  selectTrapToEditAction,
  deleteTrapAction,
} from 'store/modules/traps/actions';

const Actions = ({ handleClose, anchorEl, open, trapId }) => {
  const dispatch = useDispatch();

  const editHandler = () => {
    dispatch(selectTrapToEditAction(trapId));
    handleClose();
  };

  const deleteHandler = () => {
    dispatch(deleteTrapAction(trapId));
    handleClose();
  };

  return (
    <Menu anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
      <MenuItem onClick={editHandler}>Editar</MenuItem>
      <MenuItem onClick={deleteHandler}>Excluir</MenuItem>
    </Menu>
  );
};

Actions.propTypes = {
  handleClose: PropTypes.func.isRequired,
  anchorEl: PropTypes.element.isRequired,
  open: PropTypes.bool.isRequired,
  trapId: PropTypes.string.isRequired,
};

export default Actions;
