import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { selectedReportSelector } from 'store/modules/reports/selectors';

import { useStyles } from '../../styles';

const ReportSummary = () => {
  const classes = useStyles();

  const report = useSelector(selectedReportSelector);

  const startDate = moment(report.startDate).format('DD/MM/YYYY');
  const endDate = report.endDate && moment(report.endDate).format('DD/MM/YYYY');

  const renderEndDate = () =>
    endDate ? (
      <Typography
        variant="subtitle1"
        align="center"
        className={classes.summaryDate}
      >
        Final- {endDate}
      </Typography>
    ) : null;

  return (
    <Paper className={classes.summaryPaper}>
      <Typography variant="h4" align="center" className={classes.summaryTitle}>
        Resumo do relatório
      </Typography>
      <Typography
        variant="subtitle1"
        align="center"
        className={classes.summaryDate}
      >
        Início- {startDate}
      </Typography>
      {renderEndDate()}
      <Typography variant="h6" className={classes.summaryTextBold}>
        Armadilhas: {report.totalTrapsCount}
      </Typography>
      <Typography variant="subtitle1" className={classes.summaryText}>
        Imagens inseridas: {report.totalimagesCount}
      </Typography>
      <Typography variant="h6" className={classes.summaryTextBold}>
        Total de psilídeos: ##{' '}
      </Typography>
    </Paper>
  );
};

export default ReportSummary;
