import {
  LOT_SCAN_INIT,
  LOT_SCAN_IS_LOADING,
  LOT_SCAN_CLEANUP,
  LOT_SCAN_ERROR,
  LOT_SCAN_LOAD_GROUPS_SUCCESS,
  LOT_SCAN_ADD_IMAGE,
  LOT_SCAN_ADD_IMAGE_SUCCESS,
  LOT_SCAN_LOAD_TRAPS,
  LOT_SCAN_LOAD_TRAPS_SUCCESS,
  LOT_SCAN_UPDATE_IMAGE,
  LOT_SCAN_UPDATE_IMAGE_SUCCESS,
  LOT_SCAN_CHANGE_PAGE,
  LOT_SCAN_SUBMIT_IMAGES,
  LOT_SCAN_LOAD_VEGETATIVE_STAGES,
  LOT_SCAN_LOAD_VEGETATIVE_STAGES_SUCCESS,
  LOT_SCAN_IS_UPLOADING,
  LOT_SCAN_DONE_UPLOADING,
} from 'store/types';

export const lotScanInitAction = (date) => ({
  type: LOT_SCAN_INIT,
  data: date,
});

export const lotScanIsLoadingAction = () => ({
  type: LOT_SCAN_IS_LOADING,
});

export const lotScanCleanUpAction = () => ({
  type: LOT_SCAN_CLEANUP,
});

export const lotScanErrorAction = (error) => ({
  type: LOT_SCAN_ERROR,
  data: error,
});

export const loadGroupsSuccessAction = (groups) => ({
  type: LOT_SCAN_LOAD_GROUPS_SUCCESS,
  data: groups,
});

export const addImageAction = (files) => ({
  type: LOT_SCAN_ADD_IMAGE,
  data: files,
});

export const addImageSuccessAction = (files) => ({
  type: LOT_SCAN_ADD_IMAGE_SUCCESS,
  data: files,
});

export const loadTrapsAction = (groupId) => ({
  type: LOT_SCAN_LOAD_TRAPS,
  data: groupId,
});

export const loadTrapsSuccessAction = (traps) => ({
  type: LOT_SCAN_LOAD_TRAPS_SUCCESS,
  data: traps,
});

export const updateImageAction = (updatedImage) => ({
  type: LOT_SCAN_UPDATE_IMAGE,
  data: updatedImage,
});

export const updateImageSuccessAction = (updatedImage) => ({
  type: LOT_SCAN_UPDATE_IMAGE_SUCCESS,
  data: updatedImage,
});

export const changePageAction = (page) => ({
  type: LOT_SCAN_CHANGE_PAGE,
  data: page,
});

export const submitImagesAction = () => ({
  type: LOT_SCAN_SUBMIT_IMAGES,
});

export const loadVegetativeStagesAction = () => ({
  type: LOT_SCAN_LOAD_VEGETATIVE_STAGES,
});

export const loadVegetativeStagesSuccessAction = (stages) => ({
  type: LOT_SCAN_LOAD_VEGETATIVE_STAGES_SUCCESS,
  data: stages,
});

export const uploadingAction = () => ({
  type: LOT_SCAN_IS_UPLOADING,
});

export const doneUploadingAction = () => ({
  type: LOT_SCAN_DONE_UPLOADING,
});
