import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import ROUTER_PATH from 'constants/router';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import ImageIcon from 'assets/icons/ImageGreen.svg';
import StatusBar from 'components/StatusBar';

import { useStyles } from '../../styles';

const ImageCard = ({ image }) => {
  const classes = useStyles();
  const history = useHistory();

  const renderStatusBar = () => {
    switch (image.status) {
      case 0:
        return <StatusBar text="Erro na leitura" variant="red" />;
      case 1:
        return <StatusBar text="Processando Imagem" variant="yellow" />;
      case 2:
        return (
          <StatusBar text="Leitura realizada com sucesso" variant="green" />
        );
      default:
        return '';
    }
  };

  const date = moment(image.captureDate).format('DD/MM/YYYY');

  return (
    <div className={classes.listItem}>
      <Paper
        className={classes.paper}
        onClick={() =>
          history.push(`${ROUTER_PATH.TRAP_IMAGE_DETAILS}/${image.id}`)
        }
      >
        <Typography
          variant="h4"
          align="center"
          className={classes.cardTitleText}
        >
          {date}
        </Typography>

        <div className={classes.container}>
          <Typography variant="h6">
            ## Total de psilídeos:{image.incectsCount}
          </Typography>
          <div>
            <Button color="primary" className={classes.cardtitleIcon}>
              <img src={ImageIcon} alt="ImageIcon" className={classes.icon} />
              FOTO
            </Button>
          </div>
        </div>
      </Paper>
      {renderStatusBar()}
    </div>
  );
};

ImageCard.propTypes = {
  image: PropTypes.shape({
    id: PropTypes.string.isRequired,
    captureDate: PropTypes.string.isRequired,
    status: PropTypes.number.isRequired,
    incectsCount: PropTypes.number,
  }).isRequired,
};

export default ImageCard;
