import { takeLatest, put, select, call } from 'redux-saga/effects';
import ROUTER_PATHS from 'constants/router';
import history from 'services/history';
import {
  REPORTS_LOAD_GROUPS,
  REPORTS_NEW_REPORT_SUBMIT,
  REPORTS_LOAD_REPORT,
  REPORTS_UPDATE_REPORT_SUBMIT,
  REPORTS_INDEX_OPEN_LOAD,
  REPORTS_REPORT_BY_VEGETATIVE_STAGE_LOAD,
  REPORTS_REPORT_BY_GROUP_LOAD,
  REPORTS_INDEX_CLOSED_LOAD,
  REPORTS_CLOSE_REPORT,
  REPORTS_DELETE_REPORT,
} from 'store/types';
import { indexGroups } from 'services/groups';
import {
  storeReport,
  getReport,
  putReport,
  indexOpenReports,
  indexClosedReports,
  putCloseReport,
  getReportByVegetativeStage,
  getReportByGroup,
  deleteReportService,
} from 'services/reports';
import {
  errorNotificationAction,
  successNotificationAction,
  alertNotificationAction,
} from 'store/modules/notifications/actions';

import {
  reportsIsLoadingAction,
  reportsErrorAction,
  loadGroupsSuccessAction,
  reportLoadSuccessAction,
  indexOpenReportsSuccessAction,
  reportByVegetativeStateSuccessAction,
  indexClosedReportsSuccessAction,
  closeReportSuccessAction,
} from './actions';
import { selectedReportSelector } from './selectors';
import { reportDataValidator } from './validators';
import {
  formatReport,
  formatReportByVegetativeStagesDetails,
  formatReportByGroupDetails,
} from './serializers';

function* loadGroups() {
  yield put(reportsIsLoadingAction());
  try {
    const groups = yield indexGroups();
    yield put(loadGroupsSuccessAction(groups));
  } catch (e) {
    yield put(errorNotificationAction('Não foi possivel carregar os grupos!'));
    yield put(reportsErrorAction(e));
  }
}

function* saveReport() {
  yield put(reportsIsLoadingAction());
  try {
    const report = yield select(selectedReportSelector);
    reportDataValidator(report);
    const formatedReport = formatReport(report);

    yield storeReport(formatedReport);
    yield put(successNotificationAction('Relatório criado com sucesso'));
    yield call(history.push, ROUTER_PATHS.HOME);
  } catch (e) {
    yield put(errorNotificationAction(e.message));
    yield put(reportsErrorAction(e));
  }
}

function* loadReport({ data }) {
  yield put(reportsIsLoadingAction());
  try {
    const report = yield getReport(data);

    yield put(reportLoadSuccessAction(report));
  } catch (e) {
    yield put(
      errorNotificationAction('Não foi possivel carregar o relatório!')
    );
    yield put(reportsErrorAction(e));
  }
}

function* updateReport() {
  yield put(reportsIsLoadingAction());
  try {
    const report = yield select(selectedReportSelector);
    reportDataValidator(report);
    const formatedReport = formatReport(report);

    yield putReport(formatedReport);
    yield put(successNotificationAction('Relatório atualizado com sucesso'));
    yield call(history.push, ROUTER_PATHS.HOME);
  } catch (e) {
    yield put(errorNotificationAction(e.message));
    yield put(reportsErrorAction(e));
  }
}

function* loadOpenReports() {
  yield put(reportsIsLoadingAction());
  try {
    const openReports = yield indexOpenReports();

    yield put(indexOpenReportsSuccessAction(openReports));
  } catch (e) {
    yield put(
      errorNotificationAction('Não foi possivel carregar os relatórios!')
    );
    yield put(reportsErrorAction(e));
  }
}

function* loadReportByVegetativeStage({ data }) {
  yield put(reportsIsLoadingAction());
  try {
    const report = yield getReportByVegetativeStage({ id: data });

    const formatedReport = {
      ...report,
      details: formatReportByVegetativeStagesDetails(report.details || []),
    };

    yield put(
      alertNotificationAction(
        `Caso a armadilha se encontra em varios grupoos,
        os psilidios serão contados somente no primeiro grupo que a armadilha se encontra`
      )
    );

    yield put(reportByVegetativeStateSuccessAction(formatedReport));
  } catch (e) {
    yield put(
      errorNotificationAction('Não foi possivel carregar o Relatório!')
    );
    yield put(reportsErrorAction(e));
  }
}

function* loadReportByGroup({ data }) {
  yield put(reportsIsLoadingAction());
  try {
    const report = yield getReportByGroup({ id: data });

    const formatedReport = {
      ...report,
      details: formatReportByGroupDetails(report.details || []),
    };

    yield put(reportByVegetativeStateSuccessAction(formatedReport));
  } catch (e) {
    yield put(
      errorNotificationAction('Não foi possivel carregar o Relatório!')
    );
    yield put(reportsErrorAction(e));
  }
}

function* loadClosedReports() {
  yield put(reportsIsLoadingAction());
  try {
    const closedReports = yield indexClosedReports();

    yield put(indexClosedReportsSuccessAction(closedReports));
  } catch (e) {
    yield put(
      errorNotificationAction('Não foi possivel carregar os relatórios!')
    );
    yield put(reportsErrorAction(e));
  }
}

function* closeReport({ data }) {
  yield put(reportsIsLoadingAction());
  try {
    const closedReport = yield putCloseReport(data);

    yield put(closeReportSuccessAction(closedReport));
    yield put(successNotificationAction('Relatório finalizado com sucesso'));
  } catch (e) {
    yield put(
      errorNotificationAction('Não foi possivel finalizar o relatório!')
    );
    yield put(reportsErrorAction(e));
  }
}

function* deleteReport({ data }) {
  yield put(reportsIsLoadingAction());
  try {
    yield deleteReportService({ id: data });

    yield put(successNotificationAction('Relatório excluído com sucesso'));
    yield call(history.push, ROUTER_PATHS.HOME);
  } catch (e) {
    yield put(errorNotificationAction('Não foi possivel excluir o relatório!'));
    yield put(reportsErrorAction(e));
  }
}

const locationSagas = [
  takeLatest(REPORTS_LOAD_GROUPS, loadGroups),
  takeLatest(REPORTS_NEW_REPORT_SUBMIT, saveReport),
  takeLatest(REPORTS_LOAD_REPORT, loadReport),
  takeLatest(REPORTS_UPDATE_REPORT_SUBMIT, updateReport),
  takeLatest(REPORTS_INDEX_OPEN_LOAD, loadOpenReports),
  takeLatest(
    REPORTS_REPORT_BY_VEGETATIVE_STAGE_LOAD,
    loadReportByVegetativeStage
  ),
  takeLatest(REPORTS_REPORT_BY_GROUP_LOAD, loadReportByGroup),
  takeLatest(REPORTS_INDEX_CLOSED_LOAD, loadClosedReports),
  takeLatest(REPORTS_CLOSE_REPORT, closeReport),
  takeLatest(REPORTS_DELETE_REPORT, deleteReport),
];

export default locationSagas;
