export const groupImagesPerTrap = (images) => {
  const imagesGrouped = images.reduce((imagesObject, image) => {
    const trapId = image.trap.value;
    imagesObject[trapId] = imagesObject[trapId] || {
      frontImageUrl: '',
      backImageUrl: '',
      captureDate: image.date,
      vegetativeStageId: image.vegetativeStage.value,
      trapName: image.trap.label,
    };

    if (image.side === 'FRONT') {
      imagesObject[trapId].frontImageUrl = image.url;
    } else {
      imagesObject[trapId].backImageUrl = image.url;
    }

    return imagesObject;
  }, {});

  return Object.keys(imagesGrouped).map((trapId) => ({
    ...imagesGrouped[trapId],
    trapId,
  }));
};

const splitIntoPages = (arr, pageSize) =>
  arr.length < pageSize
    ? [arr]
    : [
        arr.slice(0, pageSize),
        ...splitIntoPages(arr.slice(pageSize), pageSize),
      ];

export const groupImagesInPages = ({ filesArray, uploadUrls, pageSize }) => {
  const filesWithUploadUrl = filesArray.map((file, index) => ({
    file,
    uploadUrl: uploadUrls[index].uploadUrl,
  }));

  return splitIntoPages(filesWithUploadUrl, pageSize);
};

export default {
  groupImagesPerTrap,
};
