import { createSelector } from 'reselect';
import { isEmpty } from 'lodash';

export const imagesSelector = (state) => state.images;

export const imagesDataSelector = createSelector(
  imagesSelector,
  (images) => images.data
);

export const imagesIsLoadingSelector = createSelector(
  imagesSelector,
  (images) => images.isLoading
);

export const imagesHasLoadedSelector = createSelector(
  imagesSelector,
  (images) => images.hasLoaded
);

export const imagesErrorSelector = createSelector(
  imagesSelector,
  (images) => images.error
);

export const imagesHasErrorSelector = createSelector(
  imagesErrorSelector,
  (error) => !isEmpty(error)
);

export const trapWithImagesSelector = createSelector(
  imagesDataSelector,
  (data) => data.trapWithImages
);

export const trapWithImagesNameSelector = createSelector(
  trapWithImagesSelector,
  (trap) => (trap ? trap.name : '')
);

export const trapImagesSelector = createSelector(
  trapWithImagesSelector,
  (trap) => (trap ? trap.images : [])
);

export const selectedImageSelector = createSelector(
  imagesDataSelector,
  (data) => data.image
);
