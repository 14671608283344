import { createSelector } from 'reselect';
import { isEmpty } from 'lodash';

export const reportsSelector = (state) => state.reports;

export const reportsDataSelector = createSelector(
  reportsSelector,
  (reports) => reports.data
);

export const reportsIsLoadingSelector = createSelector(
  reportsSelector,
  (reports) => reports.isLoading
);

export const reportsHasLoadedSelector = createSelector(
  reportsSelector,
  (reports) => reports.hasLoaded
);

export const reportsErrorSelector = createSelector(
  reportsSelector,
  (reports) => reports.error
);

export const reportsHasErrorSelector = createSelector(
  reportsErrorSelector,
  (error) => !isEmpty(error)
);

export const groupsSelector = createSelector(
  reportsDataSelector,
  (data) => data.groups || []
);

export const selectedReportSelector = createSelector(
  reportsDataSelector,
  (data) => data.selectedReport || { name: '', startDate: new Date().toJSON() }
);

export const groupIsCheckedSelector = (groupId) =>
  createSelector(
    selectedReportSelector,
    (report) => report && !!report.groups.find((group) => group.id === groupId)
  );

export const reportsListSelector = createSelector(
  reportsDataSelector,
  (data) => data.reportsList || []
);

export const selectedReportDetailsSelector = createSelector(
  selectedReportSelector,
  (report) => report.details || []
);
