import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ROUTER_PATH from 'constants/router';

import Button from '@material-ui/core/Button';
import Topbar from 'components/layout/topbar';

import {
  newReportInitAction,
  loadGroupsAction,
  reportsCleanUpAction,
  newReportSubmitAction,
} from 'store/modules/reports/actions';

import GroupsList from './components/GroupsList';
import DataCard from './components/DataCard';

import { useStyles } from './styles';

const NewReport = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(newReportInitAction());
    dispatch(loadGroupsAction());
    return () => {
      dispatch(reportsCleanUpAction());
    };
  }, [dispatch]);

  const saveClickHandler = () => {
    dispatch(newReportSubmitAction());
  };

  return (
    <div>
      <Topbar
        title="Criar Relatório"
        subtitle="Selecione os grupos que serão reportados"
      />
      <div className={classes.container}>
        <DataCard />
        <GroupsList />
        <div className={classes.buttonGroup}>
          <Button
            color="primary"
            className={classes.cancelBtn}
            onClick={() => history.push(ROUTER_PATH.HOME)}
          >
            CANCELAR
          </Button>
          <Button
            color="primary"
            variant="contained"
            className={classes.submitBtn}
            onClick={saveClickHandler}
          >
            SALVAR
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NewReport;
