import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  navContainer: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      width: '100%',
    },
    justifyContent: 'space-between',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  addButton: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.primary.main,
    minWidth: '180px',
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(2),
      width: '45%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
  },
  listContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  listItem: {
    margin: theme.spacing(3),
    minWidth: '305px',
    [theme.breakpoints.up('xs')]: {
      maxWidth: '305px',
    },
    minHeight: '200px',
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  cardTitleContainer: {
    marginBottom: theme.spacing(1),
    fontWeight: theme.typography.fontWeightBold,
    display: 'flex',
    justifyContent: 'space-between',
  },
  cardTitleText: {
    maxWidth: '200px',
    fontSize: '1.4rem',
  },
  cardtitleIcon: {
    width: '20px',
    minWidth: '20px',
    margin: `${theme.spacing(0)}px ${theme.spacing(0.5)}px`,
  },
  cardTrapLine: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    borderBottom: `1px solid #BCBCBC`,
  },
  seeMoreBTN: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.primary.main,
    display: 'block',
    width: '100%',
  },
  cardLineIndex: {
    color: theme.palette.secondary.light,
    marginRight: theme.spacing(2),
  },
  container: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      width: '100%',
    },
    justifyContent: 'center',
    padding: theme.spacing(3),
  },
  content: {
    marginRight: theme.spacing(4),
    width: '35%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  paperPadding: {
    padding: theme.spacing(2),
  },
  trapListItem: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: `1px solid #BCBCBC`,
  },
  trapListTitle: {
    marginLeft: theme.spacing(2),
  },
  input: {
    width: '90%',
    margin: theme.spacing(2),
  },
  dragIcon: {
    marginRight: theme.spacing(2),
  },
  cardButtons: {
    marginTop: theme.spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
  },
  cardButton: {
    [theme.breakpoints.up('md')]: {
      minWidth: '180px',
    },
  },
  trapsPaper: {
    padding: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(4),
    },
  },
  trapsListContainer: {
    maxHeight: '350px',
    overflowY: 'auto',
    [theme.breakpoints.up('xl')]: {
      maxHeight: '600px',
    },
  },
}));
