import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  queueScanCleanUpAction,
  loadVegetativeStagesAction,
  loadTrapsAction,
} from 'store/modules/queue-scan/actions';
import { hasTrapsSelector } from 'store/modules/queue-scan/selectors';

import Topbar from 'components/layout/topbar';
import { useStyles } from './styles';
import TrapCard from './components/TrapCard';
import ImageBox from './components/ImageBox';
import NextPrevButtons from './components/NextPrevButtons';
import SubmitButton from './components/SubmitButton';
import ConfirmChangeTrapModal from './components/ConfirmChangeTrapModal';
import ConfirmSubmitModal from './components/ConfirmSubmitModal';

const QueueScan = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { groupId } = useParams();
  const hasTraps = useSelector(hasTrapsSelector);

  useEffect(() => {
    if (!hasTraps) {
      dispatch(loadTrapsAction(groupId));
    }
  }, [dispatch, groupId, hasTraps]);

  useEffect(() => {
    dispatch(loadVegetativeStagesAction());
    return () => {
      dispatch(queueScanCleanUpAction());
    };
  }, [dispatch]);

  return (
    <div>
      <Topbar title="Leitura Sequencial" subtitle="Envio das imagens" />
      <div className={classes.container}>
        <TrapCard />
        <ImageBox />
        <NextPrevButtons />
        <SubmitButton />
      </div>

      <ConfirmChangeTrapModal />
      <ConfirmSubmitModal />
    </div>
  );
};

export default QueueScan;
