import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import whiteLogo from 'assets/images/logo.svg';
import greenLogo from 'assets/images/logo-green.svg';

const Logo = ({ classes, color }) => {
  return (
    <Typography variant="h1" component="h2" className={classes} align="center">
      <img
        src={color === 'green' ? greenLogo : whiteLogo}
        alt="logo-agrosmart"
      />
    </Typography>
  );
};

Logo.defaultProps = {
  classes: '',
  color: 'green',
};

Logo.propTypes = {
  classes: PropTypes.string,
  color: PropTypes.string,
};

export default Logo;
