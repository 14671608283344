import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import ChangeIcon from 'assets/icons/Change.svg';

import { useStyles } from '../../styles';

const ImageBox = ({ url, onChange }) => {
  const classes = useStyles();
  const filesRef = useRef(null);

  const changeHandler = (e) => {
    if (e.target.files) {
      onChange(e.target.files[0]);
    }
    filesRef.current.value = '';
  };

  const changeClickHandler = () => {
    filesRef.current.click();
  };

  return (
    <div className={classes.imageBox}>
      <Paper className={classes.imageChangePaper} onClick={changeClickHandler}>
        <Typography variant="subtitle2" className={classes.changeText}>
          Clique aqui para alterar a imagem
        </Typography>
        <img src={ChangeIcon} alt="change" />
      </Paper>
      <img src={url} alt="armadilha" className={classes.image} />
      <input
        ref={filesRef}
        hidden
        type="file"
        onChange={changeHandler}
        name="images"
        accept="image/*"
      />
    </div>
  );
};

ImageBox.propTypes = {
  url: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ImageBox;
