import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(3),
  },
  title: {
    textAlign: 'center',
  },
  btn: {
    marginTop: theme.spacing(2),
  },
}));
