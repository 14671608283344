import React from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';

const HideableButton = ({ show, className, onClick, children }) => {
  return show ? (
    <Button
      color="primary"
      variant="contained"
      className={className}
      onClick={onClick}
    >
      {children}
    </Button>
  ) : null;
};

HideableButton.defaultProps = {
  onClick: () => {},
  children: '',
};

HideableButton.propTypes = {
  show: PropTypes.bool.isRequired,
  children: PropTypes.node,
  className: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

export default HideableButton;
