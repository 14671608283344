import React from 'react';
import { useSelector } from 'react-redux';

import { reportsListSelector } from 'store/modules/reports/selectors';

import ReportCard from '../reportCard';
import { useStyles } from '../../styles';

const ReportsList = () => {
  const classes = useStyles();
  const reportsList = useSelector(reportsListSelector);

  return (
    <div className={classes.cardsContainer}>
      {reportsList.map((report) => (
        <ReportCard key={report.id} report={report} />
      ))}
    </div>
  );
};

export default ReportsList;
