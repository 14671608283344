import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ptBrLocal from 'date-fns/locale/pt-BR';

import Select from 'components/SelectWithSearch';
import DeleteIcon from 'assets/icons/Delete.svg';

import {
  trapsSelector,
  ifFrontSideSelector,
  imageSelector,
  vegetativeStagesSelector,
} from 'store/modules/single-scan/selectors';
import {
  updateImageAction,
  deleteImageAction,
} from 'store/modules/single-scan/actions';

import { useStyles } from '../../styles';

const Card = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const options = useSelector(trapsSelector);
  const vegetativeStages = useSelector(vegetativeStagesSelector);
  const frontSide = useSelector(ifFrontSideSelector);
  const image = useSelector(imageSelector) || {};
  const side = frontSide ? 'frente' : 'verso';

  const dispatchUpdateImage = (updated) => {
    dispatch(updateImageAction(updated));
  };

  const trapSelectChangeHandler = (trap) => {
    const updated = {
      ...image,
      trap,
    };
    dispatchUpdateImage(updated);
  };

  const vegetativeStageSelectChangeHandler = (vegetativeStage) => {
    const updated = {
      ...image,
      vegetativeStage,
    };
    dispatchUpdateImage(updated);
  };
  const dateChangeHandler = (newDate) => {
    const updated = {
      ...image,
      captureDate: newDate.toJSON(),
    };
    dispatchUpdateImage(updated);
  };

  const deleteClickHandler = () => {
    dispatch(deleteImageAction());
  };

  return (
    <Paper elevation={5} className={classes.dataCard}>
      <div className={classes.row}>
        <Select
          placeholder="Armadilha"
          className={classes.trapSelect}
          options={options}
          handleChange={trapSelectChangeHandler}
          selected={image.trap}
        />
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBrLocal}>
          <KeyboardDatePicker
            label="Data da imagen"
            value={image.captureDate}
            onChange={dateChangeHandler}
            animateYearScrolling
            format="dd/MM/yyyy"
            fullWidth
            className={classes.cardDate}
            size="small"
          />
        </MuiPickersUtilsProvider>
      </div>
      <div className={classes.row}>
        <Select
          placeholder="Estágio vegetativo"
          className={classes.vegetativeStagesSelect}
          options={vegetativeStages}
          handleChange={vegetativeStageSelectChangeHandler}
          selected={image.vegetativeStage}
        />
      </div>
      <div className={classes.row}>
        <Typography
          display="block"
          color="secondary"
          variant="subtitle1"
          className={classes.cardText}
        >
          Tire a foto da <span className={classes.bold}>{side}</span> da
          armadilha
        </Typography>
        <Button className={classes.deleteButton} onClick={deleteClickHandler}>
          <img src={DeleteIcon} alt="Delete" />
        </Button>
      </div>
    </Paper>
  );
};

export default Card;
