import { createSelector } from 'reselect';
import { isEmpty } from 'lodash';

export const trapsSelector = (state) => state.traps;

export const trapsDataSelector = createSelector(
  trapsSelector,
  (traps) => traps.data
);

export const trapsListSelector = createSelector(
  trapsDataSelector,
  (data) => data.traps
);

export const trapsIsLoadingSelector = createSelector(
  trapsSelector,
  (traps) => traps.isLoading
);

export const trapsHasLoadedSelector = createSelector(
  trapsSelector,
  (traps) => traps.hasLoaded
);

export const trapsErrorSelector = createSelector(
  trapsSelector,
  (traps) => traps.error
);

export const trapsHasErrorSelector = createSelector(
  trapsErrorSelector,
  (error) => !isEmpty(error)
);

export const hasTrapToEditSelector = createSelector(
  trapsSelector,
  (traps) => traps.hasTrapToEdit
);

export const selectedTrapSelector = createSelector(
  trapsSelector,
  (traps) => traps.data.selectedTrap
);

export const filteredTrapsSelector = createSelector(
  trapsDataSelector,
  (data) => data.filteredTraps
);

export const hasFilteredTrapsSelector = createSelector(
  filteredTrapsSelector,
  (traps) => !isEmpty(traps)
);
