import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Topbar from 'components/layout/topbar';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import {
  singleScanInitAction,
  singleScanCleanUpAction,
  loadTrapsAction,
  submitImageAction,
  loadVegetativeStagesAction,
} from 'store/modules/single-scan/actions';
import { currentSideHasImageSelector } from 'store/modules/single-scan/selectors';

import { useStyles } from './styles';
import Card from './components/card';
import ImageContainer from './components/imageContainer';
import UploadCard from './components/uploadCard';
import NavButtons from './components/navButtons';

const SingleScan = () => {
  const { defaultDate } = useParams();
  const dispatch = useDispatch();
  const classes = useStyles();
  const hasImage = useSelector(currentSideHasImageSelector);

  useEffect(() => {
    dispatch(singleScanInitAction(defaultDate));
    dispatch(loadTrapsAction());
    dispatch(loadVegetativeStagesAction());
    return () => {
      dispatch(singleScanCleanUpAction());
    };
  }, [dispatch, defaultDate]);

  const submitClickHandler = () => {
    dispatch(submitImageAction());
  };

  const renderImageBox = () => (hasImage ? <ImageContainer /> : <UploadCard />);

  return (
    <div>
      <Topbar title="Enviar imagem individual" subtitle=" " />
      <div className={classes.container}>
        <Card />
        {renderImageBox()}
        <NavButtons />
        <div>
          <Typography display="block" color="textSecondary" variant="subtitle2">
            Envie todas as imagens antes de finalizar
          </Typography>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            className={classes.submitBtn}
            onClick={submitClickHandler}
          >
            Finalizar envio das imagens
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SingleScan;
