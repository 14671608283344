import {
  NOTIFICATIONS_ERROR,
  NOTIFICATIONS_SUCCESS,
  NOTIFICATIONS_ALERT,
} from 'store/types';

export const errorNotificationAction = (message) => ({
  type: NOTIFICATIONS_ERROR,
  data: message,
});

export const successNotificationAction = (message) => ({
  type: NOTIFICATIONS_SUCCESS,
  data: message,
});

export const alertNotificationAction = (message) => ({
  type: NOTIFICATIONS_ALERT,
  data: message,
});
