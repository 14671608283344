import React from 'react';
import ROUTER_PATHS from 'constants/router';
import { Switch } from 'react-router-dom';
import LotScanInit from 'pages/LotScan/Init';
import ImagesLot from 'pages/LotScan/ImagesLot';
import SingleScan from 'pages/SingleScan';
import Home from 'pages/Home';
import ClosedReports from 'pages/Home/ClosedReports';
import Login from 'pages/Login';
import Groups from 'pages/Groups';
import NewGroup from 'pages/Groups/NewGroup';
import EditGroup from 'pages/Groups/EditGroup';
import Traps from 'pages/Traps';
import Images from 'pages/Images';
import ImageDetails from 'pages/Images/single';
import NewReport from 'pages/Reports/NewReport';
import EditReport from 'pages/Reports/EditReport';
import Report from 'pages/Reports/Report';
import QueueScanInit from 'pages/QueueScan/QueueScanInit';
import QueueScan from 'pages/QueueScan';

import Route from './Route';

const Routes = () => (
  <Switch>
    <Route
      path={`${ROUTER_PATHS.QUEUE_SCAN_IMAGES}/:groupId?`}
      component={QueueScan}
      isPrivate
    />

    <Route
      path={`${ROUTER_PATHS.QUEUE_SCAN_INIT}/:defaultDate`}
      component={QueueScanInit}
      isPrivate
    />

    <Route
      path={ROUTER_PATHS.CLOSED_REPORTS}
      component={ClosedReports}
      isPrivate
    />

    <Route
      path={`${ROUTER_PATHS.REPORT}/:reportId`}
      component={Report}
      isPrivate
    />
    <Route path={ROUTER_PATHS.NEW_REPORT} component={NewReport} isPrivate />
    <Route
      path={`${ROUTER_PATHS.EDIT_REPORT}/:reportId`}
      component={EditReport}
      isPrivate
    />

    <Route
      path={`${ROUTER_PATHS.SINGE_SCAN}/:defaultDate?`}
      component={SingleScan}
      isPrivate
    />
    <Route
      path={`${ROUTER_PATHS.LOT_SCAN_IMAGES}/:groupId?`}
      component={ImagesLot}
      isPrivate
    />
    <Route
      path={`${ROUTER_PATHS.LOT_SCAN_INIT}/:defaultDate`}
      component={LotScanInit}
      isPrivate
    />

    <Route exact path={ROUTER_PATHS.HOME} component={Home} isPrivate />
    <Route
      path={`${ROUTER_PATHS.TRAP_IMAGE_DETAILS}/:id`}
      component={ImageDetails}
      isPrivate
    />
    <Route
      path={`${ROUTER_PATHS.TRAP_IMAGES}/:trapId`}
      component={Images}
      isPrivate
    />
    <Route exact path={ROUTER_PATHS.TRAPS} component={Traps} isPrivate />
    <Route exact path={ROUTER_PATHS.GROUPS} component={Groups} isPrivate />
    <Route exact path={ROUTER_PATHS.NEW_GROUP} component={NewGroup} isPrivate />
    <Route
      path={`${ROUTER_PATHS.EDIT_GROUP}/:id`}
      component={EditGroup}
      isPrivate
    />
    <Route exact path={ROUTER_PATHS.LOGIN} component={Login} />
  </Switch>
);

export default Routes;
