export const trapsToImagesQueue = ({ traps, defaultDate }) =>
  traps.map((trap) => ({
    trap: {
      label: trap.name,
      value: trap.id,
    },
    vegetativeStage: '',
    frontImageUrl: '',
    backImageUrl: '',
    captureDate: defaultDate,
  }));

export const formatImages = (images) =>
  images.map((image) => ({
    trapId: image.trap.value,
    vegetativeStageId: image.vegetativeStage.value,
    frontImageUrl: image.frontImageUrl,
    backImageUrl: image.backImageUrl,
    captureDate: image.captureDate,
  }));
