import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import ROUTER_PATH from 'constants/router';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import DeleteIcon from 'assets/icons/Delete.svg';
import EditIcon from 'assets/icons/Edit.svg';

import { useStyles } from '../../../styles';

const GroupCard = ({ group }) => {
  const classes = useStyles();
  const history = useHistory();

  const editClickHandler = () => {
    history.push(`${ROUTER_PATH.EDIT_GROUP}/${group.id}`);
  };

  const renderTraps = (amount, traps) => {
    const trapsToShow = traps.slice(0, amount);
    return trapsToShow.map(({ id, trap }, index) => (
      <div className={classes.cardTrapLine} key={id}>
        <Typography variant="subtitle1" className={classes.cardLineIndex}>
          {index + 1}ª
        </Typography>

        <Typography variant="subtitle1">{trap.name}</Typography>
      </div>
    ));
  };

  return (
    <Paper className={classes.listItem}>
      <div className={classes.cardTitleContainer}>
        <Typography variant="h4" className={classes.cardTitleText}>
          {group.name}
        </Typography>
        <div>
          <Button
            color="primary"
            className={classes.cardtitleIcon}
            onClick={editClickHandler}
          >
            <img src={EditIcon} alt="edit group" />
          </Button>
          <Button color="primary" className={classes.cardtitleIcon}>
            <img src={DeleteIcon} alt="delete group" />
          </Button>
        </div>
      </div>
      <div>
        {renderTraps(3, group.traps)}
        <Button
          color="primary"
          className={classes.seeMoreBTN}
          onClick={editClickHandler}
        >
          VER MAIS
        </Button>
      </div>
    </Paper>
  );
};

GroupCard.propTypes = {
  group: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    traps: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        trap: PropTypes.shape({
          name: PropTypes.string.isRequired,
        }),
      })
    ),
  }).isRequired,
};

export default GroupCard;
