import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  vegetativeStagesSelector,
  trapsSelector,
  selectedTrapSelector,
  ifFrontSideSelector,
} from 'store/modules/queue-scan/selectors';
import {
  changeTrapAction,
  deleteImageAction,
  updateCurrentTrapAction,
} from 'store/modules/queue-scan/actions';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import Select from 'components/SelectWithSearch';
import DateInput from 'components/DateInput';
import DeleteIcon from 'assets/icons/Delete.svg';

import { useStyles } from '../../styles';

const TrapCard = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const trapsOptions = useSelector(trapsSelector) || [];
  const vegetativeStagesOptions = useSelector(vegetativeStagesSelector) || [];
  const selectedTrap = useSelector(selectedTrapSelector) || {};
  const frontSide = useSelector(ifFrontSideSelector);

  const side = frontSide ? 'frente' : 'verso';

  const trapSelectChangeHandler = (trap) => {
    dispatch(changeTrapAction(trap));
  };

  const vegetativeStageSelectChangeHandler = (vegetativeStage) => {
    const updated = {
      ...selectedTrap,
      vegetativeStage,
    };
    dispatch(updateCurrentTrapAction(updated));
  };
  const dateChangeHandler = (newDate) => {
    const updated = {
      ...selectedTrap,
      captureDate: newDate.toJSON(),
    };
    dispatch(updateCurrentTrapAction(updated));
  };

  const deleteClickHandler = () => {
    dispatch(deleteImageAction());
  };

  return (
    <Paper elevation={5} className={classes.dataCard}>
      <div className={classes.row}>
        <Select
          placeholder="Armadilha"
          className={classes.trapSelect}
          options={trapsOptions}
          handleChange={trapSelectChangeHandler}
          selected={selectedTrap.trap}
          isClearable={false}
        />
        <DateInput
          label="Data de início"
          className={classes.cardDate}
          date={selectedTrap.captureDate}
          onChange={dateChangeHandler}
        />
      </div>
      <div className={classes.row}>
        <Select
          placeholder="Estágio vegetativo"
          className={classes.vegetativeStagesSelect}
          options={vegetativeStagesOptions}
          handleChange={vegetativeStageSelectChangeHandler}
          selected={selectedTrap.vegetativeStage}
        />
      </div>
      <div className={classes.row}>
        <Typography
          display="block"
          color="secondary"
          variant="subtitle1"
          className={classes.cardText}
        >
          Tire a foto da <span className={classes.bold}>{side}</span> da
          armadilha
        </Typography>
        <Button className={classes.deleteButton} onClick={deleteClickHandler}>
          <img src={DeleteIcon} alt="Delete" />
        </Button>
      </div>
    </Paper>
  );
};

export default TrapCard;
