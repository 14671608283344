import axios from '../utils/axios';

export const indexTraps = async () => {
  try {
    const { data } = await axios.get('/traps');
    return data;
  } catch (e) {
    if (e.response) {
      throw e.response.data;
    }
    const error = {
      message: 'Ocorreu um erro por favor tente novamente!',
    };
    throw error;
  }
};

export const storeTrap = async (trap) => {
  try {
    const { data } = await axios.post('/traps', trap);
    return data;
  } catch (e) {
    if (e.response) {
      throw e.response.data;
    }
    const error = {
      message: 'Ocorreu um erro por favor tente novamente!',
    };
    throw error;
  }
};

export const putTrap = async (trap) => {
  try {
    const { data } = await axios.put('/traps', trap);
    return data;
  } catch (e) {
    if (e.response) {
      throw e.response.data;
    }
    const error = {
      message: 'Ocorreu um erro por favor tente novamente!',
    };
    throw error;
  }
};

export const deleteTrapService = async (id) => {
  try {
    const { data } = await axios.delete(`/traps/${id}`);
    return data;
  } catch (e) {
    if (e.response) {
      throw e.response.data;
    }
    const error = {
      message: 'Ocorreu um erro por favor tente novamente!',
    };
    throw error;
  }
};
