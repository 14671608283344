import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { changeTrapErrorSelector } from 'store/modules/queue-scan/selectors';
import {
  changeTrapClearCurrentAction,
  clearTrapMissingDataErrorAction,
} from 'store/modules/queue-scan/actions';

import { useStyles } from '../../styles';

const ConfirmChangeTrapModal = () => {
  const classes = useStyles();
  const changeTrapError = useSelector(changeTrapErrorSelector);
  const dispatch = useDispatch();

  const closeHandler = () => {
    dispatch(clearTrapMissingDataErrorAction());
  };
  const confirmHandler = () => {
    dispatch(changeTrapClearCurrentAction());
  };

  return (
    <Dialog open={!!changeTrapError} openonClose={closeHandler} maxWidth="xs">
      <DialogContent>
        <Typography variant="body1" align="left">
          É necessário que seja inserido as imagens de
          <span className={classes.bold}> frente e verso</span> de cada
          armadilha e o estágio vegetativo.
          <br />
          Caso contrário será desconsiderada para leitura.
          <br />
          Deseja continuar mesmo assim?
        </Typography>

        <div className={classes.dialogButtonsContainer}>
          <Button
            className={classes.dialogButton}
            onClick={closeHandler}
            color="primary"
            variant="text"
          >
            NÃO
          </Button>
          <Button
            className={classes.dialogButton}
            onClick={confirmHandler}
            variant="contained"
            color="primary"
          >
            SIM
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmChangeTrapModal;
