import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  trapsListSelector,
  hasFilteredTrapsSelector,
  filteredTrapsSelector,
} from 'store/modules/traps/selectors';
import { selectedGroupTrapsSelector } from 'store/modules/groups/selectors';
import {
  addTrapToSelectedGroupTraps,
  removeTrapFromSelectedGroupTraps,
} from 'store/modules/groups/actions';

import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';

import { useStyles } from '../../styles';

const Traps = () => {
  const classes = useStyles();
  const traps = useSelector(trapsListSelector) || [];
  const hasFilteredTraps = useSelector(hasFilteredTrapsSelector);
  const filteredTraps = useSelector(filteredTrapsSelector) || [];
  const selectedGroupTraps = useSelector(selectedGroupTrapsSelector);

  const dispatch = useDispatch();

  const checkIfSelected = (id) => {
    const selected = selectedGroupTraps.find((trap) => trap.id === id);
    return !!selected;
  };

  const toggleTrapCheckbox = (trap, selected) => {
    if (selected) {
      return dispatch(removeTrapFromSelectedGroupTraps(trap));
    }
    return dispatch(addTrapToSelectedGroupTraps(trap));
  };

  const renderTrap = (trap) => {
    const selected = checkIfSelected(trap.id);
    return (
      <div className={classes.trapListItem} key={trap.id}>
        <Typography variant="subtitle1" className={classes.trapListTitle}>
          {trap.name}
        </Typography>
        <Checkbox
          color="primary"
          checked={selected}
          onClick={() => toggleTrapCheckbox(trap, selected)}
        />
      </div>
    );
  };

  return hasFilteredTraps
    ? filteredTraps.map(renderTrap)
    : traps.map(renderTrap);
};

export default Traps;
