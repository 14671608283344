import React from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { useStyles } from './styles';

const NavButton = ({ title, subTitle, variant, onClick }) => {
  const classes = useStyles();
  return (
    <div>
      <Button
        fullWidth
        variant={variant}
        className={classes.btn}
        color="primary"
        onClick={onClick}
      >
        <div>
          <Typography
            display="block"
            align="center"
            variant="h6"
            component="h2"
            autoCapitalize
          >
            {title}
          </Typography>
          <Typography variant="caption" align="center">
            {subTitle}
          </Typography>
        </div>
      </Button>
    </div>
  );
};

NavButton.defaultProps = {
  variant: 'outlined',
};

NavButton.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  variant: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export default NavButton;
