import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    maxWidth: '400px',
    margin: '0 auto',
  },
  input: {
    marginBottom: theme.spacing(2),
  },
  groupsPaper: {
    padding: theme.spacing(2),
    maxHeight: '500px',
    overflowY: 'auto',
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(2),
  },
  groupContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  groupText: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: `0 ${theme.spacing(2)}px`,
    marginTop: theme.spacing(2),
  },
  cancelBtn: {
    minWidth: 130,
  },
  submitBtn: {
    minWidth: 130,
    color: theme.palette.white.main,
  },
  deleteBtn: {
    width: '90%',
    marginLeft: '5%',
    marginRight: '5%',
    color: theme.palette.error.main,
    border: `solid 1px ${theme.palette.error.main}`,
  },
  reportHeaderContainer: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      width: '100%',
    },
    justifyContent: 'space-between',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  reportHeaderBtn: {
    fontWeight: theme.typography.fontWeightBold,
    minWidth: '180px',
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(2),
      width: '45%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
  },
  formControl: {
    minWidth: '180px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
  },
  groupBySelect: {
    height: '35px',
  },
  contentContainer: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  detailsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
    flex: 1,
  },
  detailsCard: {
    minWidth: '170px',
    maxWidth: '364',
    minHeight: '136px',
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    marginLeft: theme.spacing(3),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      width: '90%',
      margin: '0 auto',
      marginBottom: theme.spacing(3),
    },
    display: 'flex',
    flexDirection: 'column',
  },
  summaryPaper: {
    width: 197,
    height: 197,
    marginLeft: theme.spacing(5),
    marginRight: theme.spacing(3),
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      margin: '0 auto',
      marginBottom: theme.spacing(3),
    },
  },
  summaryTitle: {
    fontSize: '0.90rem',
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  summaryDate: {
    fontSize: '0.75rem',
    color: theme.palette.secondary.light,
    marginBottom: theme.spacing(1),
  },
  summaryTextBold: {
    fontSize: '0.75rem',
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(1),
  },
  summaryText: {
    fontSize: '0.75rem',
    marginBottom: theme.spacing(3),
  },
  detailsItemsContainer: {
    flex: 1,
  },
  detailsItem: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  detailsSum: {
    marginTop: theme.spacing(1),
    fontWeight: theme.typography.fontWeightBold,
  },
  closeModalTitle: {
    marginBottom: theme.spacing(1),
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '1.5rem',
  },
  closeModalButtonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  closeModalCardButton: {
    width: '45%',
  },
  closeModalDateInput: {
    marginBottom: theme.spacing(3),
  },
}));
