import { createSelector } from 'reselect';
import { isEmpty } from 'lodash';

export const singleScanSelector = (state) => state.singleScan;

export const singleScanDataSelector = createSelector(
  singleScanSelector,
  (singleScan) => singleScan.data
);

export const singleScanIsLoadingSelector = createSelector(
  singleScanSelector,
  (singleScan) => singleScan.isLoading
);

export const singleScanHasLoadedSelector = createSelector(
  singleScanSelector,
  (singleScan) => singleScan.hasLoaded
);

export const singleScanErrorSelector = createSelector(
  singleScanSelector,
  (singleScan) => singleScan.error
);

export const singleScanHasErrorSelector = createSelector(
  singleScanErrorSelector,
  (error) => !isEmpty(error)
);

export const trapsSelector = createSelector(
  singleScanDataSelector,
  (data) =>
    data.traps &&
    data.traps.map((trap) => ({
      value: trap.id,
      label: trap.name,
    }))
);

export const ifFrontSideSelector = createSelector(
  singleScanDataSelector,
  (data) => data.frontSide
);

export const imageSelector = createSelector(
  singleScanDataSelector,
  (data) => data.image
);

export const currentSideImageSelector = createSelector(
  singleScanDataSelector,
  (data) =>
    data.image &&
    (data.frontSide ? data.image.frontImageUrl : data.image.backImageUrl)
);

export const currentSideHasImageSelector = createSelector(
  currentSideImageSelector,
  (imageUrl) => !!imageUrl
);

export const vegetativeStagesSelector = createSelector(
  singleScanDataSelector,
  (data) =>
    data.vegetativeStages &&
    data.vegetativeStages.map((trap) => ({
      value: trap.id,
      label: trap.name,
    }))
);
