import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import Menu from 'assets/icons/Menu.svg';
import UserIcon from 'assets/icons/User.svg';
import itemsList from './itemsList';
import UserDropDown from './userDropDown';

import { useStyles } from './styles';

const Topbar = ({ title, subtitle }) => {
  const classes = useStyles();
  const userIconRef = useRef();

  const [open, setOpen] = useState(false);
  const [userDropDownOpen, setUserDropDownOpen] = useState(false);

  const closeDrawer = (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setOpen(false);
  };

  const closeUserDropDown = () => {
    setUserDropDownOpen(false);
  };

  const openUserDropDown = () => {
    setUserDropDownOpen(true);
  };

  return (
    <div>
      <AppBar position="static" color="transparent">
        <Toolbar className={classes.topBar}>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="secondary"
            aria-label="menu"
            onClick={() => setOpen(true)}
          >
            <img src={Menu} alt="Menu" />
          </IconButton>
          <Typography variant="h4" className={classes.title}>
            {title}
            <br />
            <Typography
              variant="subtitle1"
              component="p"
              className={classes.subtitle}
            >
              {subtitle}
            </Typography>
          </Typography>
          <div>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={openUserDropDown}
              color="inherit"
            >
              <img src={UserIcon} alt="user" ref={userIconRef} />
            </IconButton>
            <UserDropDown
              anchorEl={userIconRef}
              open={userDropDownOpen}
              close={closeUserDropDown}
            />
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="temporary"
        anchor="left"
        open={open}
        onClose={closeDrawer}
      >
        {itemsList({ closeDrawer })}
      </Drawer>
    </div>
  );
};

Topbar.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

export default Topbar;
