import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import ROUTER_PATH from 'constants/router';

import Topbar from 'components/layout/topbar';
import ScanGroupsSelect from 'components/ScanGroupsSelect';
import Button from '@material-ui/core/Button';

import { lotScanInitAction } from 'store/modules/lot-scan/actions';
import { groupsSelector } from 'store/modules/lot-scan/selectors';

import { useStyles } from './styles';

const LotScanInit = () => {
  const { defaultDate } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [selectedGroup, setSelectedGroup] = useState('');
  const groupsList = useSelector(groupsSelector) || [];

  useEffect(() => {
    dispatch(lotScanInitAction(defaultDate));
  }, [dispatch, defaultDate]);

  const clickHandler = () => {
    history.push(`${ROUTER_PATH.LOT_SCAN_IMAGES}/${selectedGroup}`);
  };

  return (
    <div>
      <Topbar
        title="Enviar imagens por lote"
        subtitle="Selecione qual grupo deseja utilizar"
      />
      <div className={classes.container}>
        <div>
          <ScanGroupsSelect
            selected={selectedGroup}
            setSelected={setSelectedGroup}
            groupsList={groupsList}
          />
          <Button
            fullWidth
            color="primary"
            variant="contained"
            className={classes.whiteBtn}
            size="large"
            onClick={clickHandler}
          >
            INSERIR IMAGENS
          </Button>
        </div>
      </div>
    </div>
  );
};

export default LotScanInit;
