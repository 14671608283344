import { takeLatest, put, select, call } from 'redux-saga/effects';
import ROUTER_PATHS from 'constants/router';
import history from 'services/history';
import { indexTraps } from 'services/traps';
import {
  SINGLE_SCAN_LOAD_TRAPS,
  SINGLE_SCAN_UPLOAD_IMAGE,
  SINGLE_SCAN_SUBMIT_IMAGE,
  SINGLE_SCAN_LOAD_VEGETATIVE_STAGES,
} from 'store/types';
import {
  getUploadUrl,
  storeImages,
  indexVegetativeStages,
} from 'services/images';
import { uploadImageService } from 'services/aws-s3';

import {
  errorNotificationAction,
  successNotificationAction,
} from 'store/modules/notifications/actions';

import {
  singleScanIsLoadingAction,
  singleScanErrorAction,
  loadTrapsSuccessAction,
  uploadImageSuccessAction,
  loadVegetativeStagesSuccessAction,
} from './actions';
import { imageSelector } from './selectors';
import { validateImageAttributes } from './validators';
import { formatImage } from './serializers';

function* loadTraps() {
  yield put(singleScanIsLoadingAction());

  try {
    const traps = yield indexTraps();
    yield put(loadTrapsSuccessAction(traps));
  } catch (error) {
    yield put(
      errorNotificationAction('Não foi possivel carregar as armadilhas!')
    );
    yield put(singleScanErrorAction(error.message));
  }
}

function* uploadImage({ data }) {
  yield put(singleScanIsLoadingAction());

  try {
    const file = data[0];

    const { uploadUrl } = yield getUploadUrl({
      fileName: file.name,
      fileType: file.type,
    });

    yield uploadImageService({ file, uploadUrl });
    const imageUrl = uploadUrl.split('?')[0];

    yield put(uploadImageSuccessAction(imageUrl));
  } catch (error) {
    yield put(errorNotificationAction('Não foi possivel enviar a imagem!'));
    yield put(singleScanErrorAction(error.message));
  }
}

function* sendImage() {
  yield put(singleScanIsLoadingAction());

  try {
    const image = yield select(imageSelector);

    validateImageAttributes(image);
    const formatedImage = formatImage(image);

    yield storeImages([formatedImage]);

    yield call(history.push, ROUTER_PATHS.HOME);
    yield put(
      successNotificationAction(
        'As imagens foram salvas e serão analisadas, o resultado ficará disponível em breve'
      )
    );
  } catch (error) {
    yield put(errorNotificationAction(error.message));
    yield put(singleScanErrorAction(error.message));
  }
}

function* loadVegetativeStages() {
  yield put(singleScanIsLoadingAction());

  try {
    const vegetativeStages = yield indexVegetativeStages();
    yield put(loadVegetativeStagesSuccessAction(vegetativeStages));
  } catch (error) {
    yield put(
      errorNotificationAction(
        'Não foi possivel carregar os estágios vegetativos!'
      )
    );
    yield put(singleScanErrorAction(error.message));
  }
}

const singleScanSagas = [
  takeLatest(SINGLE_SCAN_LOAD_TRAPS, loadTraps),
  takeLatest(SINGLE_SCAN_UPLOAD_IMAGE, uploadImage),
  takeLatest(SINGLE_SCAN_SUBMIT_IMAGE, sendImage),
  takeLatest(SINGLE_SCAN_LOAD_VEGETATIVE_STAGES, loadVegetativeStages),
];

export default singleScanSagas;
