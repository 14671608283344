import produce from 'immer';
import {
  TRAP_INDEX_IS_LOADING,
  TRAP_INDEX_SUCCESS,
  TRAP_INDEX_ERROR,
  TRAP_CLEANUP,
  TRAP_EDIT_SELECT,
  TRAP_EDIT_CLEAR_SELECTED,
  TRAP_ADD_SUCCESS,
  TRAP_ADD_ERROR,
  TRAPS_SEARCH_FILTER,
  TRAPS_REMOVE_SEARCH_FILTER,
  TRAP_UPDATE_SUCCESS,
  TRAP_UPDATE_ERROR,
  TRAP_DELETE_SUCCESS,
  TRAP_DELETE_ERROR,
} from 'store/types';

export const initialState = {
  isLoading: false,
  hasLoaded: false,
  hasTrapToEdit: false,
  error: {},
  data: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TRAP_DELETE_ERROR:
      return produce(state, (draft) => {
        draft.isLoading = false;
        draft.hasLoaded = true;
        draft.error = action.data;
      });

    case TRAP_DELETE_SUCCESS:
      return produce(state, (draft) => {
        draft.isLoading = false;
        draft.hasLoaded = true;
        draft.data.traps = draft.data.traps.filter(
          (trap) => trap.id !== action.data
        );
      });

    case TRAP_UPDATE_ERROR:
      return produce(state, (draft) => {
        draft.isLoading = false;
        draft.hasLoaded = true;
        draft.error = action.data;
      });

    case TRAP_UPDATE_SUCCESS:
      return produce(state, (draft) => {
        draft.isLoading = false;
        draft.hasLoaded = true;
        draft.data.traps = draft.data.traps.map((trap) =>
          trap.id === action.data.id ? action.data : trap
        );
      });

    case TRAPS_SEARCH_FILTER:
      return produce(state, (draft) => {
        draft.data.filteredTraps = draft.data.traps.filter((trap) =>
          trap.name.toLowerCase().includes(action.data.toLowerCase())
        );
      });

    case TRAPS_REMOVE_SEARCH_FILTER:
      return produce(state, (draft) => {
        draft.data.filteredTraps = [];
      });

    case TRAP_ADD_SUCCESS:
      return produce(state, (draft) => {
        draft.isLoading = false;
        draft.hasLoaded = true;
        draft.data.traps = [...state.data.traps, action.data];
      });

    case TRAP_ADD_ERROR:
      return produce(state, (draft) => {
        draft.isLoading = false;
        draft.hasLoaded = true;
        draft.error = action.data;
      });

    case TRAP_EDIT_SELECT:
      return produce(state, (draft) => {
        draft.hasTrapToEdit = true;
        draft.data.selectedTrap = draft.data.traps.find(
          (trap) => trap.id === action.data
        );
      });

    case TRAP_EDIT_CLEAR_SELECTED:
      return produce(state, (draft) => {
        draft.hasTrapToEdit = false;
        draft.data.selectedTrap = null;
      });

    case TRAP_INDEX_SUCCESS:
      return produce(state, (draft) => {
        draft.isLoading = false;
        draft.hasLoaded = true;
        draft.data.traps = action.data;
      });

    case TRAP_INDEX_IS_LOADING:
      return produce(state, (draft) => {
        draft.isLoading = true;
        draft.hasLoaded = false;
        draft.error = {};
      });

    case TRAP_INDEX_ERROR:
      return produce(state, (draft) => {
        draft.isLoading = false;
        draft.hasLoaded = true;
        draft.error = action.error;
      });
    case TRAP_CLEANUP:
      return initialState;

    default:
      return state;
  }
};
