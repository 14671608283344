import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import PropTypes from 'prop-types';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import DownArrow from 'assets/icons/DownArrowGreen.svg';
import Scan from 'assets/icons/ScanGreen.svg';

import ROUTER_PATH from 'constants/router';

import Actions from '../actionsDropdown';
import { useStyles } from '../../../styles';

const TrapListItem = ({ trap }) => {
  const classes = useStyles();
  const history = useHistory();
  const lastUpdate = trap.lastUpdate
    ? moment(trap.lastUpdate).format('DD/MM/YYYY')
    : '--';

  const [actionsOpen, setActionsOpen] = useState(false);

  const actionsBtnRef = useRef(null);

  const actionsBtnCloseHandler = () => {
    setActionsOpen(false);
  };

  const actionsBtnClickHandler = () => {
    setActionsOpen(true);
  };

  return (
    <Paper className={classes.listItem}>
      <Typography variant="h4" align="center" className={classes.cardTitle}>
        {trap.name}
      </Typography>
      <Typography
        variant="subtitle1"
        align="left"
        className={classes.cardContent}
      >
        Latitude: {trap.latitude}
      </Typography>
      <Typography
        variant="subtitle1"
        align="left"
        className={classes.cardContent}
      >
        Longitude: {trap.longitude}
      </Typography>
      <Typography
        variant="subtitle1"
        align="left"
        className={classes.cardContent}
      >
        Quantidade de imagens: {trap.imagesCount}
      </Typography>
      <Typography
        variant="subtitle1"
        align="left"
        className={classes.cardContent}
      >
        Última atualização: {lastUpdate}
      </Typography>
      <div className={classes.cardButtons}>
        <Button
          color="primary"
          onClick={() => history.push(`${ROUTER_PATH.TRAP_IMAGES}/${trap.id}`)}
        >
          <img src={Scan} alt="leituras" className={classes.buttonIcon} />{' '}
          LEITURAS
        </Button>
        <Button
          color="primary"
          onClick={actionsBtnClickHandler}
          ref={actionsBtnRef}
        >
          <img src={DownArrow} alt="actions" className={classes.buttonIcon} />{' '}
          AÇÕES
        </Button>
        <Actions
          anchorEl={actionsBtnRef.current}
          open={actionsOpen}
          handleClose={actionsBtnCloseHandler}
          trapId={trap.id}
        />
      </div>
    </Paper>
  );
};

TrapListItem.propTypes = {
  trap: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    latitude: PropTypes.number.isRequired,
    longitude: PropTypes.number.isRequired,
    lastUpdate: PropTypes.string.isRequired,
    imagesCount: PropTypes.string.isRequired,
  }).isRequired,
};

export default TrapListItem;
