import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Topbar from 'components/layout/topbar';

import {
  imageIndexLoadAction,
  imageCleanUpAction,
} from 'store/modules/images/actions';
import { trapWithImagesNameSelector } from 'store/modules/images/selectors';

import ImagesList from './components/imagesList';

const Images = () => {
  const { trapId } = useParams();
  const trapName = useSelector(trapWithImagesNameSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(imageIndexLoadAction(trapId));
    return () => {
      dispatch(imageCleanUpAction());
    };
  }, [dispatch, trapId]);

  return (
    <div>
      <Topbar
        title={`Armadilha ${trapName}`}
        subtitle="Todas as leituras desta armadilha realizadas até hoje"
      />
      <ImagesList />
    </div>
  );
};

export default Images;
