const LOT_SCAN = '/lot_scan';

const ROUTER_PATHS = {
  HOME: '/',
  CLOSED_REPORTS: '/reports_closed',
  LOGIN: '/LOGIN',
  TRAPS: '/traps',
  TRAP_IMAGES: '/traps/images',
  TRAP_IMAGE_DETAILS: '/traps/images/details',
  GROUPS: '/groups',
  NEW_GROUP: '/groups/new',
  EDIT_GROUP: '/groups/edit',
  LOT_SCAN_INIT: `${LOT_SCAN}`,
  LOT_SCAN_IMAGES: `${LOT_SCAN}/images`,
  QUEUE_SCAN_INIT: '/queue_scan',
  QUEUE_SCAN_IMAGES: '/queue_scan/images',
  SINGE_SCAN: '/single_scan',
  NEW_REPORT: '/reports_new',
  EDIT_REPORT: '/reports_edit',
  REPORT: '/report',
};

export default ROUTER_PATHS;
