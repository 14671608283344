import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import { useStyles } from './styles';

const StatusBar = ({ text, variant }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography
        variant="subtitle2"
        className={`${classes[variant]} ${classes.messageBox}`}
        align="center"
      >
        {text}
      </Typography>
    </div>
  );
};

StatusBar.propTypes = {
  text: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['red', 'yellow', 'green']).isRequired,
};

export default StatusBar;
