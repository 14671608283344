import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import {
  hasTrapToEditSelector,
  selectedTrapSelector,
} from 'store/modules/traps/selectors';
import {
  clearSelectedTrapAction,
  updateTrapAction,
} from 'store/modules/traps/actions';

import TrapForm from '../trapForm';
import { useStyles } from '../../styles';

const EditTrap = () => {
  const classes = useStyles();
  const selectedTrap = useSelector(selectedTrapSelector);
  const open = useSelector(hasTrapToEditSelector);
  const dispatch = useDispatch();

  const [trap, setTrap] = useState({
    id: 0,
    name: '',
    latitude: 0,
    longitude: 0,
  });

  useEffect(() => {
    if (open) {
      setTrap(selectedTrap);
    }
  }, [open, selectedTrap]);

  const onChangeHandler = (e) => {
    setTrap({
      ...trap,
      [e.target.id]: e.target.value,
    });
  };

  const handleClose = () => {
    dispatch(clearSelectedTrapAction());
  };

  const handleSubmit = () => {
    dispatch(updateTrapAction(trap));
    handleClose();
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} maxWidth="xs">
        <DialogTitle>Editar armadilha</DialogTitle>
        <DialogContent>
          <TrapForm
            formdata={trap}
            onChangeHandler={onChangeHandler}
            disableLocation
          />
          <div className={classes.cardButtons}>
            <Button
              className={classes.cardButton}
              onClick={handleClose}
              color="primary"
            >
              CANCELAR
            </Button>
            <Button
              className={classes.cardButton}
              onClick={handleSubmit}
              color="primary"
              variant="contained"
            >
              SALVAR
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default EditTrap;
