import React from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';

import { useStyles } from '../styles';

const NavButton = ({ selected, onClickHandler, text }) => {
  const classes = useStyles();
  return (
    <Button
      className={`${classes.navButton} ${selected ? classes.selected : ''}`}
      onClick={onClickHandler}
    >
      {text}
    </Button>
  );
};

NavButton.defaultProps = {
  selected: false,
  onClickHandler: () => {},
};

NavButton.propTypes = {
  selected: PropTypes.bool,
  onClickHandler: PropTypes.func,
  text: PropTypes.string.isRequired,
};

export default NavButton;
