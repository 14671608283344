import { takeLatest, put, call } from 'redux-saga/effects';
import {
  AUTH_LOGIN_LOAD,
  AUTH_INDEX_ORGANIZATIONS_LOAD,
  AUTH_SET_SELECTED_ORGANIZATION,
  AUTH_LOGOUT,
} from 'store/types';
import ROUTER_PATHS from 'constants/router';
import history from 'services/history';
import { loginUserService, indexOrganizations } from 'services/auth';
import axios from 'utils/axios';

import { errorNotificationAction } from 'store/modules/notifications/actions';

import {
  authIsLoadingAction,
  authLoginSuccessAction,
  authErrorAction,
  indexOrganizationsSuccessAction,
  indexOrganizationsLoadAction,
  logoutAction,
} from './actions';

function* login({ userInfo }) {
  yield put(authIsLoadingAction());
  try {
    const response = yield loginUserService(userInfo);
    axios.defaults.headers.Authorization = `Bearer ${response.token}`;

    yield put(
      authLoginSuccessAction({
        token: response.token,
        saveSession: userInfo.rememberMe,
        email: userInfo.email,
      })
    );
    yield put(indexOrganizationsLoadAction(response));

    yield call(history.push, ROUTER_PATHS.HOME);
  } catch (e) {
    yield put(authErrorAction(e));
  }
}

function* loadOrganizations() {
  yield put(authIsLoadingAction());
  try {
    const organizations = yield indexOrganizations();

    if (!organizations.length) {
      throw new Error(
        'Este usuário não possui acesso a uma organização com produto psilídio'
      );
    }

    const [selectedOrganization] = organizations;
    axios.defaults.headers['organization-id'] = selectedOrganization.id;

    yield put(
      indexOrganizationsSuccessAction({ organizations, selectedOrganization })
    );
  } catch (e) {
    yield put(errorNotificationAction(e.message));
    yield put(authErrorAction(e));
  }
}

function setSelectedOrganization({ data }) {
  axios.defaults.headers['organization-id'] = data.id;
}

function* setAuthHeaders({ payload }) {
  if (!payload) return;

  const { token, selectedOrganization, saveSession } = payload.auth.data;

  if (token && selectedOrganization && saveSession) {
    axios.defaults.headers['organization-id'] = selectedOrganization.id;
    axios.defaults.headers.Authorization = `Bearer ${token}`;
  } else {
    yield put(logoutAction());
  }
}

function* logout() {
  yield call(history.push, ROUTER_PATHS.LOGIN);
}

const locationSagas = [
  takeLatest('persist/REHYDRATE', setAuthHeaders),
  takeLatest(AUTH_LOGOUT, logout),
  takeLatest(AUTH_LOGIN_LOAD, login),
  takeLatest(AUTH_INDEX_ORGANIZATIONS_LOAD, loadOrganizations),
  takeLatest(AUTH_SET_SELECTED_ORGANIZATION, setSelectedOrganization),
];

export default locationSagas;
