import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import {
  trapsCleanUpAction,
  trapsSearchAction,
} from 'store/modules/traps/actions';

import Topbar from 'components/layout/topbar';
import NavButtons from 'components/NavButtons';
import TrapsList from './components/trapsList';
import AddTrapModal from './components/addTrapModal';
import EditTrapModal from './components/editTrapModal';
import { useStyles } from './styles';

const TrapsPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const [addTrapOpen, setAddTrapOpen] = useState(false);
  const [search, setSearch] = useState('');
  const navButtons = [
    {
      selected: true,
      text: 'ARMADILHAS',
    },
    {
      onClickHandler: () => history.push('/groups'),
      text: 'GRUPOS',
    },
  ];

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(trapsSearchAction(search));
  }, [search, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(trapsCleanUpAction());
    };
  }, [dispatch]);

  const openAddTrapModal = () => {
    setAddTrapOpen(true);
  };

  return (
    <div>
      <Topbar
        title="Armadilhas"
        subtitle="Crie, edite e visualize suas armadilhas e grupos "
      />
      <div className={classes.navContainer}>
        <NavButtons buttonsList={navButtons} />
        <div className={classes.navRight}>
          <TextField
            type="text"
            id="search"
            label="Pesquisar"
            color="secondary"
            className={classes.searchInput}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <Button className={classes.addButton} onClick={openAddTrapModal}>
            + NOVA ARMADILHA
          </Button>
        </div>
      </div>
      <TrapsList />
      <AddTrapModal open={addTrapOpen} setOpen={setAddTrapOpen} />
      <EditTrapModal />
    </div>
  );
};

export default TrapsPage;
