import React, { useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  ifFrontSideSelector,
  currentSideHasImageSelector,
  currentSideImageSelector,
} from 'store/modules/queue-scan/selectors';
import {
  changeSideAction,
  uploadImageAction,
} from 'store/modules/queue-scan/actions';

import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import CircleIcon from 'assets/icons/Circle.svg';
import Button from '@material-ui/core/Button';

import { useStyles } from '../../styles';

const ImageBox = () => {
  const classes = useStyles();
  const filesRef = useRef(null);
  const dispatch = useDispatch();
  const hasImage = useSelector(currentSideHasImageSelector);
  const currentSideImage = useSelector(currentSideImageSelector);

  const frontSide = useSelector(ifFrontSideSelector);

  const side = !frontSide ? 'FRENTE' : 'VERSO';

  const changeHandler = (e) => {
    if (e.target.files) {
      dispatch(uploadImageAction(e.target.files));
    }
    filesRef.current.value = '';
  };

  const containerClickHandler = () => {
    filesRef.current.click();
  };

  const toggleSidesHandler = () => {
    dispatch(changeSideAction());
  };

  const renderImageBox = () =>
    hasImage ? (
      <div>
        <img src={currentSideImage} alt="trap" width="240" height="420" />
      </div>
    ) : (
      <Container
        className={classes.uploadCardBox}
        onClick={containerClickHandler}
      >
        <Typography variant="h6" component="h2" align="center" gutterBottom>
          Toque para tirar a foto da armadilha
        </Typography>
        <img src={CircleIcon} alt="upload" />
        <input
          ref={filesRef}
          hidden
          type="file"
          onChange={changeHandler}
          name="images"
          accept="image/*"
        />
      </Container>
    );

  return (
    <div className={classes.imageBox}>
      {renderImageBox()}
      <Button
        color="primary"
        variant="outlined"
        fullWidth
        className={classes.toggleButton}
        onClick={toggleSidesHandler}
      >
        VER {side}
      </Button>
    </div>
  );
};

export default ImageBox;
