import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';

import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import CircleIcon from 'assets/icons/Circle.svg';

import { uploadImageAction } from 'store/modules/single-scan/actions';

import { useStyles } from '../../styles';

const UploadCard = () => {
  const classes = useStyles();
  const filesRef = useRef(null);
  const dispatch = useDispatch();

  const changeHandler = (e) => {
    if (e.target.files) {
      dispatch(uploadImageAction(e.target.files));
    }
    filesRef.current.value = '';
  };

  const containerClickHandler = () => {
    filesRef.current.click();
  };

  return (
    <Container
      className={classes.uploadCardBox}
      onClick={containerClickHandler}
    >
      <Typography variant="h6" component="h2" align="center" gutterBottom>
        Toque para tirar a foto da armadilha
      </Typography>
      <img src={CircleIcon} alt="upload" />
      <input
        ref={filesRef}
        hidden
        type="file"
        onChange={changeHandler}
        name="images"
        accept="image/*"
      />
    </Container>
  );
};

export default UploadCard;
