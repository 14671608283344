import {
  AUTH_LOGIN_LOAD,
  AUTH_IS_LOADING,
  AUTH_LOGIN_SUCCESS,
  AUTH_ERROR,
  AUTH_INDEX_ORGANIZATIONS_LOAD,
  AUTH_INDEX_ORGANIZATIONS_SUCCESS,
  AUTH_SET_SELECTED_ORGANIZATION,
  AUTH_LOGOUT,
} from 'store/types';

export const authLoginLoadAction = (userInfo) => ({
  type: AUTH_LOGIN_LOAD,
  userInfo,
});

export const authLoginSuccessAction = (data) => ({
  data,
  type: AUTH_LOGIN_SUCCESS,
});

export const authIsLoadingAction = () => ({
  type: AUTH_IS_LOADING,
});

export const authErrorAction = (error) => ({
  error,
  type: AUTH_ERROR,
});

export const indexOrganizationsLoadAction = () => ({
  type: AUTH_INDEX_ORGANIZATIONS_LOAD,
});

export const indexOrganizationsSuccessAction = (data) => ({
  type: AUTH_INDEX_ORGANIZATIONS_SUCCESS,
  data,
});

export const setSelectedOrganizationAction = (organization) => ({
  type: AUTH_SET_SELECTED_ORGANIZATION,
  data: organization,
});

export const logoutAction = () => ({
  type: AUTH_LOGOUT,
});
