export const validateImageAttributes = (image) => {
  if (
    !image.backImageUrl ||
    !image.frontImageUrl ||
    !image.captureDate ||
    !image.trap ||
    !image.vegetativeStage
  ) {
    throw new Error('Preenche todos os dados e faça upload de frente e verso');
  }
};
