import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  topBar: {
    height: 70,
  },
  title: {
    flexGrow: 1,
  },
  list: {
    width: 250,
  },
  userDDTitle: {
    marginTop: -theme.spacing(1),
    background: '#D9D9D9',
  },
  userDDTitleText: {
    fontWeight: theme.typography.fontWeightBold,
  },
  userDDItem: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  activeOrganizationAvatar: {
    color: `${theme.palette.getContrastText(
      theme.palette.primary.main
    )} !important`,
    backgroundColor: `${theme.palette.primary.main} !important`,
  },
  subtitle: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
}));
