import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { trapsIndexAction } from 'store/modules/traps/actions';
import {
  trapsListSelector,
  hasFilteredTrapsSelector,
  filteredTrapsSelector,
} from 'store/modules/traps/selectors';

import TrapListItem from './trapListItem';
import { useStyles } from '../../styles';

const TrapsList = () => {
  const classes = useStyles();
  const traps = useSelector(trapsListSelector) || [];
  const hasFilteredTraps = useSelector(hasFilteredTrapsSelector);
  const filteredTraps = useSelector(filteredTrapsSelector) || [];
  const dispatch = useDispatch();

  const mapTrapsList = (trap) => <TrapListItem key={trap.id} trap={trap} />;

  useEffect(() => {
    dispatch(trapsIndexAction());
  }, [dispatch]);

  return (
    <div className={classes.listContainer}>
      {hasFilteredTraps
        ? filteredTraps.map(mapTrapsList)
        : traps.map(mapTrapsList)}
    </div>
  );
};

export default TrapsList;
