import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  background: {
    position: 'absolute',
    left: '0',
    top: '0',
    minHeight: '350px',
    // backgroundImage: `url(${loginBackground})`,
    backgroundColor: '#ccc',
    width: '100%',
    zIndex: '-1',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  container: {
    width: 'auto',
    margin: theme.spacing(2),
    marginTop: '0px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  box: {
    maxWidth: '350px',
  },
  logo: {
    marginTop: theme.spacing(4),
  },
  title: {
    color: theme.palette.white.main,
    fontWeight: 'bold',
    fontSize: '2.4em',
    marginBottom: theme.spacing(7),
    marginTop: theme.spacing(3),
  },
  loginBox: {
    padding: theme.spacing(3),
  },
  inputField: {
    width: '100%',
    paddingBottom: theme.spacing(2),
  },
  rememberLoginBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(5),
  },
  loginButton: {
    width: '100%',
    color: theme.palette.white.main,
    padding: '10px',
  },
  possuiContaBox: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
  },
  crieContaButton: {
    border: `2px solid ${theme.palette.primary.main}`,
    '&:hover': {
      border: `2px solid ${theme.palette.primary.main}`,
    },
    width: '65%',
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  languagesBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'center',
    padding: '10%',
  },
  flag: {
    minWidth: '52px',
    background: '#C4C4C4',
    minHeight: '37px',
  },
}));
