import produce from 'immer';
import {
  REPORTS_CLEANUP,
  REPORTS_ERROR,
  REPORTS_IS_LOADING,
  REPORTS_LOAD_GROUPS_SUCCESS,
  REPORTS_NEW_REPORT_INIT,
  REPORTS_UPDATE_SELECTED,
  REPORTS_SELECTED_ADD_GROUP,
  REPORTS_SELECTED_REMOVE_GROUP,
  REPORTS_LOAD_REPORT_SUCCESS,
  REPORTS_INDEX_OPEN_SUCCESS,
  REPORTS_REPORT_BY_VEGETATIVE_STAGE_SUCCESS,
  REPORTS_INDEX_CLOSED_SUCCESS,
  REPORTS_CLOSE_REPORT_SUCCESS,
} from 'store/types';

export const initialState = {
  isLoading: false,
  hasLoaded: false,
  error: {},
  data: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case REPORTS_CLOSE_REPORT_SUCCESS:
      return produce(state, (draft) => {
        draft.isLoading = false;
        draft.hasLoaded = true;
        draft.data.selectedReport.endDate = action.data.endDate;
      });

    case REPORTS_INDEX_CLOSED_SUCCESS:
      return produce(state, (draft) => {
        draft.isLoading = false;
        draft.hasLoaded = true;
        draft.data.reportsList = action.data;
      });

    case REPORTS_REPORT_BY_VEGETATIVE_STAGE_SUCCESS:
      return produce(state, (draft) => {
        draft.isLoading = false;
        draft.hasLoaded = true;
        draft.data.selectedReport = action.data;
      });

    case REPORTS_INDEX_OPEN_SUCCESS:
      return produce(state, (draft) => {
        draft.isLoading = false;
        draft.hasLoaded = true;
        draft.data.reportsList = action.data;
      });

    case REPORTS_LOAD_REPORT_SUCCESS:
      return produce(state, (draft) => {
        draft.isLoading = false;
        draft.hasLoaded = true;
        draft.data.selectedReport = action.data;
      });

    case REPORTS_SELECTED_REMOVE_GROUP:
      return produce(state, (draft) => {
        const { groups } = draft.data.selectedReport;
        draft.data.selectedReport.groups = groups.filter(
          (group) => group.id !== action.data.id
        );
      });

    case REPORTS_SELECTED_ADD_GROUP:
      return produce(state, (draft) => {
        draft.data.selectedReport.groups.push(action.data);
      });

    case REPORTS_UPDATE_SELECTED:
      return produce(state, (draft) => {
        draft.isLoading = false;
        draft.hasLoaded = true;
        draft.data.selectedReport = action.data;
      });

    case REPORTS_LOAD_GROUPS_SUCCESS:
      return produce(state, (draft) => {
        draft.isLoading = false;
        draft.hasLoaded = true;
        draft.data.groups = action.data;
      });

    case REPORTS_NEW_REPORT_INIT:
      return produce(state, (draft) => {
        draft.data.selectedReport = {
          startDate: new Date().toJSON(),
          name: '',
          groups: [],
        };
      });

    case REPORTS_IS_LOADING:
      return produce(state, (draft) => {
        draft.isLoading = true;
        draft.hasLoaded = false;
      });

    case REPORTS_ERROR:
      return produce(state, (draft) => {
        draft.isLoading = false;
        draft.hasLoaded = true;
        draft.error = action.error;
      });

    case REPORTS_CLEANUP:
      return initialState;

    default:
      return state;
  }
};
