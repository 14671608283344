import React from 'react';
import { useSelector } from 'react-redux';

import { trapImagesSelector } from 'store/modules/images/selectors';

import ImageCard from '../imageCard';
import { useStyles } from '../../styles';

const ImagesList = () => {
  const classes = useStyles();
  const images = useSelector(trapImagesSelector);

  return (
    <div className={classes.listContainer}>
      {images.map((image) => (
        <ImageCard key={image.id} image={image} />
      ))}
    </div>
  );
};

export default ImagesList;
