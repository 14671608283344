import React from 'react';
import { useDispatch } from 'react-redux';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { submitTrapsAction } from 'store/modules/queue-scan/actions';

import { useStyles } from '../../styles';

const SubmitButton = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const submitClickHandler = () => {
    dispatch(submitTrapsAction());
  };

  return (
    <div className={classes.submitButton}>
      <Typography display="block" color="textSecondary" variant="subtitle2">
        Envie todas as imagens antes de finalizar
      </Typography>
      <Button
        color="primary"
        variant="contained"
        fullWidth
        onClick={submitClickHandler}
      >
        Finalizar envio das imagens
      </Button>
    </div>
  );
};

export default SubmitButton;
