import React from 'react';
import { useSelector } from 'react-redux';

import { currentSideImageSelector } from 'store/modules/single-scan/selectors';

const ImageContainer = () => {
  const imageUrl = useSelector(currentSideImageSelector);

  return (
    <div>
      <img src={imageUrl} alt="trap" width="240" height="420" />
    </div>
  );
};

export default ImageContainer;
