import produce from 'immer';
import {
  LOT_SCAN_INIT,
  LOT_SCAN_CLEANUP,
  LOT_SCAN_IS_LOADING,
  LOT_SCAN_ERROR,
  LOT_SCAN_LOAD_GROUPS_SUCCESS,
  LOT_SCAN_ADD_IMAGE_SUCCESS,
  LOT_SCAN_LOAD_TRAPS_SUCCESS,
  LOT_SCAN_UPDATE_IMAGE_SUCCESS,
  LOT_SCAN_CHANGE_PAGE,
  LOT_SCAN_LOAD_VEGETATIVE_STAGES_SUCCESS,
  LOT_SCAN_IS_UPLOADING,
  LOT_SCAN_DONE_UPLOADING,
} from 'store/types';

export const initialState = {
  isLoading: false,
  hasLoaded: false,
  isUploading: false,
  error: {},
  data: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOT_SCAN_IS_UPLOADING:
      return produce(state, (draft) => {
        draft.isUploading = true;
      });

    case LOT_SCAN_DONE_UPLOADING:
      return produce(state, (draft) => {
        draft.isUploading = false;
      });

    case LOT_SCAN_LOAD_VEGETATIVE_STAGES_SUCCESS:
      return produce(state, (draft) => {
        draft.isLoading = false;
        draft.hasLoaded = true;
        draft.data.vegetativeStages = action.data;
      });

    case LOT_SCAN_CHANGE_PAGE:
      return produce(state, (draft) => {
        draft.data.currentPage = action.data;
      });

    case LOT_SCAN_UPDATE_IMAGE_SUCCESS:
      return produce(state, (draft) => {
        draft.isLoading = false;
        draft.hasLoaded = true;

        draft.data.images = draft.data.images.map((image) =>
          image.url === action.data.url ? action.data : image
        );
      });

    case LOT_SCAN_LOAD_TRAPS_SUCCESS:
      return produce(state, (draft) => {
        draft.isLoading = false;
        draft.hasLoaded = true;
        draft.data.traps = action.data;
      });

    case LOT_SCAN_ADD_IMAGE_SUCCESS:
      return produce(state, (draft) => {
        const images = draft.data.images || [];
        const newImages = action.data.map((image) => ({
          url: image,
          date: draft.data.defaultDate || new Date(),
          vegetativeStage: '',
          side: '',
        }));
        draft.data.images = [...images, ...newImages];
        draft.isLoading = false;
        draft.hasLoaded = true;
      });

    case LOT_SCAN_LOAD_GROUPS_SUCCESS:
      return produce(state, (draft) => {
        draft.data.groups = action.data;
        draft.isLoading = false;
        draft.hasLoaded = true;
      });

    case LOT_SCAN_INIT:
      return produce(state, (draft) => {
        draft.data.defaultDate = action.data;
      });

    case LOT_SCAN_IS_LOADING:
      return produce(state, (draft) => {
        draft.isLoading = true;
        draft.hasLoaded = false;
      });

    case LOT_SCAN_ERROR:
      return produce(state, (draft) => {
        draft.isLoading = false;
        draft.hasLoaded = true;
        draft.error = action.data;
      });
    case LOT_SCAN_CLEANUP:
      return initialState;

    default:
      return state;
  }
};
