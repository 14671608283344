/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import Typography from '@material-ui/core/Typography';
import DragIcon from 'assets/icons/Mover.svg';

import { selectedGroupTrapsSelector } from 'store/modules/groups/selectors';
import { updateSelectedGroupTraps } from 'store/modules/groups/actions';

import { useStyles } from '../../styles';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const TrapsSequence = () => {
  const classes = useStyles();
  const selectedGroupTraps = useSelector(selectedGroupTrapsSelector);
  const dispatch = useDispatch();

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const traps = reorder(
      selectedGroupTraps,
      result.source.index,
      result.destination.index
    );
    dispatch(updateSelectedGroupTraps(traps));
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(droppable) => {
          const { droppableProps, innerRef } = droppable;
          return (
            <div {...droppableProps} ref={innerRef}>
              {selectedGroupTraps.map((trap, index) => (
                <Draggable key={trap.id} draggableId={trap.id} index={index}>
                  {(draggable) => {
                    const { draggableProps, dragHandleProps } = draggable;
                    const draggableInnerRef = draggable.innerRef;

                    return (
                      <div
                        className={classes.cardTrapLine}
                        ref={draggableInnerRef}
                        {...draggableProps}
                        {...dragHandleProps}
                      >
                        <Typography
                          variant="subtitle1"
                          className={classes.cardLineIndex}
                        >
                          {index + 1}ª
                        </Typography>
                        <img
                          src={DragIcon}
                          alt="DragIcon"
                          className={classes.dragIcon}
                        />
                        <Typography variant="subtitle1">{trap.name}</Typography>
                      </div>
                    );
                  }}
                </Draggable>
              ))}
              {droppable.placeholder}
            </div>
          );
        }}
      </Droppable>
    </DragDropContext>
  );
};

export default TrapsSequence;
