import React from 'react';
import PropTypes from 'prop-types';

import NavButton from './NavButton';

const NavButtons = ({ buttonsList }) => {
  return (
    <div>
      {buttonsList.map((btn) => (
        <NavButton
          key={btn.text}
          selected={btn.selected}
          onClickHandler={btn.onClickHandler}
          text={btn.text}
        />
      ))}
    </div>
  );
};

NavButtons.propTypes = {
  buttonsList: PropTypes.arrayOf(
    PropTypes.shape({
      selected: PropTypes.bool,
      onClickHandler: PropTypes.func,
      text: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default NavButtons;
