import { takeLatest, put } from 'redux-saga/effects';
import {
  TRAP_INDEX_LOAD,
  TRAP_ADD,
  TRAP_UPDATE,
  TRAP_DELETE,
} from 'store/types';
import {
  indexTraps,
  storeTrap,
  putTrap,
  deleteTrapService,
} from 'services/traps';

import {
  errorNotificationAction,
  successNotificationAction,
} from 'store/modules/notifications/actions';

import {
  trapsIndexErrorAction,
  trapsIsLoadingAction,
  trapsIndexSuccessAction,
  addTrapSuccessAction,
  addTrapErrorAction,
  updateTrapSuccessAction,
  updateTrapErrorAction,
  deleteTrapSuccessAction,
  deleteTrapErrorAction,
} from './actions';

function* loadTraps() {
  yield put(trapsIsLoadingAction());

  try {
    const traps = yield indexTraps();
    yield put(trapsIndexSuccessAction(traps));
  } catch (error) {
    yield put(
      errorNotificationAction('Não foi possivel carregar as armadilhas!')
    );
    yield put(trapsIndexErrorAction(error));
  }
}

function* addTrap({ data }) {
  yield put(trapsIsLoadingAction());

  try {
    const createdTrap = yield storeTrap(data);
    yield put(addTrapSuccessAction(createdTrap));
    yield put(successNotificationAction('Armadilha! adicionada com sucesso'));
  } catch (error) {
    yield put(errorNotificationAction('Não foi possivel salvar a armadilha!'));
    yield put(addTrapErrorAction(error));
  }
}

function* updateTrap({ data }) {
  yield put(trapsIsLoadingAction());

  try {
    const updatedTrap = yield putTrap(data);
    yield put(updateTrapSuccessAction(updatedTrap));
    yield put(successNotificationAction('Armadilha! atualizada com sucesso'));
  } catch (error) {
    yield put(
      errorNotificationAction('Não foi possivel atualizar a armadilha!')
    );
    yield put(updateTrapErrorAction(error));
  }
}

function* deleteTrap({ data }) {
  yield put(trapsIsLoadingAction());

  try {
    yield deleteTrapService(data);
    yield put(deleteTrapSuccessAction(data));
    yield put(successNotificationAction('Armadilha! excluída com sucesso'));
  } catch (error) {
    yield put(errorNotificationAction('Não foi possivel excluir a armadilha!'));
    yield put(deleteTrapErrorAction(error));
  }
}

const trapsSagas = [
  takeLatest(TRAP_INDEX_LOAD, loadTraps),
  takeLatest(TRAP_ADD, addTrap),
  takeLatest(TRAP_UPDATE, updateTrap),
  takeLatest(TRAP_DELETE, deleteTrap),
];

export default trapsSagas;
