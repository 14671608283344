import { takeLatest, put, select, call } from 'redux-saga/effects';
import {
  GROUP_INDEX_LOAD,
  GROUP_NEW_SUBMIT,
  GROUP_FIND_LOAD,
  GROUP_EDIT_SUBMIT,
} from 'store/types';
import ROUTER_PATHS from 'constants/router';
import history from 'services/history';
import {
  indexGroups,
  postStoreGroup,
  getFindGroup,
  putEditGroup,
} from 'services/groups';

import {
  errorNotificationAction,
  successNotificationAction,
} from 'store/modules/notifications/actions';

import {
  groupsIsLoadingAction,
  groupsIndexSuccessAction,
  groupsErrorAction,
  newGroupSubmitSuccessAction,
  findGroupSuccessAction,
} from './actions';
import { selectedGroupSelector } from './selectors';

import { formatTraps } from './utils/formatGroupTraps';

function* loadGroups() {
  yield put(groupsIsLoadingAction());

  try {
    const groups = yield indexGroups();
    yield put(groupsIndexSuccessAction(groups));
  } catch (error) {
    yield put(errorNotificationAction('Não foi possivel carregar os grupos!'));
    yield put(groupsErrorAction(error));
  }
}

function* storeGroup() {
  yield put(groupsIsLoadingAction());

  try {
    const group = yield select(selectedGroupSelector);
    const createdGroup = yield postStoreGroup(group);
    yield put(newGroupSubmitSuccessAction(createdGroup));
    yield call(history.push, ROUTER_PATHS.GROUPS);
    yield put(successNotificationAction('O grupo foi criado com sucesso.'));
  } catch (error) {
    yield put(errorNotificationAction('Não foi possivel adicionar o grupo!'));
    yield put(groupsErrorAction(error));
  }
}

function* findGroup({ data }) {
  yield put(groupsIsLoadingAction());

  try {
    const group = yield getFindGroup(data);
    group.traps = formatTraps(group.traps);
    yield put(findGroupSuccessAction(group));
  } catch (error) {
    yield put(errorNotificationAction('Não foi possivel encontrar o grupo!'));
    yield put(groupsErrorAction(error));
  }
}

function* editGroup() {
  yield put(groupsIsLoadingAction());

  try {
    const group = yield select(selectedGroupSelector);
    yield putEditGroup(group);
    yield call(history.push, ROUTER_PATHS.GROUPS);
    yield put(successNotificationAction('O grupo foi salvo com sucesso.'));
  } catch (error) {
    yield put(errorNotificationAction('Não foi possivel editar o grupo!'));
    yield put(groupsErrorAction(error));
  }
}

const groupsSagas = [
  takeLatest(GROUP_INDEX_LOAD, loadGroups),
  takeLatest(GROUP_NEW_SUBMIT, storeGroup),
  takeLatest(GROUP_FIND_LOAD, findGroup),
  takeLatest(GROUP_EDIT_SUBMIT, editGroup),
];

export default groupsSagas;
