import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';

import { authHasErrorSelector } from 'store/modules/auth/selectors';

import { useStyles } from '../styles';

const LoginForm = ({ onSubmit }) => {
  const authHasError = useSelector(authHasErrorSelector);

  const classes = useStyles();
  const [loginFormData, setloginFormData] = useState({
    email: '',
    password: '',
    rememberMe: false,
  });

  const onChangeHandler = (e) => {
    setloginFormData({
      ...loginFormData,
      [e.target.id]: e.target.value,
    });
  };

  const rememberMeHandler = () => {
    setloginFormData({
      ...loginFormData,
      rememberMe: !loginFormData.rememberMe,
    });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    onSubmit({
      email: loginFormData.email,
      password: loginFormData.password,
      rememberMe: loginFormData.rememberMe,
    });
  };

  return (
    <div>
      <Paper elevation={3} className={classes.loginBox}>
        <form onSubmit={submitHandler}>
          <FormGroup row>
            <TextField
              className={classes.inputField}
              type="email"
              id="email"
              label="E-mail"
              variant="outlined"
              color="secondary"
              value={loginFormData.email}
              onChange={onChangeHandler}
            />
          </FormGroup>
          <FormGroup row>
            <TextField
              className={classes.inputField}
              type="password"
              id="password"
              label="Senha"
              variant="outlined"
              color="secondary"
              value={loginFormData.password}
              onChange={onChangeHandler}
            />
          </FormGroup>
          <FormGroup row className={classes.rememberLoginBox}>
            <FormControlLabel
              control={
                <Checkbox
                  id="rememberMe"
                  name="rememberLogin"
                  checked={loginFormData.rememberMe}
                  onChange={rememberMeHandler}
                />
              }
              label="Lembrar do login"
            />
            <a href="https://www.cultivointeligente.com.br/users/password/new">
              <span> Esqueceu a senha?</span>
            </a>
          </FormGroup>
          {authHasError ? (
            <FormGroup row>
              <Typography
                variant="button"
                display="block"
                color="error"
                gutterBottom
              >
                Email ou senha errado
              </Typography>
            </FormGroup>
          ) : null}

          <FormGroup row>
            <Button
              variant="contained"
              color="primary"
              className={classes.loginButton}
              onClick={submitHandler}
              type="submit"
            >
              ENTRAR
            </Button>
          </FormGroup>
        </form>
      </Paper>
    </div>
  );
};

LoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default LoginForm;
