import React from 'react';
import PropTypes from 'prop-types';

import TextField from '@material-ui/core/TextField';

import { useStyles } from '../../styles';

const TrapForm = ({ formdata, onChangeHandler, disableLocation }) => {
  const classes = useStyles();

  return (
    <div className={classes.addTrapForm}>
      <TextField
        className={classes.inputField}
        type="text"
        id="name"
        label="Armadilha"
        variant="outlined"
        color="secondary"
        value={formdata.name}
        onChange={onChangeHandler}
      />
      <TextField
        className={`${classes.inputField} ${classes.halfWidth}`}
        type="number"
        id="latitude"
        label="Latitude"
        variant="outlined"
        color="secondary"
        value={formdata.latitude}
        onChange={onChangeHandler}
        disabled={disableLocation}
      />
      <TextField
        className={`${classes.inputField} ${classes.halfWidth}`}
        type="number"
        id="longitude"
        label="Longitude"
        variant="outlined"
        color="secondary"
        value={formdata.longitude}
        onChange={onChangeHandler}
        disabled={disableLocation}
      />
    </div>
  );
};

TrapForm.defaultProps = {
  disableLocation: false,
};

TrapForm.propTypes = {
  formdata: PropTypes.shape({
    name: PropTypes.string.isRequired,
    latitude: PropTypes.number.isRequired,
    longitude: PropTypes.number.isRequired,
  }).isRequired,
  onChangeHandler: PropTypes.func.isRequired,
  disableLocation: PropTypes.bool,
};

export default TrapForm;
