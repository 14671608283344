import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ifFrontSideSelector } from 'store/modules/single-scan/selectors';
import { changeSideAction } from 'store/modules/single-scan/actions';

import Button from '@material-ui/core/Button';
import { useStyles } from '../../styles';

const NavButtons = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const frontSide = useSelector(ifFrontSideSelector);

  const changeSideClickHandler = () => {
    dispatch(changeSideAction());
  };

  return (
    <div className={classes.navButtons}>
      <Button
        disabled={frontSide}
        color="primary"
        onClick={changeSideClickHandler}
      >
        {'< '} FRENTE
      </Button>
      <Button
        disabled={!frontSide}
        color="primary"
        onClick={changeSideClickHandler}
      >
        VERSO {' >'}{' '}
      </Button>
    </div>
  );
};

export default NavButtons;
