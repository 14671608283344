import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { trapsWithErrorSelector } from 'store/modules/queue-scan/selectors';
import {
  clearTrapsWithErrorAction,
  submitOnlyValidTrapsAction,
} from 'store/modules/queue-scan/actions';

import { useStyles } from '../../styles';

const ConfirmChangeTrapModal = () => {
  const classes = useStyles();
  const trapsWithError = useSelector(trapsWithErrorSelector);
  const dispatch = useDispatch();

  const closeHandler = () => {
    dispatch(clearTrapsWithErrorAction());
  };
  const confirmHandler = () => {
    dispatch(submitOnlyValidTrapsAction());
  };

  const renderTrapsWithError = () =>
    trapsWithError &&
    trapsWithError.map((trap) => (
      <Typography
        variant="body1"
        align="left"
        key={trap.trap.value}
        gutterBottom
      >
        Armadilha: {trap.trap.label}
      </Typography>
    ));

  return (
    <Dialog open={!!trapsWithError} openonClose={closeHandler} maxWidth="xs">
      <DialogContent>
        <Typography variant="body1" align="left" gutterBottom>
          Ainda restam as seguintes imagens das armadilhas do grupo para serem
          enviadas:
        </Typography>
        {renderTrapsWithError()}

        <Typography variant="body1" align="left">
          Deseja prosseguir mesmo assim?
        </Typography>

        <div className={classes.dialogButtonsContainer}>
          <Button
            className={classes.dialogButton}
            onClick={closeHandler}
            color="primary"
            variant="text"
          >
            NÃO
          </Button>
          <Button
            className={classes.dialogButton}
            onClick={confirmHandler}
            variant="contained"
            color="primary"
          >
            SIM
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmChangeTrapModal;
