import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  whiteBtn: {
    color: theme.palette.white.main,
  },
  paper: {
    paddingTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  radioContainer: {
    display: 'flex',
    marginBottom: theme.spacing(2),
  },
  topButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row-reverse',
    paddingRight: theme.spacing(3),
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: '80vh',
    paddingLeft: theme.spacing(5),
    [theme.breakpoints.only('sm')]: {
      paddingLeft: theme.spacing(0),
    },
    [theme.breakpoints.only('lg')]: {
      paddingLeft: theme.spacing(0),
    },
  },
  imageListContainer: {
    flex: 1,
    display: 'flex',
    flexFlow: 'wrap',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      width: '100%',
    },
  },
  submitBtn: {
    color: theme.palette.white.main,
    marginTop: theme.spacing(1),
    minWidth: 270,
  },
  imageListItem: {
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(5),
    '&:last-child': {
      marginRight: theme.spacing(0),
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(0),
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      maxWidth: '290px',
    },
  },
  imageCard: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
  },
  row: {
    display: 'flex',
    marginBottom: theme.spacing(1),
  },
  cardSelect: {
    flex: 1,
    zIndex: 3,
    paddingTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  vegetativeStagesSelect: {
    zIndex: 2,
    width: '100%',
  },
  cardDate: {
    flex: 1,
  },
  cardRadio: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  paginationContainer: {
    cursor: 'pointer',
    display: 'flex',
  },
  paginationList: {
    listStyle: 'none',
    padding: 0,
  },
  page: {
    marginRight: theme.spacing(1),
    padding: 0,
    minWidth: 36,
    fontWeight: theme.typography.fontWeightBold,
  },
  active: {
    color: theme.palette.primary.main,
  },
  paginationButtons: {
    display: 'flex',
    justifyContent: 'space-around',
  },
}));
