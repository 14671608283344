import produce from 'immer';
import {
  IMAGE_ERROR,
  IMAGE_CLEANUP,
  IMAGE_IS_LOADING,
  IMAGE_INDEX_SUCCESS,
  IMAGE_FIND_SUCCESS,
  IMAGE_UPDATE_SUCCESS,
  IMAGE_SUBMIT_UPDATED_SUCCESS,
} from 'store/types';

export const initialState = {
  isLoading: false,
  hasLoaded: false,
  error: {},
  data: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case IMAGE_SUBMIT_UPDATED_SUCCESS:
      return produce(state, (draft) => {
        draft.isLoading = false;
        draft.hasLoaded = true;
        draft.data.image = action.data;
      });

    case IMAGE_UPDATE_SUCCESS:
      return produce(state, (draft) => {
        draft.isLoading = false;
        draft.hasLoaded = true;
        if (action.data.side === 'front') {
          draft.data.image.frontImageUrl = action.data.url;
        } else {
          draft.data.image.backImageUrl = action.data.url;
        }
        draft.data.image.changed = true;
      });

    case IMAGE_FIND_SUCCESS:
      return produce(state, (draft) => {
        draft.isLoading = false;
        draft.hasLoaded = true;
        draft.data.image = action.data;
      });

    case IMAGE_INDEX_SUCCESS:
      return produce(state, (draft) => {
        draft.isLoading = false;
        draft.hasLoaded = true;
        draft.data.trapWithImages = action.data;
      });

    case IMAGE_IS_LOADING:
      return produce(state, (draft) => {
        draft.isLoading = true;
        draft.hasLoaded = false;
        draft.error = {};
      });

    case IMAGE_ERROR:
      return produce(state, (draft) => {
        draft.isLoading = false;
        draft.hasLoaded = true;
        draft.error = action.error;
      });

    case IMAGE_CLEANUP:
      return initialState;

    default:
      return state;
  }
};
