import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Button from '@material-ui/core/Button';

import {
  imagesCountSelector,
  currentPageSelector,
} from 'store/modules/lot-scan/selectors';
import { changePageAction } from 'store/modules/lot-scan/actions';

import Pages from './pages';
import { useStyles } from '../../styles';

const Pagination = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const imagesCount = useSelector(imagesCountSelector);
  const currentPage = useSelector(currentPageSelector);
  const pages = Math.ceil(imagesCount / 4);

  const prevPageHandler = () => {
    dispatch(changePageAction(currentPage - 1));
  };

  const nextPageHandler = () => {
    dispatch(changePageAction(currentPage + 1));
  };

  const pageClickHandler = (number) => {
    dispatch(changePageAction(number));
  };

  return (
    <div className="">
      <Pages
        pageCount={pages}
        currentPage={currentPage}
        onChange={pageClickHandler}
      />
      <div className={classes.paginationButtons}>
        <Button
          color="primary"
          disabled={currentPage === 1}
          onClick={prevPageHandler}
        >
          {'< '} ANTERIOR
        </Button>
        <Button
          color="primary"
          disabled={currentPage === pages}
          onClick={nextPageHandler}
        >
          PRÓXIMA{' >'}
        </Button>
      </div>
    </div>
  );
};

export default Pagination;
