import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  listContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  listItem: {
    margin: theme.spacing(3),
    minWidth: '290px',
    [theme.breakpoints.up('xs')]: {
      maxWidth: '290px',
    },
  },
  paper: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },
  cardTitleText: {
    fontSize: '1.4rem',
    marginBottom: theme.spacing(1),
  },
  container: {
    marginBottom: theme.spacing(1),
    fontWeight: theme.typography.fontWeightBold,
    display: 'flex',
    justifyContent: 'space-between',
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  backBtn: {
    display: 'block',
    marginLeft: theme.spacing(5),
    minWidth: '150px',
    minHeight: '60px',
  },
  detailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  reSendBtn: {
    marginBottom: theme.spacing(3),
    color: theme.palette.white.main,
  },
  imageBox: {
    '&:first-child': {
      marginRight: theme.spacing(3),
    },
    [theme.breakpoints.down('sm')]: {
      '&:first-child': {
        marginRight: 0,
      },
    },
    marginBottom: theme.spacing(2),
  },
  image: {
    width: '240px',
    height: '420px',
  },
  imagesContainer: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  imageChangePaper: {
    cursor: 'pointer',
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
  },
  changeText: {
    alignSelf: 'center',
    fontSize: '0.68rem',
  },
}));
