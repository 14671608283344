import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

import {
  trapsIndexAction,
  trapsSearchAction,
} from 'store/modules/traps/actions';

import { useStyles } from '../../styles';
import Traps from './traps';

const TrapsList = () => {
  const classes = useStyles();
  const [search, setSearch] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(trapsIndexAction());
  }, [dispatch]);

  useEffect(() => {
    dispatch(trapsSearchAction(search));
  }, [dispatch, search]);

  return (
    <Paper elevation={3} className={classes.trapsPaper}>
      <Typography variant="h5" gutterBottom>
        ARMADILHAS DISPONIVEl
      </Typography>
      <Typography variant="subtitle2" gutterBottom>
        Selecione quais armadilhas deseja adicionar à nova sequência
      </Typography>
      <TextField
        variant="outlined"
        type="text"
        id="search"
        label="Pesquisar"
        color="secondary"
        className={classes.input}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      <div className={classes.trapsListContainer}>
        <Traps />
      </div>
    </Paper>
  );
};

export default TrapsList;
