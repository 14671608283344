import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import ROUTER_PATH from 'constants/router';

import { useStyles } from '../../styles';

const ReportHeader = ({
  groupBy,
  setGroupBy,
  reportId,
  closeReportClickHandler,
  closedReport,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const groupByChangeHandler = (e) => {
    setGroupBy(e.target.value);
  };

  return (
    <>
      <div>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="groupBy">Agrupamento</InputLabel>
          <Select
            labelId="groupBy"
            id="groupBy"
            value={groupBy}
            onChange={groupByChangeHandler}
            label="Agrupamento"
            className={classes.groupBySelect}
          >
            <MenuItem value={1} disabled={groupBy === 1}>
              Por estágio
            </MenuItem>
            <MenuItem value={2} disabled={groupBy === 2}>
              Por grupo
            </MenuItem>
          </Select>
        </FormControl>
      </div>
      {!closedReport ? (
        <div>
          <Button
            color="primary"
            className={classes.reportHeaderBtn}
            onClick={() =>
              history.push(`${ROUTER_PATH.EDIT_REPORT}/${reportId}`)
            }
          >
            EDITAR
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={`${classes.reportHeaderBtn} ${classes.whiteText}`}
            onClick={closeReportClickHandler}
          >
            FINALIZAR RELATÓRIO
          </Button>
        </div>
      ) : null}
    </>
  );
};

ReportHeader.propTypes = {
  groupBy: PropTypes.number.isRequired,
  setGroupBy: PropTypes.func.isRequired,
  reportId: PropTypes.string.isRequired,
  closeReportClickHandler: PropTypes.func.isRequired,
  closedReport: PropTypes.bool.isRequired,
};

export default ReportHeader;
