import {
  QUEUE_SCAN_INIT,
  QUEUE_SCAN_IS_LOADING,
  QUEUE_SCAN_CLEANUP,
  QUEUE_SCAN_ERROR,
  QUEUE_SCAN_LOAD_GROUPS_SUCCESS,
  QUEUE_SCAN_LOAD_VEGETATIVE_STAGES,
  QUEUE_SCAN_LOAD_VEGETATIVE_STAGES_SUCCESS,
  QUEUE_SCAN_LOAD_TRAPS,
  QUEUE_SCAN_LOAD_TRAPS_SUCCESS,
  QUEUE_SCAN_NEXT_TRAP,
  QUEUE_SCAN_NEXT_TRAP_SUCCESS,
  QUEUE_SCAN_PREV_TRAP,
  QUEUE_SCAN_PREV_TRAP_SUCCESS,
  QUEUE_SCAN_CHANGE_TRAP,
  QUEUE_SCAN_CHANGE_TRAP_SUCCESS,
  QUEUE_SCAN_CHANGE_SIDE,
  QUEUE_SCAN_UPLOAD_IMAGE,
  QUEUE_SCAN_UPLOAD_IMAGE_SUCCESS,
  QUEUE_SCAN_DELETE_IMAGE,
  QUEUE_SCAN_UPDATE_CURRENT_TRAP,
  QUEUE_SCAN_TRAP_MISSING_DATA_ERROR,
  QUEUE_SCAN_CHANGE_TRAP_CLEAR_CURRENT,
  QUEUE_SCAN_TRAP_MISSING_DATA_ERROR_CLEAR,
  QUEUE_SCAN_SUBMIT_TRAPS,
  QUEUE_SCAN_SUBMIT_TRAPS_ERROR,
  QUEUE_SCAN_CLEAR_TRAPS_WITH_ERROR,
  QUEUE_SCAN_SUBMIT_VALID_TRAPS,
} from 'store/types';

export const queueScanInitAction = (date) => ({
  type: QUEUE_SCAN_INIT,
  data: date,
});

export const queueScanIsLoadingAction = () => ({
  type: QUEUE_SCAN_IS_LOADING,
});

export const queueScanCleanUpAction = () => ({
  type: QUEUE_SCAN_CLEANUP,
});

export const queueScanErrorAction = (error) => ({
  type: QUEUE_SCAN_ERROR,
  data: error,
});

export const loadGroupsSuccessAction = (groups) => ({
  type: QUEUE_SCAN_LOAD_GROUPS_SUCCESS,
  data: groups,
});

export const loadVegetativeStagesAction = () => ({
  type: QUEUE_SCAN_LOAD_VEGETATIVE_STAGES,
});

export const loadVegetativeStagesSuccessAction = (stages) => ({
  type: QUEUE_SCAN_LOAD_VEGETATIVE_STAGES_SUCCESS,
  data: stages,
});

export const loadTrapsAction = (groupId) => ({
  type: QUEUE_SCAN_LOAD_TRAPS,
  data: groupId,
});

export const loadTrapsSuccessAction = (traps) => ({
  type: QUEUE_SCAN_LOAD_TRAPS_SUCCESS,
  data: traps,
});

export const nextTrapAction = () => ({
  type: QUEUE_SCAN_NEXT_TRAP,
});

export const nextTrapSuccessAction = () => ({
  type: QUEUE_SCAN_NEXT_TRAP_SUCCESS,
});

export const prevTrapAction = () => ({
  type: QUEUE_SCAN_PREV_TRAP,
});

export const prevTrapSuccessAction = () => ({
  type: QUEUE_SCAN_PREV_TRAP_SUCCESS,
});

export const changeTrapAction = (trap) => ({
  type: QUEUE_SCAN_CHANGE_TRAP,
  data: trap,
});

export const changeTrapSuccessAction = (newCurrentTrap) => ({
  type: QUEUE_SCAN_CHANGE_TRAP_SUCCESS,
  data: newCurrentTrap,
});

export const changeSideAction = () => ({
  type: QUEUE_SCAN_CHANGE_SIDE,
});

export const uploadImageAction = (file) => ({
  type: QUEUE_SCAN_UPLOAD_IMAGE,
  data: file,
});

export const uploadImageSuccessAction = (imageUrl) => ({
  type: QUEUE_SCAN_UPLOAD_IMAGE_SUCCESS,
  data: imageUrl,
});

export const deleteImageAction = () => ({
  type: QUEUE_SCAN_DELETE_IMAGE,
});

export const updateCurrentTrapAction = (updatedTrap) => ({
  type: QUEUE_SCAN_UPDATE_CURRENT_TRAP,
  data: updatedTrap,
});

export const trapMissingDataErrorAction = (trapIndex) => ({
  type: QUEUE_SCAN_TRAP_MISSING_DATA_ERROR,
  data: trapIndex,
});

export const changeTrapClearCurrentAction = () => ({
  type: QUEUE_SCAN_CHANGE_TRAP_CLEAR_CURRENT,
});

export const clearTrapMissingDataErrorAction = () => ({
  type: QUEUE_SCAN_TRAP_MISSING_DATA_ERROR_CLEAR,
});

export const submitTrapsAction = () => ({
  type: QUEUE_SCAN_SUBMIT_TRAPS,
});

export const submitTrapsErrorAction = (trapsMissingData) => ({
  type: QUEUE_SCAN_SUBMIT_TRAPS_ERROR,
  data: trapsMissingData,
});

export const clearTrapsWithErrorAction = () => ({
  type: QUEUE_SCAN_CLEAR_TRAPS_WITH_ERROR,
});

export const submitOnlyValidTrapsAction = () => ({
  type: QUEUE_SCAN_SUBMIT_VALID_TRAPS,
});
