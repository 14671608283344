import React from 'react';
import PropTypes from 'prop-types';
import { useLocation, useHistory } from 'react-router-dom';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import Report from 'assets/icons/Report.svg';
import Traps from 'assets/icons/Traps.svg';
import Groups from 'assets/icons/Groups.svg';
import Logo from '../../logo';

import { useStyles } from './styles';

const SidebarList = ({ closeDrawer }) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const redirect = (path) => {
    history.push(path);
  };

  return (
    <div
      className={classes.list}
      role="presentation"
      onClick={closeDrawer}
      onKeyDown={closeDrawer}
    >
      <Logo />
      <List>
        <ListItem
          button
          disabled={location.pathname === '/'}
          selected={location.pathname === '/'}
          onClick={() => redirect('/')}
        >
          <ListItemIcon>
            <img src={Report} alt="Reports" />
          </ListItemIcon>
          <ListItemText primary="Relatórios" />
        </ListItem>
        <ListItem
          button
          disabled={location.pathname === '/traps'}
          selected={location.pathname === '/traps'}
          onClick={() => redirect('/traps')}
        >
          <ListItemIcon>
            <img src={Traps} alt="Traps" />
          </ListItemIcon>
          <ListItemText primary="Armadilhas" />
        </ListItem>
        <ListItem
          button
          disabled={location.pathname === '/groups'}
          selected={location.pathname === '/groups'}
          onClick={() => redirect('/groups')}
        >
          <ListItemIcon>
            <img src={Groups} alt="groups" />
          </ListItemIcon>
          <ListItemText primary="Grupos" />
        </ListItem>
      </List>
    </div>
  );
};

SidebarList.propTypes = {
  closeDrawer: PropTypes.func.isRequired,
};

export default SidebarList;
