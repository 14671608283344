import { combineReducers } from 'redux';

import auth, { initialState as authInitialState } from './modules/auth/reducer';
import traps, {
  initialState as trapsInitialState,
} from './modules/traps/reducer';
import groups, {
  initialState as groupsInitialState,
} from './modules/groups/reducer';

import images, {
  initialState as imagesInitialState,
} from './modules/images/reducer';

import lotScan, {
  initialState as lotScanInitialState,
} from './modules/lot-scan/reducer';

import singleScan, {
  initialState as singleScanInitialState,
} from './modules/single-scan/reducer';

import notifications, {
  initialState as notificationsInitialState,
} from './modules/notifications/reducer';

import reports, {
  initialState as reportsInitialState,
} from './modules/reports/reducer';

import queueScan, {
  initialState as queueScanInitialState,
} from './modules/queue-scan/reducer';

export const initialState = {
  auth: authInitialState,
  traps: trapsInitialState,
  groups: groupsInitialState,
  images: imagesInitialState,
  lotScan: lotScanInitialState,
  singleScan: singleScanInitialState,
  notifications: notificationsInitialState,
  reports: reportsInitialState,
  queueScan: queueScanInitialState,
};

export default combineReducers({
  auth,
  traps,
  groups,
  images,
  lotScan,
  singleScan,
  notifications,
  reports,
  queueScan,
});
