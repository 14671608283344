import React from 'react';
import { useSelector } from 'react-redux';

import {
  imagesSelector,
  currentPageSelector,
} from 'store/modules/lot-scan/selectors';

import Item from './item';

import { useStyles } from '../../styles';

const ImageList = () => {
  const classes = useStyles();
  const images = useSelector(imagesSelector) || [];
  const currentPage = useSelector(currentPageSelector) || [];

  const indexOfLastImage = currentPage * 4;
  const indexOfFirstImage = indexOfLastImage - 4;

  const currentPageImages = images.slice(indexOfFirstImage, indexOfLastImage);

  return (
    <div className={classes.imageListContainer}>
      {currentPageImages.map((image) => (
        <Item key={image.url} image={image} />
      ))}
    </div>
  );
};

export default ImageList;
