import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  navContainer: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      width: '100%',
    },
    justifyContent: 'space-between',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  btn: {
    fontWeight: theme.typography.fontWeightBold,
    minWidth: '180px',
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(2),
      width: '45%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
  },
  whiteText: {
    color: theme.palette.white.main,
  },
  cardsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
    margin: theme.spacing(3),
  },
  listItem: {
    margin: theme.spacing(3),
    minWidth: '197px',
    maxWidth: '443px',
    minHeight: '170px',
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    cursor: 'pointer',
  },
  cardTitle: {
    fontSize: '1.05rem',
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  cardDate: {
    fontSize: '0.75rem',
    color: theme.palette.secondary.light,
    marginBottom: theme.spacing(1),
  },
  cardTextBold: {
    fontSize: '0.75rem',
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(1),
  },
  cardText: {
    fontSize: '0.75rem',
    marginBottom: theme.spacing(3),
  },
}));
