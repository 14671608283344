import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Button from '@material-ui/core/Button';

import {
  hasNextTrapSelector,
  hasPrevTrapSelector,
} from 'store/modules/queue-scan/selectors';
import {
  nextTrapAction,
  prevTrapAction,
} from 'store/modules/queue-scan/actions';

import { useStyles } from '../../styles';

const NextTrapButton = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const hasNextTrap = useSelector(hasNextTrapSelector);
  const hasPrevTrap = useSelector(hasPrevTrapSelector);

  const prevTrapClickHandler = () => {
    dispatch(prevTrapAction());
  };
  const nextTrapClickHandler = () => {
    dispatch(nextTrapAction());
  };

  return (
    <div className={classes.navButtons}>
      <Button
        disabled={!hasPrevTrap}
        color="primary"
        onClick={prevTrapClickHandler}
      >
        {'< '} ANTERIOR
      </Button>
      <Button
        disabled={!hasNextTrap}
        color="primary"
        onClick={nextTrapClickHandler}
      >
        PRÓXIMA {' >'}
      </Button>
    </div>
  );
};

export default NextTrapButton;
