import produce from 'immer';
import {
  SINGLE_SCAN_INIT,
  SINGLE_SCAN_CLEANUP,
  SINGLE_SCAN_ERROR,
  SINGLE_SCAN_IS_LOADING,
  SINGLE_SCAN_LOAD_TRAPS_SUCCESS,
  SINGLE_SCAN_UPDATE_IMAGE,
  SINGLE_SCAN_CHANGE_SIDE,
  SINGLE_SCAN_UPLOAD_IMAGE_SUCCESS,
  SINGLE_SCAN_DELETE_IMAGE,
  SINGLE_SCAN_LOAD_VEGETATIVE_STAGES_SUCCESS,
} from 'store/types';

export const initialState = {
  isLoading: false,
  hasLoaded: false,
  error: {},
  data: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SINGLE_SCAN_LOAD_VEGETATIVE_STAGES_SUCCESS:
      return produce(state, (draft) => {
        draft.isLoading = false;
        draft.hasLoaded = true;
        draft.data.vegetativeStages = action.data;
      });

    case SINGLE_SCAN_DELETE_IMAGE:
      return produce(state, (draft) => {
        if (draft.data.frontSide) {
          draft.data.image.frontImageUrl = '';
        } else {
          draft.data.image.backImageUrl = '';
        }
      });

    case SINGLE_SCAN_UPLOAD_IMAGE_SUCCESS:
      return produce(state, (draft) => {
        draft.isLoading = false;
        draft.hasLoaded = true;
        if (draft.data.frontSide) {
          draft.data.image.frontImageUrl = action.data;
        } else {
          draft.data.image.backImageUrl = action.data;
        }
      });

    case SINGLE_SCAN_CHANGE_SIDE:
      return produce(state, (draft) => {
        draft.data.frontSide = !draft.data.frontSide;
      });

    case SINGLE_SCAN_UPDATE_IMAGE:
      return produce(state, (draft) => {
        draft.data.image = {
          ...draft.data.image,
          ...action.data,
        };
      });

    case SINGLE_SCAN_LOAD_TRAPS_SUCCESS:
      return produce(state, (draft) => {
        draft.isLoading = false;
        draft.hasLoaded = true;
        draft.data.traps = action.data;
      });

    case SINGLE_SCAN_INIT:
      return produce(state, (draft) => {
        draft.data.image = {
          frontImageUrl: '',
          backImageUrl: '',
          captureDate: action.data,
        };
        draft.data.frontSide = true;
      });

    case SINGLE_SCAN_IS_LOADING:
      return produce(state, (draft) => {
        draft.isLoading = true;
        draft.hasLoaded = false;
      });

    case SINGLE_SCAN_ERROR:
      return produce(state, (draft) => {
        draft.isLoading = false;
        draft.hasLoaded = true;
        draft.error = action.data;
      });
    case SINGLE_SCAN_CLEANUP:
      return initialState;

    default:
      return state;
  }
};
