import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import Paper from '@material-ui/core/Paper';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ptBrLocal from 'date-fns/locale/pt-BR';

import Select from 'components/SelectWithSearch';

import {
  trapsSelector,
  vegetativeStagesSelector,
} from 'store/modules/lot-scan/selectors';
import {
  updateImageAction,
  updateImageSuccessAction,
} from 'store/modules/lot-scan/actions';

import { useStyles } from '../../styles';

const Card = ({ image }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const vegetativeStages = useSelector(vegetativeStagesSelector) || [];
  const traps = useSelector(trapsSelector) || [];
  const options = traps.map((trap) => ({
    value: trap.id,
    label: trap.name,
  }));

  const dispatchUpdateImage = (updated) => {
    dispatch(updateImageAction(updated));
  };

  const trapSelectChangeHandler = (newOption) => {
    const updated = {
      ...image,
      trap: newOption,
    };
    dispatchUpdateImage(updated);
  };

  const dateChangeHandler = (newDate) => {
    const updated = {
      ...image,
      date: newDate.toJSON(),
    };
    dispatchUpdateImage(updated);
  };

  const vegetativeStageChangeHandler = (newOption) => {
    const updated = {
      ...image,
      vegetativeStage: newOption,
    };
    dispatch(updateImageSuccessAction(updated));
  };

  const sideChangeHandler = (event) => {
    const updated = {
      ...image,
      side: event.target.value,
    };
    dispatchUpdateImage(updated);
  };

  return (
    <Paper elevation={5} className={classes.imageCard}>
      <div className={classes.row}>
        <Select
          placeholder="Armadilha"
          className={classes.cardSelect}
          options={options}
          handleChange={trapSelectChangeHandler}
          selected={image.trap}
        />
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBrLocal}>
          <KeyboardDatePicker
            label="Data da imagen"
            value={image.date}
            onChange={dateChangeHandler}
            animateYearScrolling
            format="dd/MM/yyyy"
            fullWidth
            className={classes.cardDate}
            size="small"
          />
        </MuiPickersUtilsProvider>
      </div>
      <div className={classes.row}>
        <Select
          placeholder="Estágio vegetativo"
          className={classes.vegetativeStagesSelect}
          options={vegetativeStages}
          handleChange={vegetativeStageChangeHandler}
          selected={image.vegetativeStage}
        />
      </div>
      <RadioGroup value={image.side} onChange={sideChangeHandler}>
        <div className={classes.cardRadio}>
          <FormControlLabel
            value="FRONT"
            control={<Radio color="primary" />}
            label="Frente"
            labelPlacement="end"
          />
          <FormControlLabel
            value="BACK"
            control={<Radio color="primary" />}
            label="Verso"
            labelPlacement="end"
          />
        </div>
      </RadioGroup>
    </Paper>
  );
};

Card.propTypes = {
  image: PropTypes.shape({
    trap: PropTypes.object,
    url: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    vegetativeStage: PropTypes.string.isRequired,
    side: PropTypes.string.isRequired,
  }).isRequired,
};

export default Card;
