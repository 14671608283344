import produce from 'immer';
import {
  GROUP_CLEANUP,
  GROUP_INDEX_SUCCESS,
  GROUP_ERROR,
  GROUP_IS_LOADING,
  GROUP_NEW_INIT,
  GROUP_SELECTED_UPDATE_NAME,
  GROUP_SELECTED_UPDATE_TRAPS,
  GROUP_SELECTED_ADD_TRAP,
  GROUP_SELECTED_REMOVE_TRAP,
  GROUP_NEW_SUBMIT_SUCCESS,
  GROUP_FIND_SUCCESS,
  GROUP_SELECTED_CLEANUP,
} from 'store/types';

export const initialState = {
  isLoading: false,
  hasLoaded: false,
  error: {},
  data: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GROUP_SELECTED_CLEANUP:
      return produce(state, (draft) => {
        draft.isLoading = false;
        draft.hasLoaded = true;
        draft.data.selectedGroup = {
          name: '',
          traps: [],
        };
      });

    case GROUP_FIND_SUCCESS:
      return produce(state, (draft) => {
        draft.isLoading = false;
        draft.hasLoaded = true;
        draft.data.selectedGroup = action.data;
      });

    case GROUP_NEW_SUBMIT_SUCCESS:
      return produce(state, (draft) => {
        draft.isLoading = false;
        draft.hasLoaded = true;
        draft.data.addedGroup = action.data;
      });

    case GROUP_SELECTED_REMOVE_TRAP:
      return produce(state, (draft) => {
        draft.data.selectedGroup.traps = draft.data.selectedGroup.traps.filter(
          (trap) => trap.id !== action.data.id
        );
      });

    case GROUP_SELECTED_ADD_TRAP:
      return produce(state, (draft) => {
        draft.data.selectedGroup.traps.push(action.data);
      });

    case GROUP_SELECTED_UPDATE_TRAPS:
      return produce(state, (draft) => {
        draft.data.selectedGroup.traps = action.data;
      });

    case GROUP_SELECTED_UPDATE_NAME:
      return produce(state, (draft) => {
        draft.data.selectedGroup.name = action.data;
      });

    case GROUP_NEW_INIT:
      return produce(state, (draft) => {
        draft.data.selectedGroup = {
          name: '',
          traps: [],
        };
      });

    case GROUP_INDEX_SUCCESS:
      return produce(state, (draft) => {
        draft.isLoading = false;
        draft.hasLoaded = true;
        draft.data.groups = action.data;
      });

    case GROUP_ERROR:
      return produce(state, (draft) => {
        draft.isLoading = false;
        draft.hasLoaded = true;
        draft.error = action.error;
      });

    case GROUP_IS_LOADING:
      return produce(state, (draft) => {
        draft.isLoading = true;
        draft.hasLoaded = false;
        draft.error = {};
      });

    case GROUP_CLEANUP:
      return initialState;

    default:
      return state;
  }
};
