import React from 'react';
import { useHistory } from 'react-router-dom';

import Button from '@material-ui/core/Button';

import Topbar from 'components/layout/topbar';
import NavButtons from 'components/NavButtons';
import ROUTER_PATHS from 'constants/router';
import { useStyles } from './styles';

import GroupsList from './components/groupsList';

const Groups = () => {
  const classes = useStyles();
  const history = useHistory();
  const navButtons = [
    {
      onClickHandler: () => history.push(ROUTER_PATHS.TRAPS),
      text: 'ARMADILHAS',
    },
    {
      selected: true,
      text: 'GRUPOS',
    },
  ];

  return (
    <div>
      <Topbar
        title="Grupos de armadilhas"
        subtitle="Crie, edite e visualize suas armadilhas e grupos "
      />
      <div className={classes.navContainer}>
        <NavButtons buttonsList={navButtons} />
        <div>
          <Button
            className={classes.addButton}
            onClick={() => history.push(ROUTER_PATHS.NEW_GROUP)}
          >
            + Novo Grupo
          </Button>
        </div>
      </div>
      <GroupsList />
    </div>
  );
};

export default Groups;
