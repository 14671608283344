import { takeLatest, put, select, call } from 'redux-saga/effects';
import ROUTER_PATHS from 'constants/router';
import history from 'services/history';
import {
  QUEUE_SCAN_INIT,
  QUEUE_SCAN_LOAD_VEGETATIVE_STAGES,
  QUEUE_SCAN_LOAD_TRAPS,
  QUEUE_SCAN_NEXT_TRAP,
  QUEUE_SCAN_PREV_TRAP,
  QUEUE_SCAN_CHANGE_TRAP,
  QUEUE_SCAN_UPLOAD_IMAGE,
  QUEUE_SCAN_SUBMIT_TRAPS,
  QUEUE_SCAN_SUBMIT_VALID_TRAPS,
} from 'store/types';
import { indexGroups, getFindGroup } from 'services/groups';
import {
  indexVegetativeStages,
  getUploadUrl,
  storeImages,
} from 'services/images';
import { uploadImageService } from 'services/aws-s3';
import { indexTraps } from 'services/traps';

import {
  errorNotificationAction,
  successNotificationAction,
} from 'store/modules/notifications/actions';

import {
  queueScanIsLoadingAction,
  queueScanErrorAction,
  loadGroupsSuccessAction,
  loadVegetativeStagesSuccessAction,
  loadTrapsSuccessAction,
  nextTrapSuccessAction,
  prevTrapSuccessAction,
  changeTrapSuccessAction,
  uploadImageSuccessAction,
  trapMissingDataErrorAction,
  submitTrapsErrorAction,
  clearTrapsWithErrorAction,
} from './actions';
import {
  defaultDateSelector,
  trapsSelector,
  hasPrevTrapSelector,
  hasNextTrapSelector,
  selectedTrapSelector,
  currentTrapIndexSelector,
  queueSelector,
} from './selectors';
import { trapsToImagesQueue, formatImages } from './serializers';
import { validateTrapData } from './validators';

function* init() {
  yield put(queueScanIsLoadingAction());
  try {
    const groups = yield indexGroups();

    yield put(loadGroupsSuccessAction(groups));
  } catch (error) {
    yield put(errorNotificationAction('Não foi possivel carregar os grupos!'));
    yield put(queueScanErrorAction(error));
  }
}

function* loadVegetativeStages() {
  yield put(queueScanIsLoadingAction());

  try {
    const vegetativeStages = yield indexVegetativeStages();
    yield put(loadVegetativeStagesSuccessAction(vegetativeStages));
  } catch (error) {
    yield put(
      errorNotificationAction(
        'Não foi possivel carregar os estágios vegetativos!'
      )
    );
    yield put(queueScanErrorAction(error.message));
  }
}

function* loadTraps({ data }) {
  yield put(queueScanIsLoadingAction());

  const defaultDate = yield select(defaultDateSelector);

  try {
    if (data) {
      const group = yield getFindGroup(data);
      const traps = group.traps.map((groupTrap) => groupTrap.trap);

      const queue = trapsToImagesQueue({ traps, defaultDate });

      yield put(loadTrapsSuccessAction(queue));
    } else {
      const traps = yield indexTraps();

      const queue = trapsToImagesQueue({ traps, defaultDate });

      yield put(loadTrapsSuccessAction(queue));
    }
  } catch (error) {
    yield put(
      errorNotificationAction('Não foi possivel carregar as armadilhas!')
    );
    yield put(queueScanErrorAction(error));
  }
}

function* nextTrap() {
  const hasNext = yield select(hasNextTrapSelector);
  if (!hasNext) {
    return;
  }
  const trap = yield select(selectedTrapSelector);
  const currentTrapIsValid = validateTrapData(trap);

  if (!currentTrapIsValid) {
    const currentTrapIndex = yield select(currentTrapIndexSelector);
    yield put(trapMissingDataErrorAction(currentTrapIndex + 1));
    return;
  }

  yield put(nextTrapSuccessAction());
}

function* prevTrap() {
  const hasPrev = yield select(hasPrevTrapSelector);
  if (!hasPrev) {
    return;
  }
  const trap = yield select(selectedTrapSelector);
  const currentTrapIsValid = validateTrapData(trap);

  if (!currentTrapIsValid) {
    const currentTrapIndex = yield select(currentTrapIndexSelector);
    yield put(trapMissingDataErrorAction(currentTrapIndex - 1));
    return;
  }
  yield put(prevTrapSuccessAction());
}

function* changeTrap({ data }) {
  const traps = yield select(trapsSelector);
  const index = traps.indexOf(data);

  const trap = yield select(selectedTrapSelector);
  const currentTrapIsValid = validateTrapData(trap);

  if (!currentTrapIsValid) {
    yield put(trapMissingDataErrorAction(index));
    return;
  }

  yield put(changeTrapSuccessAction(index));
}

function* uploadImage({ data }) {
  yield put(queueScanIsLoadingAction());

  try {
    const file = data[0];

    const { uploadUrl } = yield getUploadUrl({
      fileName: file.name,
      fileType: file.type,
    });

    yield uploadImageService({ file, uploadUrl });
    const imageUrl = uploadUrl.split('?')[0];

    yield put(uploadImageSuccessAction(imageUrl));
  } catch (error) {
    yield put(errorNotificationAction('Não foi possivel enviar a imagem!'));
    yield put(queueScanErrorAction(error.message));
  }
}

function* submit() {
  try {
    const queue = yield select(queueSelector);
    const trapsMissingData = queue.filter((trap) => !validateTrapData(trap));

    if (trapsMissingData.length > 0) {
      yield put(submitTrapsErrorAction(trapsMissingData));
      return;
    }

    const images = yield formatImages(queue);
    yield storeImages(images);
    yield call(history.push, ROUTER_PATHS.HOME);
    yield put(
      successNotificationAction(
        'As imagens foram salvas e serão analisadas, o resultado ficará disponível em breve'
      )
    );
  } catch (error) {
    yield put(errorNotificationAction('Não foi possivel enviar a imagem!'));
    yield put(queueScanErrorAction(error.message));
  }
}

function* submitOnlyValid() {
  try {
    const queue = yield select(queueSelector);
    const validTraps = queue.filter((trap) => validateTrapData(trap));

    if (validTraps.length === 0) {
      yield put(
        errorNotificationAction(
          'É necessário preencher os dados de no mínimo uma armadilha'
        )
      );
      yield put(clearTrapsWithErrorAction());
      return;
    }

    const images = yield formatImages(validTraps);
    yield storeImages(images);
    yield call(history.push, ROUTER_PATHS.HOME);
    yield put(
      successNotificationAction(
        'As imagens foram salvas e serão analisadas, o resultado ficará disponível em breve'
      )
    );
  } catch (error) {
    yield put(errorNotificationAction('Não foi possivel enviar a imagem!'));
    yield put(queueScanErrorAction(error.message));
  }
}

const queueSagas = [
  takeLatest(QUEUE_SCAN_INIT, init),
  takeLatest(QUEUE_SCAN_LOAD_VEGETATIVE_STAGES, loadVegetativeStages),
  takeLatest(QUEUE_SCAN_LOAD_TRAPS, loadTraps),
  takeLatest(QUEUE_SCAN_NEXT_TRAP, nextTrap),
  takeLatest(QUEUE_SCAN_PREV_TRAP, prevTrap),
  takeLatest(QUEUE_SCAN_CHANGE_TRAP, changeTrap),
  takeLatest(QUEUE_SCAN_UPLOAD_IMAGE, uploadImage),
  takeLatest(QUEUE_SCAN_SUBMIT_TRAPS, submit),
  takeLatest(QUEUE_SCAN_SUBMIT_VALID_TRAPS, submitOnlyValid),
];

export default queueSagas;
