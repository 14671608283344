import React from 'react';
import PropTypes from 'prop-types';

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ptBrLocal from 'date-fns/locale/pt-BR';

const DateInput = ({ date, label, onChange, className }) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBrLocal}>
      <KeyboardDatePicker
        label={label}
        value={date}
        onChange={onChange}
        animateYearScrolling
        format="dd/MM/yyyy"
        fullWidth
        className={className}
        size="small"
      />
    </MuiPickersUtilsProvider>
  );
};

DateInput.defaultProps = {
  className: '',
};

DateInput.propTypes = {
  date: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default DateInput;
