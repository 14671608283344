export const formatImage = (image) => {
  const formatedImage = {
    ...image,
    trapId: image.trap.value,
    vegetativeStageId: image.vegetativeStage.value,
  };

  delete formatedImage.trap;

  return formatedImage;
};
