import { createSelector } from 'reselect';
import { isEmpty } from 'lodash';

export const groupsSelector = (state) => state.groups;

export const groupsDataSelector = createSelector(
  groupsSelector,
  (groups) => groups.data
);

export const groupsIsLoadingSelector = createSelector(
  groupsSelector,
  (groups) => groups.isLoading
);

export const groupsHasLoadedSelector = createSelector(
  groupsSelector,
  (groups) => groups.hasLoaded
);

export const groupsErrorSelector = createSelector(
  groupsSelector,
  (groups) => groups.error
);

export const groupsHasErrorSelector = createSelector(
  groupsErrorSelector,
  (error) => !isEmpty(error)
);

export const groupsListSelector = createSelector(
  groupsDataSelector,
  (data) => data.groups
);

export const selectedGroupSelector = createSelector(
  groupsDataSelector,
  (data) => data.selectedGroup
);

export const selectedGroupTrapsSelector = createSelector(
  selectedGroupSelector,
  (selectedGroup) => (selectedGroup ? selectedGroup.traps : [])
);

export const selectedGroupNameSelector = createSelector(
  selectedGroupSelector,
  (selectedGroup) => (selectedGroup ? selectedGroup.name : '')
);
