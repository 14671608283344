import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-around',
  },
  messageBox: {
    borderRadius: '0px 0px 5px 5px',
    width: '80%',
  },

  red: {
    backgroundColor: '#FF3C3C',
  },
  yellow: {
    backgroundColor: '#FF9C06',
  },
  green: {
    backgroundColor: '#66a343',
  },
}));
