import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ROUTER_PATH from 'constants/router';

import Button from '@material-ui/core/Button';
import Topbar from 'components/layout/topbar';

import ConfirmModal from 'components/ConfirmModal';
import { useModal } from 'hooks/useModal';

import {
  reportLoadAction,
  reportsCleanUpAction,
  loadGroupsAction,
  updateReportSubmitAction,
  deleteReportAction,
} from 'store/modules/reports/actions';

import GroupsList from './components/GroupsList';
import DataCard from './components/DataCard';

import { useStyles } from './styles';

const EditReport = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { reportId } = useParams();
  const [deleteModalIsOpen, deleteModalClose, deleteModalOpen] = useModal(
    false
  );

  useEffect(() => {
    dispatch(reportLoadAction(reportId));
    dispatch(loadGroupsAction());
    return () => {
      dispatch(reportsCleanUpAction());
    };
  }, [dispatch, reportId]);

  const saveClickHandler = () => {
    dispatch(updateReportSubmitAction());
  };

  const deleteClickHandler = () => {
    dispatch(deleteReportAction(reportId));
    deleteModalClose();
  };

  return (
    <div>
      <Topbar
        title="Criar Relatório"
        subtitle="Selecione os grupos que serão reportados"
      />
      <div className={classes.container}>
        <DataCard />
        <GroupsList />
        <div className={classes.buttonGroup}>
          <Button
            color="primary"
            className={classes.cancelBtn}
            onClick={() => history.push(ROUTER_PATH.HOME)}
          >
            CANCELAR
          </Button>
          <Button
            color="primary"
            variant="contained"
            className={classes.submitBtn}
            onClick={saveClickHandler}
          >
            SALVAR
          </Button>
        </div>
        <div className={classes.buttonGroup}>
          <Button
            variant="outlined"
            className={classes.deleteBtn}
            onClick={deleteModalOpen}
          >
            Excluir relatório
          </Button>
        </div>
      </div>
      <ConfirmModal
        open={deleteModalIsOpen}
        closeHandler={deleteModalClose}
        confirmHandler={deleteClickHandler}
        titleText="Deseja realmente excluir o relatório?"
        cancelText="CANCELAR"
        confirmText="EXCLUIR"
        variant="error"
      />
    </div>
  );
};

export default EditReport;
