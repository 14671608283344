import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';

import { isLoadingSelector } from 'store/modules/shared/selectors';

const Loading = ({ children }) => {
  const isLoading = useSelector(isLoadingSelector);

  return (
    <>
      <Dialog
        open={isLoading}
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
          },
        }}
        fullScreen
      >
        <CircularProgress
          color="primary"
          style={{
            margin: 'auto',
          }}
          thickness={4}
          size={70}
        />
      </Dialog>
      {children}
    </>
  );
};

Loading.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Loading;
