export const formatReport = (report) => {
  const groups = report.groups && report.groups.map((group) => group.id);

  return {
    ...report,
    groups,
  };
};

export const formatReportByVegetativeStagesDetails = (details) =>
  details.map((stage) => ({
    title: stage.name,
    totalAmount: stage.totalInsectsCount,
    data: stage.groups.map((group) => ({
      text: group.name,
      amount: group.insectsCount,
    })),
  }));

export const formatReportByGroupDetails = (details) =>
  details.map((group) => ({
    title: group.name,
    totalAmount: group.totalInsectsCount,
    data: group.vegetativeStages.map((stage) => ({
      text: stage.name,
      amount: stage.insectsCount,
    })),
  }));
