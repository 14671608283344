import { createSelector } from 'reselect';
import { isEmpty } from 'lodash';

export const queueScanSelector = (state) => state.queueScan;

export const queueScanDataSelector = createSelector(
  queueScanSelector,
  (queueScan) => queueScan.data
);

export const queueScanIsLoadingSelector = createSelector(
  queueScanSelector,
  (queueScan) => queueScan.isLoading
);

export const queueScanHasLoadedSelector = createSelector(
  queueScanSelector,
  (queueScan) => queueScan.hasLoaded
);

export const queueScanErrorSelector = createSelector(
  queueScanSelector,
  (queueScan) => queueScan.error
);

export const queueScanHasErrorSelector = createSelector(
  queueScanErrorSelector,
  (error) => !isEmpty(error)
);

export const groupsSelector = createSelector(
  queueScanDataSelector,
  (data) => data.groups
);

export const vegetativeStagesSelector = createSelector(
  queueScanDataSelector,
  (data) =>
    data.vegetativeStages &&
    data.vegetativeStages.map((trap) => ({
      value: trap.id,
      label: trap.name,
    }))
);

export const defaultDateSelector = createSelector(
  queueScanDataSelector,
  (data) => data.defaultDate
);

export const hasNextTrapSelector = createSelector(
  queueScanDataSelector,
  (data) => data.queue && data.currentTrap < data.queue.length - 1
);

export const hasPrevTrapSelector = createSelector(
  queueScanDataSelector,
  (data) => data.currentTrap > 0
);

export const trapsSelector = createSelector(
  queueScanDataSelector,
  (data) => data.queue && data.queue.map((trapEntry) => trapEntry.trap)
);

export const selectedTrapSelector = createSelector(
  queueScanDataSelector,
  (data) => data.queue && data.queue[data.currentTrap]
);

export const ifFrontSideSelector = createSelector(
  queueScanDataSelector,
  (data) => data.frontSide
);

export const hasTrapsSelector = createSelector(
  trapsSelector,
  (traps) => !isEmpty(traps)
);

export const currentSideImageSelector = createSelector(
  queueScanDataSelector,
  (data) =>
    data.queue &&
    (data.frontSide
      ? data.queue[data.currentTrap].frontImageUrl
      : data.queue[data.currentTrap].backImageUrl)
);

export const currentSideHasImageSelector = createSelector(
  currentSideImageSelector,
  (imageUrl) => !!imageUrl
);

export const currentTrapIndexSelector = createSelector(
  queueScanDataSelector,
  (data) => data.currentTrap
);

export const changeTrapErrorSelector = createSelector(
  queueScanDataSelector,
  (data) => data.changeTrapError
);

export const queueSelector = createSelector(
  queueScanDataSelector,
  (data) => data.queue
);

export const trapsWithErrorSelector = createSelector(
  queueScanDataSelector,
  (data) => data.trapsWithError
);
