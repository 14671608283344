import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Topbar from 'components/layout/topbar';

import {
  reportByGroupLoadAction,
  reportByVegetativeStateLoadAction,
  reportsCleanUpAction,
  closeReportAction,
} from 'store/modules/reports/actions';

import { selectedReportSelector } from 'store/modules/reports/selectors';

import { useModal } from 'hooks/useModal';

import ReportHeader from './components/ReportHeader';
import ReportSummary from './components/ReportSummary';
import ReportDetails from './components/ReportDetails';
import CloseReportModal from './components/CloseReportModal';
import { useStyles } from './styles';

const Report = () => {
  const classes = useStyles();
  const { reportId } = useParams();
  const dispatch = useDispatch();
  const [groupBy, setGroupBy] = useState(1);
  const [isOpen, closeModal, openModal] = useModal();

  const closeReportHandler = (endDate) => {
    dispatch(closeReportAction({ id: reportId, endDate }));
  };

  const report = useSelector(selectedReportSelector);

  useEffect(() => {
    if (groupBy === 1) {
      dispatch(reportByVegetativeStateLoadAction(reportId));
    }
    if (groupBy === 2) {
      dispatch(reportByGroupLoadAction(reportId));
    }
    return () => {
      dispatch(reportsCleanUpAction());
    };
  }, [dispatch, reportId, groupBy]);

  return (
    <div>
      <Topbar
        title={`${report.name}`}
        subtitle="Informações acumuladas de todas as leituras realizadas até hoje"
      />
      <div className={classes.reportHeaderContainer}>
        <ReportHeader
          groupBy={groupBy}
          setGroupBy={setGroupBy}
          reportId={reportId}
          closeReportClickHandler={openModal}
          closedReport={!!report.endDate}
        />
      </div>
      <div className={classes.contentContainer}>
        <ReportSummary />
        <ReportDetails />
      </div>
      <CloseReportModal
        open={isOpen}
        closeHandler={closeModal}
        submitHandler={closeReportHandler}
      />
    </div>
  );
};

export default Report;
