import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: 400,
    margin: '0 auto',
    marginTop: theme.spacing(4),
  },
  whiteBtn: {
    color: theme.palette.white.main,
  },
  uploadCardBox: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    justifyContent: 'center',
    border: `solid 2px ${theme.palette.secondary.main}`,
    padding: theme.spacing(1),
    maxWidth: '290px',
    minHeight: '420px',
    cursor: 'pointer',
    marginBottom: theme.spacing(2),
  },
  imageBox: {
    width: '100%',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  toggleButton: {
    width: '95%',
  },
  navButtons: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  submitButton: {
    width: '100%',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  dataCard: {
    marginBottom: theme.spacing(3),
    padding: theme.spacing(1),
    width: '100%',
  },
  row: {
    display: 'flex',
    marginBottom: theme.spacing(1),
    justifyContent: 'space-between',
  },
  trapSelect: {
    flex: 1,
    zIndex: 3,
    paddingTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  cardDate: {
    flex: 1,
  },
  vegetativeStagesSelect: {
    zIndex: 2,
    width: '100%',
  },
  cardText: {
    alignSelf: 'flex-end',
    fontSize: theme.typography.body1,
  },
  bold: {
    fontWeight: theme.typography.fontWeightBold,
  },
  deleteButton: {
    paddingRight: 15,
    minWidth: 'auto',
  },
  dialogButtonsContainer: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
  },
  dialogButton: {
    width: '45%',
  },
}));
