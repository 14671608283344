import React from 'react';
import PropTypes from 'prop-types';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import Container from '@material-ui/core/Container';

import { useStyles } from './styles';

const ScanGroupsSelect = ({ selected, setSelected, groupsList }) => {
  const classes = useStyles();
  const renderTraps = (traps) => {
    const trapsToRender = traps.slice(0, 3);

    return trapsToRender.map(
      (groupTrap, index) =>
        `${groupTrap.trap.name}${index < trapsToRender.length - 1 ? ', ' : '.'}`
    );
  };

  const renderGroups = () => {
    return groupsList.map((group) => (
      <div className={classes.radioContainer} key={group.id}>
        <Radio
          color="primary"
          value={group.id}
          onChange={() => setSelected(group.id)}
        />
        <Container onClick={() => setSelected(group.id)}>
          <Typography display="block" variant="h5">
            {group.name}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            {renderTraps(group.traps)}
          </Typography>
        </Container>
      </div>
    ));
  };

  return (
    <Paper elevation={24} className={classes.paper}>
      <RadioGroup value={selected}>
        {renderGroups()}

        <div className={classes.radioContainer}>
          <Radio color="primary" value="" onChange={() => setSelected('')} />
          <Container onClick={() => setSelected('')}>
            <Typography display="block" variant="h5">
              Fazer a leitura sem utilizar grupo
            </Typography>
          </Container>
        </div>
      </RadioGroup>
    </Paper>
  );
};

ScanGroupsSelect.propTypes = {
  selected: PropTypes.string.isRequired,
  setSelected: PropTypes.func.isRequired,
  groupsList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      traps: PropTypes.arrayOf(
        PropTypes.shape({
          trap: PropTypes.shape({
            name: PropTypes.string,
          }),
        })
      ).isRequired,
    })
  ).isRequired,
};

export default ScanGroupsSelect;
