import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  paper: {
    paddingTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  radioContainer: {
    display: 'flex',
    marginBottom: theme.spacing(2),
  },
}));
