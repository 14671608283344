import {
  SINGLE_SCAN_INIT,
  SINGLE_SCAN_CLEANUP,
  SINGLE_SCAN_ERROR,
  SINGLE_SCAN_IS_LOADING,
  SINGLE_SCAN_LOAD_TRAPS,
  SINGLE_SCAN_LOAD_TRAPS_SUCCESS,
  SINGLE_SCAN_CHANGE_SIDE,
  SINGLE_SCAN_UPLOAD_IMAGE,
  SINGLE_SCAN_UPLOAD_IMAGE_SUCCESS,
  SINGLE_SCAN_UPDATE_IMAGE,
  SINGLE_SCAN_DELETE_IMAGE,
  SINGLE_SCAN_SUBMIT_IMAGE,
  SINGLE_SCAN_LOAD_VEGETATIVE_STAGES,
  SINGLE_SCAN_LOAD_VEGETATIVE_STAGES_SUCCESS,
} from 'store/types';

export const singleScanInitAction = (date) => ({
  type: SINGLE_SCAN_INIT,
  data: date,
});

export const singleScanIsLoadingAction = () => ({
  type: SINGLE_SCAN_IS_LOADING,
});

export const singleScanCleanUpAction = () => ({
  type: SINGLE_SCAN_CLEANUP,
});

export const singleScanErrorAction = (error) => ({
  type: SINGLE_SCAN_ERROR,
  data: error,
});

export const loadTrapsAction = () => ({
  type: SINGLE_SCAN_LOAD_TRAPS,
});

export const loadTrapsSuccessAction = (traps) => ({
  type: SINGLE_SCAN_LOAD_TRAPS_SUCCESS,
  data: traps,
});

export const changeSideAction = () => ({
  type: SINGLE_SCAN_CHANGE_SIDE,
});

export const uploadImageAction = (file) => ({
  type: SINGLE_SCAN_UPLOAD_IMAGE,
  data: file,
});

export const uploadImageSuccessAction = (imageUrl) => ({
  type: SINGLE_SCAN_UPLOAD_IMAGE_SUCCESS,
  data: imageUrl,
});

export const updateImageAction = (updatedImage) => ({
  type: SINGLE_SCAN_UPDATE_IMAGE,
  data: updatedImage,
});

export const deleteImageAction = () => ({
  type: SINGLE_SCAN_DELETE_IMAGE,
});

export const submitImageAction = () => ({
  type: SINGLE_SCAN_SUBMIT_IMAGE,
});

export const loadVegetativeStagesAction = () => ({
  type: SINGLE_SCAN_LOAD_VEGETATIVE_STAGES,
});

export const loadVegetativeStagesSuccessAction = (stages) => ({
  type: SINGLE_SCAN_LOAD_VEGETATIVE_STAGES_SUCCESS,
  data: stages,
});
