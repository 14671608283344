import produce from 'immer';
import {
  AUTH_LOGIN_SUCCESS,
  AUTH_IS_LOADING,
  AUTH_ERROR,
  AUTH_LOGOUT,
  AUTH_INDEX_ORGANIZATIONS_SUCCESS,
  AUTH_SET_SELECTED_ORGANIZATION,
} from 'store/types';

export const initialState = {
  isLoading: false,
  hasLoaded: false,
  error: {},
  data: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case AUTH_SET_SELECTED_ORGANIZATION:
      return produce(state, (draft) => {
        draft.data.selectedOrganization = action.data;
      });

    case AUTH_INDEX_ORGANIZATIONS_SUCCESS:
      return produce(state, (draft) => {
        draft.isLoading = false;
        draft.hasLoaded = true;
        draft.data.organizations = action.data.organizations;
        draft.data.selectedOrganization = action.data.selectedOrganization;
      });

    case AUTH_LOGIN_SUCCESS:
      return produce(state, (draft) => {
        draft.isLoading = false;
        draft.hasLoaded = true;
        draft.data.token = action.data.token;
        draft.data.saveSession = action.data.saveSession;
        draft.data.email = action.data.email;
      });

    case AUTH_IS_LOADING:
      return produce(state, (draft) => {
        draft.isLoading = true;
        draft.hasLoaded = false;
        draft.error = {};
      });

    case AUTH_ERROR:
      return produce(state, (draft) => {
        draft.isLoading = false;
        draft.hasLoaded = true;
        draft.error = action.error;
      });

    case AUTH_LOGOUT:
      return initialState;

    default:
      return state;
  }
};
