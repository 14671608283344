import axios from '../utils/axios';

export const loginUserService = async ({ password, email }) => {
  try {
    const { data } = await axios.post('/auth/login', { password, email });
    return data;
  } catch (e) {
    if (e.response) {
      throw e.response.data;
    }
    const error = {
      message: 'Ocorreu um erro por favor tente novamente!',
    };
    throw error;
  }
};

export const indexOrganizations = async () => {
  try {
    const { data } = await axios.get('/organizations');
    return data;
  } catch (e) {
    if (e.response) {
      throw e.response.data;
    }
    const error = {
      message: 'Ocorreu um erro por favor tente novamente!',
    };
    throw error;
  }
};
