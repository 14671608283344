export const formatTraps = (traps) =>
  traps.reduce((trapsArray, GroupTrap) => {
    trapsArray[GroupTrap.sequenceIndex - 1] = {
      id: GroupTrap.trap.id,
      name: GroupTrap.trap.name,
    };
    return trapsArray;
  }, []);

export default {
  formatTraps,
};
