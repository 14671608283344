import React from 'react';
import PropTypes from 'prop-types';

import Card from './card';

import { useStyles } from '../../styles';

const Item = ({ image }) => {
  const classes = useStyles();

  return (
    <div className={classes.imageListItem}>
      <Card image={image} />
      <img src={image.url} alt="frente" className="" width="290" height="420" />
    </div>
  );
};

Item.propTypes = {
  image: PropTypes.shape({
    trap: PropTypes.object,
    url: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    vegetativeStage: PropTypes.string.isRequired,
    side: PropTypes.string.isRequired,
  }).isRequired,
};

export default Item;
