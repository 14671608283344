import React from 'react';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles';
import Loading from 'components/Loading';
import Notifier from 'components/Notifier';
import Routes from './routes';
import history from './services/history';
import { store, persistor } from './store';
import theme from './assets/materialTheme';

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <Notifier />
          <Loading>
            <Router history={history}>
              <Routes />
            </Router>
          </Loading>
        </PersistGate>
      </Provider>
    </ThemeProvider>
  );
};

export default App;
