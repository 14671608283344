import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import TextField from '@material-ui/core/TextField';
import DateInput from 'components/DateInput';

import { selectedReportSelector } from 'store/modules/reports/selectors';
import { updateSelectedReportAction } from 'store/modules/reports/actions';

import { useStyles } from '../../styles';

const DataCard = () => {
  const classes = useStyles();
  const selectedReport = useSelector(selectedReportSelector);
  const dispatch = useDispatch();

  const dispatchUpdateReport = (updated) => {
    dispatch(updateSelectedReportAction(updated));
  };

  const nameChangeHandler = (e) => {
    const updated = {
      ...selectedReport,
      name: e.target.value,
    };
    dispatchUpdateReport(updated);
  };

  const dateChangeHandler = (newDate) => {
    const updated = {
      ...selectedReport,
      startDate: newDate.toJSON(),
    };
    dispatchUpdateReport(updated);
  };

  return (
    <div>
      <TextField
        type="text"
        placeholder="Ex. Primeira quinzena, Safra"
        label="Nome do relatório"
        variant="standard"
        color="primary"
        fullWidth
        size="small"
        className={classes.input}
        value={selectedReport.name}
        onChange={nameChangeHandler}
      />
      <DateInput
        label="Data de início"
        className={classes.input}
        date={selectedReport.startDate}
        onChange={dateChangeHandler}
      />
    </div>
  );
};

export default DataCard;
