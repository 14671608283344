import {
  TRAP_INDEX_LOAD,
  TRAP_INDEX_IS_LOADING,
  TRAP_INDEX_SUCCESS,
  TRAP_INDEX_ERROR,
  TRAP_CLEANUP,
  TRAP_EDIT_SELECT,
  TRAP_EDIT_CLEAR_SELECTED,
  TRAP_ADD,
  TRAP_ADD_SUCCESS,
  TRAP_ADD_ERROR,
  TRAPS_SEARCH_FILTER,
  TRAPS_REMOVE_SEARCH_FILTER,
  TRAP_UPDATE,
  TRAP_UPDATE_ERROR,
  TRAP_UPDATE_SUCCESS,
  TRAP_DELETE,
  TRAP_DELETE_ERROR,
  TRAP_DELETE_SUCCESS,
} from 'store/types';

export const trapsIndexAction = () => ({
  type: TRAP_INDEX_LOAD,
});

export const trapsCleanUpAction = () => ({
  type: TRAP_CLEANUP,
});

export const trapsIndexSuccessAction = (data) => ({
  type: TRAP_INDEX_SUCCESS,
  data,
});

export const trapsIsLoadingAction = () => ({
  type: TRAP_INDEX_IS_LOADING,
});

export const trapsIndexErrorAction = (error) => ({
  type: TRAP_INDEX_ERROR,
  error,
});

export const selectTrapToEditAction = (trapId) => ({
  type: TRAP_EDIT_SELECT,
  data: trapId,
});

export const clearSelectedTrapAction = () => ({
  type: TRAP_EDIT_CLEAR_SELECTED,
});

export const addTrapAction = (trap) => ({
  type: TRAP_ADD,
  data: trap,
});

export const addTrapSuccessAction = (createdTrap) => ({
  type: TRAP_ADD_SUCCESS,
  data: createdTrap,
});

export const addTrapErrorAction = (error) => ({
  type: TRAP_ADD_ERROR,
  data: error,
});

export const trapsSearchAction = (searchText) => {
  if (searchText) {
    return {
      type: TRAPS_SEARCH_FILTER,
      data: searchText,
    };
  }
  return {
    type: TRAPS_REMOVE_SEARCH_FILTER,
  };
};

export const updateTrapAction = (trap) => ({
  type: TRAP_UPDATE,
  data: trap,
});

export const updateTrapSuccessAction = (trap) => ({
  type: TRAP_UPDATE_SUCCESS,
  data: trap,
});

export const updateTrapErrorAction = (error) => ({
  type: TRAP_UPDATE_ERROR,
  data: error,
});

export const deleteTrapAction = (trapId) => ({
  type: TRAP_DELETE,
  data: trapId,
});

export const deleteTrapSuccessAction = (trapId) => ({
  type: TRAP_DELETE_SUCCESS,
  data: trapId,
});

export const deleteTrapErrorAction = (error) => ({
  type: TRAP_DELETE_ERROR,
  data: error,
});
