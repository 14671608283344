import React from 'react';
import { useSelector } from 'react-redux';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { groupsSelector } from 'store/modules/reports/selectors';

import GroupItem from './groupItem';
import { useStyles } from '../../styles';

const GroupsList = () => {
  const classes = useStyles();
  const groups = useSelector(groupsSelector);

  return (
    <Paper elevation={5} className={classes.groupsPaper}>
      <Typography variant="h6" className={classes.title}>
        Grupos de armadilhas disponíveis
      </Typography>
      <Typography variant="subtitle1" color="textSecondary" gutterBottom>
        Selecione quais grupos deseja adicionar ao relatório
      </Typography>
      {groups.map((group) => (
        <GroupItem key={group.id} group={group} />
      ))}
    </Paper>
  );
};

export default GroupsList;
