import { all } from 'redux-saga/effects';

import auth from './modules/auth/sagas';
import traps from './modules/traps/sagas';
import groups from './modules/groups/sagas';
import images from './modules/images/sagas';
import lotScan from './modules/lot-scan/sagas';
import singleScan from './modules/single-scan/sagas';
import reports from './modules/reports/sagas';
import queueScan from './modules/queue-scan/sagas';

export default function* rootSaga() {
  yield all([
    ...auth,
    ...traps,
    ...groups,
    ...images,
    ...lotScan,
    ...singleScan,
    ...reports,
    ...queueScan,
  ]);
}
