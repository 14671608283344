import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';

import DateInput from 'components/DateInput';

import { useStyles } from '../../styles';

const CloseReportModal = ({ open, closeHandler, submitHandler }) => {
  const classes = useStyles();
  const [reportEndDate, setReportEndDate] = useState(new Date());

  const confirmHandler = () => {
    submitHandler(reportEndDate);
    closeHandler();
  };

  return (
    <Dialog open={open} onClose={closeHandler} maxWidth="xs">
      <DialogTitle className={classes.closeModalTitle}>
        Confirmar o fechamento de relatório
      </DialogTitle>
      <DialogContent>
        <DateInput
          label="Data final"
          className={classes.closeModalDateInput}
          date={reportEndDate}
          onChange={setReportEndDate}
        />

        <div className={classes.closeModalButtonsContainer}>
          <Button
            className={classes.closeModalCardButton}
            onClick={closeHandler}
            color="primary"
            variant="outlined"
          >
            CANCELAR
          </Button>
          <Button
            className={classes.closeModalCardButton}
            onClick={confirmHandler}
            color="primary"
            variant="contained"
          >
            CONFIRMAR
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

CloseReportModal.propTypes = {
  open: PropTypes.bool.isRequired,
  closeHandler: PropTypes.func.isRequired,
  submitHandler: PropTypes.func.isRequired,
};

export default CloseReportModal;
