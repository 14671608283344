import React from 'react';
import PropTypes from 'prop-types';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import { useStyles } from '../../styles';

const Card = ({ title, data, totalAmount }) => {
  const classes = useStyles();

  return (
    <Paper elevation={5} className={classes.detailsCard}>
      <Typography variant="h4" align="center" className={classes.summaryTitle}>
        {title}
      </Typography>
      <div className={classes.detailsItemsContainer}>
        {data.map((item) => (
          <Typography
            variant="subtitle1"
            className={classes.detailsItem}
            key={item.text}
          >
            <span>{item.text}:</span>
            <span>{item.amount}</span>
          </Typography>
        ))}
      </div>
      <Divider />
      <Typography variant="subtitle1" className={classes.detailsSum}>
        Total de psilídeos: {totalAmount}
      </Typography>
    </Paper>
  );
};

Card.defaultProps = {
  data: [],
};

Card.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      amount: PropTypes.number,
    })
  ),
  totalAmount: PropTypes.number.isRequired,
};

export default Card;
