import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Button from '@material-ui/core/Button';

import Topbar from 'components/layout/topbar';
import TrapsList from 'pages/Groups/components/trapsList';
import SequenceCard from 'pages/Groups/components/sequenceCard';
import ROUTER_PATHS from 'constants/router';

import {
  newGroupInit,
  newGroupSubmitAction,
  selectedGroupCleanUpAction,
} from 'store/modules/groups/actions';

import { useStyles } from './styles';

const NewGroup = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(newGroupInit());
    return () => {
      dispatch(selectedGroupCleanUpAction());
    };
  }, [dispatch]);

  const handleSubmit = () => {
    dispatch(newGroupSubmitAction());
  };

  return (
    <div>
      <Topbar
        title="Criar Grupo"
        subtitle="Selecione e ordene as armadilhas, e nomeie o grupo"
      />
      <div className={classes.container}>
        <div className={classes.content}>
          <TrapsList />
        </div>
        <div className={classes.content}>
          <SequenceCard />
          <div className={classes.cardButtons}>
            <Button
              className={classes.cardButton}
              onClick={() => history.push(ROUTER_PATHS.GROUPS)}
              color="primary"
            >
              CANCELAR
            </Button>
            <Button
              className={classes.cardButton}
              onClick={handleSubmit}
              color="primary"
              variant="contained"
            >
              SALVAR
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewGroup;
