import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import DragIcon from 'assets/icons/Mover.svg';

import { selectedGroupNameSelector } from 'store/modules/groups/selectors';
import { updateSelectedGroupName } from 'store/modules/groups/actions';

import { useStyles } from '../../styles';
import TrapsSequence from './trapsSequence';

const SequenceCard = () => {
  const classes = useStyles();
  const selectedGroup = useSelector(selectedGroupNameSelector);

  const dispatch = useDispatch();

  const updateNameHandler = (e) => {
    dispatch(updateSelectedGroupName(e.target.value));
  };

  return (
    <Paper elevation={3} className={classes.paperPadding}>
      <Typography variant="h5" gutterBottom>
        Sequência de leitura
        <TextField
          variant="outlined"
          type="text"
          id="name"
          label="Nome do grupo"
          color="secondary"
          className={classes.input}
          value={selectedGroup}
          onChange={updateNameHandler}
        />
      </Typography>
      <Typography variant="subtitle2" gutterBottom>
        Para alterar a ordem das armadilhas, arraste pelo ícone:{' '}
        <img src={DragIcon} alt="DragIcon" className={classes.dragIcon} />
      </Typography>
      <TrapsSequence />
    </Paper>
  );
};

export default SequenceCard;
