import {
  GROUP_ERROR,
  GROUP_IS_LOADING,
  GROUP_CLEANUP,
  GROUP_INDEX_LOAD,
  GROUP_INDEX_SUCCESS,
  GROUP_NEW_INIT,
  GROUP_SELECTED_UPDATE_NAME,
  GROUP_SELECTED_UPDATE_TRAPS,
  GROUP_SELECTED_ADD_TRAP,
  GROUP_SELECTED_REMOVE_TRAP,
  GROUP_NEW_SUBMIT,
  GROUP_NEW_SUBMIT_SUCCESS,
  GROUP_SELECTED_CLEANUP,
  GROUP_FIND_LOAD,
  GROUP_FIND_SUCCESS,
  GROUP_EDIT_SUBMIT,
} from 'store/types';

export const groupsErrorAction = (error) => ({
  type: GROUP_ERROR,
  error,
});

export const groupsIsLoadingAction = () => ({
  type: GROUP_IS_LOADING,
});

export const groupsCleanUpAction = () => ({
  type: GROUP_CLEANUP,
});

export const groupsIndexAction = () => ({
  type: GROUP_INDEX_LOAD,
});

export const groupsIndexSuccessAction = (data) => ({
  type: GROUP_INDEX_SUCCESS,
  data,
});

export const newGroupInit = () => ({
  type: GROUP_NEW_INIT,
});

export const updateSelectedGroupName = (name) => ({
  type: GROUP_SELECTED_UPDATE_NAME,
  data: name,
});

export const updateSelectedGroupTraps = (traps) => ({
  type: GROUP_SELECTED_UPDATE_TRAPS,
  data: traps,
});

export const addTrapToSelectedGroupTraps = (trap) => ({
  type: GROUP_SELECTED_ADD_TRAP,
  data: trap,
});

export const removeTrapFromSelectedGroupTraps = (trap) => ({
  type: GROUP_SELECTED_REMOVE_TRAP,
  data: trap,
});

export const newGroupSubmitAction = () => ({
  type: GROUP_NEW_SUBMIT,
});

export const newGroupSubmitSuccessAction = (group) => ({
  type: GROUP_NEW_SUBMIT_SUCCESS,
  data: group,
});

export const selectedGroupCleanUpAction = () => ({
  type: GROUP_SELECTED_CLEANUP,
});

export const findGroupLoadAction = (id) => ({
  type: GROUP_FIND_LOAD,
  data: id,
});

export const findGroupSuccessAction = (group) => ({
  type: GROUP_FIND_SUCCESS,
  data: group,
});

export const editGroupSubmitAction = () => ({
  type: GROUP_EDIT_SUBMIT,
});
