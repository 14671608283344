export const duplicateTrapsValidator = (images, updatedImage) => {
  const duplication = images.find(
    (image) =>
      image.trap &&
      image.side &&
      updatedImage.trap &&
      image.trap.value === updatedImage.trap.value &&
      image.side === updatedImage.side
  );
  if (duplication) {
    const trapName = duplication.trap.label;
    const trapSide = duplication.side === 'FRONT' ? 'frente' : 'verso';
    throw new Error(`Armadilha ${trapName} possui imagem como ${trapSide}`);
  }
};

export const imagesDataValidator = (images) => {
  images.forEach((image) => {
    if (!image.trap || !image.side || !image.vegetativeStage) {
      throw new Error(
        'Armadilha, lado e estágio vegetativo devem ser selecionados'
      );
    }
  });
};

export const trapHaveBothSidesValidator = (images) => {
  images.forEach((image) => {
    if (!image.frontImageUrl) {
      throw new Error(
        `Armadilha ${image.trapName} não possui imagem de frente!`
      );
    }
    if (!image.backImageUrl) {
      throw new Error(
        `Armadilha ${image.trapName} não possui imagem de verso!`
      );
    }
  });
};

export default {
  duplicateTrapsValidator,
  imagesDataValidator,
  trapHaveBothSidesValidator,
};
