import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';

import Topbar from 'components/layout/topbar';
import ScanTypeModal from 'components/ScanTypeModal';
import NavButtons from 'components/NavButtons';
import { useModal } from 'hooks/useModal';
import ROUTER_PATH from 'constants/router';

import {
  reportsCleanUpAction,
  indexClosedReportsLoadAction,
} from 'store/modules/reports/actions';
import { selectedOrganizationSelector } from 'store/modules/auth/selectors';

import ReportsList from './components/reportsList';
import { useStyles } from './styles';

const Home = () => {
  const classes = useStyles();
  const [isOpen, closeModal, openModal] = useModal();
  const history = useHistory();
  const dispatch = useDispatch();
  const organization = useSelector(selectedOrganizationSelector);

  const navButtons = [
    {
      text: 'EM ABERTO',
      onClickHandler: () => history.push(ROUTER_PATH.HOME),
    },
    {
      selected: true,
      text: 'FINALIZADAS',
    },
  ];

  useEffect(() => {
    return () => {
      dispatch(reportsCleanUpAction());
    };
  }, [dispatch]);

  useEffect(() => {
    if (organization) {
      dispatch(indexClosedReportsLoadAction());
    }
  }, [dispatch, organization]);

  return (
    <div>
      <Topbar title="Produto de psilídeos" subtitle="Relatórios Finalizados" />
      <div className={classes.navContainer}>
        <NavButtons buttonsList={navButtons} />
        <div>
          <Button
            color="primary"
            className={classes.btn}
            onClick={() => history.push(ROUTER_PATH.NEW_REPORT)}
          >
            + NOVO RELATÓRIO
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={`${classes.btn} ${classes.whiteText}`}
            onClick={openModal}
          >
            LER ARMADILHAS
          </Button>
        </div>
      </div>
      <ReportsList />
      <ScanTypeModal close={closeModal} open={isOpen} />
    </div>
  );
};

export default Home;
