import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';

import {
  organizationsSelector,
  selectedOrganizationSelector,
  loggedUserEmailSelector,
} from 'store/modules/auth/selectors';

import {
  setSelectedOrganizationAction,
  logoutAction,
} from 'store/modules/auth/actions';

import { useStyles } from './styles';

const UserDropDown = ({ open, anchorEl, close }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const loggedUserEmail = useSelector(loggedUserEmailSelector);
  const organizations = useSelector(organizationsSelector) || [];
  const selectedOrganization = useSelector(selectedOrganizationSelector) || {
    name: '',
  };

  const unselectedOrganizations = organizations.filter(
    (org) => org.id !== selectedOrganization.id
  );

  const handleClose = () => {
    close();
  };

  const logoutHandler = () => {
    dispatch(logoutAction());
  };

  const changeOrgHandler = (organization) => {
    dispatch(setSelectedOrganizationAction(organization));
    handleClose();
  };

  const renderUnselectedOrganizations = () =>
    unselectedOrganizations.map((org) => (
      <MenuItem
        key={org.id}
        className={classes.userDDItem}
        onClick={() => changeOrgHandler(org)}
      >
        <Avatar>{org.name.charAt(0).toUpperCase()}</Avatar>
        <Typography>{org.name.toUpperCase()}</Typography>
      </MenuItem>
    ));

  return (
    <div>
      <Menu
        id="user-drop-down-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
      >
        <MenuItem className={classes.userDDTitle}>
          <Typography variant="subtitle2" className={classes.userDDTitleText}>
            Logado como {loggedUserEmail}
          </Typography>
        </MenuItem>
        <MenuItem className={classes.userDDItem}>
          <Avatar className={classes.activeOrganizationAvatar}>
            {selectedOrganization &&
              selectedOrganization.name.charAt(0).toUpperCase()}
          </Avatar>
          <Typography>
            {selectedOrganization && selectedOrganization.name.toUpperCase()}
          </Typography>
        </MenuItem>
        {renderUnselectedOrganizations()}

        <MenuItem className={classes.userDDItem} onClick={logoutHandler}>
          <Button variant="outlined" fullWidth color="secondary">
            Sair
          </Button>
        </MenuItem>
      </Menu>
    </div>
  );
};

UserDropDown.propTypes = {
  open: PropTypes.bool.isRequired,
  anchorEl: PropTypes.element.isRequired,
  close: PropTypes.func.isRequired,
};

export default UserDropDown;
