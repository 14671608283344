import React from 'react';
import PropTypes from 'prop-types';

import Page from './pageButton';
import { useStyles } from '../../styles';

const Pages = ({ pageCount, currentPage, onChange }) => {
  const classes = useStyles();

  const pages = [...Array(pageCount).keys()]
    .map((index) => index + 1)
    .filter(
      (page) =>
        page <= pageCount && page <= currentPage + 2 && page >= currentPage - 2
    );

  const renderPages = () =>
    pages.map((page) => (
      <Page
        onChange={onChange}
        key={page}
        number={page}
        className={`${classes.page} ${
          page === currentPage ? classes.active : ''
        }`}
      />
    ));

  return (
    <div className={classes.paginationContainer}>
      {currentPage > 3 && (
        <Page
          onChange={onChange}
          number={1}
          variant="first"
          className={classes.page}
        />
      )}
      {renderPages()}
      {currentPage + 2 < pageCount && (
        <Page
          onChange={onChange}
          number={pageCount}
          variant="last"
          className={classes.page}
        />
      )}
    </div>
  );
};

Pages.propTypes = {
  pageCount: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Pages;
