import { takeLatest, put, select, call } from 'redux-saga/effects';
import ROUTER_PATHS from 'constants/router';
import history from 'services/history';
import {
  LOT_SCAN_INIT,
  LOT_SCAN_ADD_IMAGE,
  LOT_SCAN_LOAD_TRAPS,
  LOT_SCAN_UPDATE_IMAGE,
  LOT_SCAN_SUBMIT_IMAGES,
  LOT_SCAN_LOAD_VEGETATIVE_STAGES,
} from 'store/types';
import { indexGroups, getFindGroup } from 'services/groups';
import { indexTraps } from 'services/traps';
import {
  getUploadUrl,
  storeImages,
  indexVegetativeStages,
} from 'services/images';
import { uploadImageService } from 'services/aws-s3';

import {
  errorNotificationAction,
  successNotificationAction,
} from 'store/modules/notifications/actions';

import {
  lotScanIsLoadingAction,
  lotScanErrorAction,
  loadGroupsSuccessAction,
  addImageSuccessAction,
  loadTrapsSuccessAction,
  updateImageSuccessAction,
  lotScanCleanUpAction,
  loadVegetativeStagesSuccessAction,
  uploadingAction,
  doneUploadingAction,
} from './actions';
import { imagesSelector } from './selectors';
import {
  duplicateTrapsValidator,
  imagesDataValidator,
  trapHaveBothSidesValidator,
} from './validators';
import { groupImagesPerTrap, groupImagesInPages } from './serializers';

function* init() {
  yield put(lotScanIsLoadingAction());
  try {
    const groups = yield indexGroups();

    yield put(loadGroupsSuccessAction(groups));
  } catch (error) {
    yield put(errorNotificationAction('Não foi possivel carregar os grupos!'));
    yield put(lotScanErrorAction(error));
  }
}

function* uploadImage({ data }) {
  yield put(uploadingAction());
  try {
    const filesArray = [...data];

    const uploadUrls = yield Promise.all(
      filesArray.map((file) =>
        getUploadUrl({ fileName: file.name, fileType: file.type })
      )
    );

    const imagesPages = groupImagesInPages({
      filesArray,
      uploadUrls,
      pageSize: 4,
    });

    // eslint-disable-next-line no-restricted-syntax
    for (const page of imagesPages) {
      yield Promise.all(page.map((image) => uploadImageService(image)));
      const imagesUrls = page.map(({ uploadUrl }) => uploadUrl.split('?')[0]);

      yield put(addImageSuccessAction(imagesUrls));
    }

    yield put(doneUploadingAction());
  } catch (error) {
    yield put(
      errorNotificationAction('Ocorreu um erro ao tentar enviar as imagens!')
    );
    yield put(lotScanErrorAction(error));
  }
}

function* loadTraps({ data }) {
  yield put(lotScanIsLoadingAction());
  try {
    let traps;
    if (data) {
      const group = yield getFindGroup(data);
      traps = group.traps.map((groupTrap) => groupTrap.trap);
    } else {
      traps = yield indexTraps();
    }
    yield put(loadTrapsSuccessAction(traps));
  } catch (error) {
    yield put(
      errorNotificationAction('Não foi possivel carregar as armadilhas!')
    );
    yield put(lotScanErrorAction(error));
  }
}

function* updateImage({ data }) {
  yield put(lotScanIsLoadingAction());
  try {
    const images = yield select(imagesSelector);
    duplicateTrapsValidator(images, data);

    yield put(updateImageSuccessAction(data));
  } catch (error) {
    yield put(errorNotificationAction(error.message));
    yield put(lotScanErrorAction(error.message));
  }
}

function* submitImages() {
  yield put(lotScanIsLoadingAction());
  try {
    const images = yield select(imagesSelector);
    imagesDataValidator(images);
    const imagesPerGroup = groupImagesPerTrap(images);
    trapHaveBothSidesValidator(imagesPerGroup);

    const response = yield storeImages(imagesPerGroup);
    yield put(lotScanCleanUpAction(response));
    yield call(history.push, ROUTER_PATHS.HOME);
    yield put(
      successNotificationAction(
        'As imagens foram salvas e serão analisadas, o resultado ficará disponível em breve'
      )
    );
  } catch (error) {
    yield put(errorNotificationAction(error.message));
    yield put(lotScanErrorAction(error.message));
  }
}

function* loadVegetativeStages() {
  yield put(lotScanIsLoadingAction());

  try {
    const vegetativeStages = yield indexVegetativeStages();
    yield put(loadVegetativeStagesSuccessAction(vegetativeStages));
  } catch (error) {
    yield put(
      errorNotificationAction(
        'Não foi possivel carregar os estágios vegetativos!'
      )
    );
    yield put(lotScanErrorAction(error.message));
  }
}

const loteSagas = [
  takeLatest(LOT_SCAN_INIT, init),
  takeLatest(LOT_SCAN_ADD_IMAGE, uploadImage),
  takeLatest(LOT_SCAN_LOAD_TRAPS, loadTraps),
  takeLatest(LOT_SCAN_UPDATE_IMAGE, updateImage),
  takeLatest(LOT_SCAN_SUBMIT_IMAGES, submitImages),
  takeLatest(LOT_SCAN_LOAD_VEGETATIVE_STAGES, loadVegetativeStages),
];

export default loteSagas;
