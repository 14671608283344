import React, { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Topbar from 'components/layout/topbar';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import {
  addImageAction,
  loadTrapsAction,
  submitImagesAction,
  lotScanCleanUpAction,
  loadVegetativeStagesAction,
} from 'store/modules/lot-scan/actions';
import {
  hasImagesSelector,
  isUploadingSelector,
} from 'store/modules/lot-scan/selectors';

import ImageList from './components/imageList';
import Pagination from './components/pagination';

import { useStyles } from './styles';

const ImagesLot = () => {
  const classes = useStyles();
  const filesRef = useRef(null);
  const dispatch = useDispatch();
  const hasImages = useSelector(hasImagesSelector);
  const isUploading = useSelector(isUploadingSelector);
  const { groupId } = useParams();

  const changeHandler = (e) => {
    if (e.target.files) {
      dispatch(addImageAction(e.target.files));
    }
    filesRef.current.value = '';
  };

  const btnClick = () => {
    filesRef.current.click();
  };

  useEffect(() => {
    dispatch(loadTrapsAction(groupId));
    dispatch(loadVegetativeStagesAction());
    return () => {
      dispatch(lotScanCleanUpAction());
    };
  }, [dispatch, groupId]);

  const submitImagesHandler = () => {
    dispatch(submitImagesAction());
  };

  const renderSubmitButton = () => (
    <Button
      color="primary"
      variant="contained"
      className={classes.submitBtn}
      onClick={submitImagesHandler}
      disabled={isUploading}
    >
      {isUploading ? 'ENVIANDO...' : 'LER ARMADILHAS'}
    </Button>
  );

  return (
    <div>
      <Topbar
        title="Enviar imagens por lote"
        subtitle="Identificando as armadilhas"
      />
      <div className={classes.allContent}>
        <div className={classes.topButton}>
          <Button
            color="primary"
            variant="contained"
            className={classes.whiteBtn}
            onClick={btnClick}
          >
            ENVIAR IMAGENS
          </Button>
          <input
            ref={filesRef}
            hidden
            type="file"
            multiple
            onChange={changeHandler}
            name="images"
            id="images"
            accept="image/*"
          />
        </div>
        {hasImages ? (
          <div className={classes.content}>
            <ImageList />
            <Pagination />
            <Typography
              display="block"
              color="textSecondary"
              variant="subtitle2"
            >
              Envie todas as imagens antes de finalizar
            </Typography>
            {renderSubmitButton()}
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default ImagesLot;
