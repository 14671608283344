import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import Topbar from 'components/layout/topbar';
import Button from '@material-ui/core/Button';

import HideableButton from 'components/HideableButton';

import {
  imageFindLoadAction,
  imageCleanUpAction,
  updateImageAction,
  imageSubmitupdateAction,
} from 'store/modules/images/actions';
import { selectedImageSelector } from 'store/modules/images/selectors';

import ImageCard from './components/imageCard';
import ImageBox from './components/imageBox';
import { useStyles } from './styles';

const SingleImage = () => {
  const classes = useStyles();
  const { id } = useParams();
  const dispatch = useDispatch();
  const image = useSelector(selectedImageSelector) || {};
  const history = useHistory();

  useEffect(() => {
    dispatch(imageFindLoadAction(id));
    return () => {
      dispatch(imageCleanUpAction());
    };
  }, [dispatch, id]);

  const updateImageHandler = (side) => (newImage) => {
    dispatch(updateImageAction({ file: newImage, side }));
  };

  const saveChangesClickHandler = () => {
    dispatch(imageSubmitupdateAction(image));
  };

  const date = moment(image.captureDate).format('DD/MM/YYYY');

  return (
    <div>
      <Topbar
        title={`Imagens da armadilha ${image.trap ? image.trap.name : ''}`}
        subtitle={`data ${date}`}
      />
      <Button
        color="primary"
        className={classes.backBtn}
        onClick={() => history.goBack()}
      >
        {'< '} VOLTAR
      </Button>
      <div className={classes.detailsContainer}>
        <ImageCard image={image} />
        <HideableButton show={!image.status} className={classes.reSendBtn}>
          REFAZER LEITURA
        </HideableButton>
        <div className={classes.imagesContainer}>
          <ImageBox
            url={image.frontImageUrl}
            onChange={updateImageHandler('front')}
          />
          <ImageBox
            url={image.backImageUrl}
            onChange={updateImageHandler('back')}
          />
        </div>
        <HideableButton
          show={image.changed}
          className={classes.reSendBtn}
          onClick={saveChangesClickHandler}
        >
          SALVAR ALTERAÇÕES
        </HideableButton>
      </div>
    </div>
  );
};

export default SingleImage;
