import {
  REPORTS_CLEANUP,
  REPORTS_IS_LOADING,
  REPORTS_ERROR,
  REPORTS_LOAD_GROUPS,
  REPORTS_LOAD_GROUPS_SUCCESS,
  REPORTS_NEW_REPORT_INIT,
  REPORTS_UPDATE_SELECTED,
  REPORTS_SELECTED_ADD_GROUP,
  REPORTS_SELECTED_REMOVE_GROUP,
  REPORTS_NEW_REPORT_SUBMIT,
  REPORTS_LOAD_REPORT,
  REPORTS_LOAD_REPORT_SUCCESS,
  REPORTS_UPDATE_REPORT_SUBMIT,
  REPORTS_INDEX_OPEN_LOAD,
  REPORTS_INDEX_OPEN_SUCCESS,
  REPORTS_REPORT_BY_VEGETATIVE_STAGE_LOAD,
  REPORTS_REPORT_BY_VEGETATIVE_STAGE_SUCCESS,
  REPORTS_REPORT_BY_GROUP_LOAD,
  REPORTS_REPORT_BY_GROUP_SUCCESS,
  REPORTS_INDEX_CLOSED_LOAD,
  REPORTS_INDEX_CLOSED_SUCCESS,
  REPORTS_CLOSE_REPORT,
  REPORTS_CLOSE_REPORT_SUCCESS,
  REPORTS_DELETE_REPORT,
} from 'store/types';

export const reportsIsLoadingAction = () => ({
  type: REPORTS_IS_LOADING,
});

export const reportsErrorAction = (error) => ({
  type: REPORTS_ERROR,
  error,
});

export const reportsCleanUpAction = () => ({
  type: REPORTS_CLEANUP,
});

export const newReportInitAction = () => ({
  type: REPORTS_NEW_REPORT_INIT,
});

export const loadGroupsAction = () => ({
  type: REPORTS_LOAD_GROUPS,
});

export const loadGroupsSuccessAction = (groups) => ({
  type: REPORTS_LOAD_GROUPS_SUCCESS,
  data: groups,
});

export const updateSelectedReportAction = (updated) => ({
  type: REPORTS_UPDATE_SELECTED,
  data: updated,
});

export const addGroupToSelectedReportAction = (group) => ({
  type: REPORTS_SELECTED_ADD_GROUP,
  data: group,
});

export const removeGroupFromSelectedReportAction = (group) => ({
  type: REPORTS_SELECTED_REMOVE_GROUP,
  data: group,
});

export const newReportSubmitAction = () => ({
  type: REPORTS_NEW_REPORT_SUBMIT,
});

export const reportLoadAction = (id) => ({
  type: REPORTS_LOAD_REPORT,
  data: id,
});

export const reportLoadSuccessAction = (report) => ({
  type: REPORTS_LOAD_REPORT_SUCCESS,
  data: report,
});

export const updateReportSubmitAction = () => ({
  type: REPORTS_UPDATE_REPORT_SUBMIT,
});

export const indexOpenReportsLoadAction = () => ({
  type: REPORTS_INDEX_OPEN_LOAD,
});

export const indexOpenReportsSuccessAction = (reports) => ({
  type: REPORTS_INDEX_OPEN_SUCCESS,
  data: reports,
});

export const reportByVegetativeStateLoadAction = (reportId) => ({
  type: REPORTS_REPORT_BY_VEGETATIVE_STAGE_LOAD,
  data: reportId,
});

export const reportByVegetativeStateSuccessAction = (report) => ({
  type: REPORTS_REPORT_BY_VEGETATIVE_STAGE_SUCCESS,
  data: report,
});

export const reportByGroupLoadAction = (reportId) => ({
  type: REPORTS_REPORT_BY_GROUP_LOAD,
  data: reportId,
});

export const reportByGroupSuccessAction = (report) => ({
  type: REPORTS_REPORT_BY_GROUP_SUCCESS,
  data: report,
});

export const indexClosedReportsLoadAction = () => ({
  type: REPORTS_INDEX_CLOSED_LOAD,
});

export const indexClosedReportsSuccessAction = (reports) => ({
  type: REPORTS_INDEX_CLOSED_SUCCESS,
  data: reports,
});

export const closeReportAction = (data) => ({
  type: REPORTS_CLOSE_REPORT,
  data,
});

export const closeReportSuccessAction = (report) => ({
  type: REPORTS_CLOSE_REPORT_SUCCESS,
  data: report,
});

export const deleteReportAction = (reportId) => ({
  type: REPORTS_DELETE_REPORT,
  data: reportId,
});
