import React from 'react';
import { useDispatch } from 'react-redux';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import logo from 'assets/images/logo.svg';
import { authLoginLoadAction } from 'store/modules/auth/actions';
import LoginForm from './components/loginForm';

import { useStyles } from './styles';

const Login = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const loginHandler = (data) => {
    dispatch(authLoginLoadAction(data));
  };
  return (
    <div className={classes.container}>
      <div className={classes.background} />
      <div className={classes.box}>
        <Typography variant="h1" component="h2" className={classes.logo}>
          <img src={logo} alt="logo-agrosmart" />
        </Typography>
        <Typography variant="h2" className={classes.title}>
          NOME DO PRODUTO
        </Typography>
        <LoginForm onSubmit={loginHandler} />
        <Typography variant="body2" className={classes.possuiContaBox}>
          Não possui uma conta?
        </Typography>
        <Button
          variant="outlined"
          color="primary"
          className={classes.crieContaButton}
          onClick={() => {
            window.location = 'https://www.cultivointeligente.com.br/sign_up';
          }}
        >
          CRIE AGORA
        </Button>
        <Box display="block" className={classes.languagesBox}>
          <Typography variant="button" className={classes.flag} />
          <Typography variant="button" className={classes.flag} />
          <Typography variant="button" className={classes.flag} />
        </Box>
        <Typography variant="caption" display="block">
          Agrosmart 2020
        </Typography>
      </div>
    </div>
  );
};

export default Login;
