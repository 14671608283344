import axios from '../utils/axios';

export const indexGroups = async () => {
  try {
    const { data } = await axios.get('/groups');
    return data;
  } catch (e) {
    if (e.response) {
      throw e.response.data;
    }
    const error = {
      message: 'Ocorreu um erro por favor tente novamente!',
    };
    throw error;
  }
};

export const postStoreGroup = async (group) => {
  try {
    const { data } = await axios.post('/groups', group);
    return data;
  } catch (e) {
    if (e.response) {
      throw e.response.data;
    }
    const error = {
      message: 'Ocorreu um erro por favor tente novamente!',
    };
    throw error;
  }
};

export const getFindGroup = async (id) => {
  try {
    const { data } = await axios.get(`/groups/${id}`);
    return data;
  } catch (e) {
    if (e.response) {
      throw e.response.data;
    }
    const error = {
      message: 'Ocorreu um erro por favor tente novamente!',
    };
    throw error;
  }
};

export const putEditGroup = async (group) => {
  try {
    const { data } = await axios.put(`/groups/${group.id}`, group);
    return data;
  } catch (e) {
    if (e.response) {
      throw e.response.data;
    }
    const error = {
      message: 'Ocorreu um erro por favor tente novamente!',
    };
    throw error;
  }
};
