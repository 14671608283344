import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { addTrapAction } from 'store/modules/traps/actions';

import TrapForm from '../trapForm';
import { useStyles } from '../../styles';

const AddTrap = ({ open, setOpen }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [trap, setTrap] = useState({
    name: '',
    latitude: 0,
    longitude: 0,
  });

  const onChangeHandler = (e) => {
    setTrap({
      ...trap,
      [e.target.id]: e.target.value,
    });
  };

  const handleClose = () => {
    setTrap({ name: '', latitude: 0, longitude: 0 });
    setOpen(false);
  };

  const handleSubmit = () => {
    dispatch(addTrapAction(trap));
    handleClose();
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} maxWidth="xs">
        <DialogTitle>Criar nova armadilha</DialogTitle>
        <DialogContent>
          <TrapForm formdata={trap} onChangeHandler={onChangeHandler} />
          <div className={classes.cardButtons}>
            <Button
              className={classes.cardButton}
              onClick={handleClose}
              color="primary"
            >
              CANCELAR
            </Button>
            <Button
              className={classes.cardButton}
              onClick={handleSubmit}
              color="primary"
              variant="contained"
            >
              CRIAR
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

AddTrap.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default AddTrap;
